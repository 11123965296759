import React from 'react';

import { BaseModal } from './BaseModal';
import MobileDownArrow from '@app/assets/images/down-arrow.svg';
import FlexRow from '../FlexRow';
import Button from '../Button';
import { ContentCard } from '../ContentCard';
import FlexCol from '../FlexCol';

export const PendingPostDetailModal = ({ isOpen, onClose, item, setShowDeclineModal, onApprove }) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className="w-full h-full flex flex-col bg-white" overlayClassName="flex">
      <FlexRow className="p-7" onClick={onClose}>
        <img src={MobileDownArrow} className="rotate-90 transform cursor-pointer w-8 h-8" />
      </FlexRow>
      <FlexCol className="flex-1 justify-center items-center">
        <FlexCol className="flex-1">
          <ContentCard selectedItem={item} />
        </FlexCol>
        <FlexRow className="justify-center mb-8 flex-shrink-0">
          <Button label="Decline" className="px-12 py-3 bg-bg3 text-sm text-inactive" onClick={() => setShowDeclineModal(true)} />
          <Button label="Approve" className="px-12 py-3 bg-primary-green ml-3 text-sm text-white" onClick={() => onApprove()} />
        </FlexRow>
      </FlexCol>
    </BaseModal>
  );
};
