import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FlexCol from '@app/components/FlexCol';
import FlexRow from '@app/components/FlexRow';
import { currentNetworkSelector, requestStatusSelector } from '@app/store/network/selector';
import { declineMemberInviteAction, fetchUsersByNetworkAction } from '@app/store/network/actions';
import { useParams } from 'react-router-dom';
import CloseIcon from '@app/components/CloseIcon';
import MailIcon from '@app/assets/images/mail.svg';
import { Button } from '@app/components';
import { DoubleConfirmModal, InviteMemberModal } from '@app/components/modals';

export const NetworkUsersPendingPage = () => {
  const dispatch = useDispatch();
  const [isInviteMemberModalActive, setIsInviteMemberModalActive] = React.useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const { network_membership_invitations = [] } = useSelector(currentNetworkSelector);
  const { loading, success } = useSelector(requestStatusSelector);
  const { id: networkId } = useParams();
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);
  useEffect(() => {
    if (!loading && success) {
      setIsDeleteModalActive(false);
    }
  }, [loading, success]);
  return (
    <FlexCol className="flex-1 overflow-hidden">
      {!!network_membership_invitations.length && (
        <FlexCol className="px-7">
          <div className="font-sans text-34 text-dark">Invites sent</div>
          <div className="text-base text-inactive">Invitations you sent to non members.</div>
        </FlexCol>
      )}
      {!network_membership_invitations.length && (
        <FlexCol className="flex-0.7 px-7 items-center text-center justify-center">
          <FlexCol className="h-16 w-16 rounded-full border border-black justify-center items-center mb-6">
            <img src={MailIcon} className="w-6 h-6" />
          </FlexCol>
          <div className="font-sans text-30">
            Invite others and grow
            <br />
            your social club.
          </div>
          <Button label="Send an invite" className="bg-primary-green rounded-xl text-white mt-8 px-8 py-4" />
        </FlexCol>
      )}
      {!!network_membership_invitations.length && (
        <FlexCol className="flex-1 overflow-hidden">
          <FlexCol className="flex-shrink-0 items-center mt-20 mb-8">
            <Button
              label="Send an invite"
              className="bg-primary-green rounded-xl text-white px-8 py-4"
              onClick={() => setIsInviteMemberModalActive(true)}
            />
          </FlexCol>
          <FlexCol className="mt-12 px-7 pt-2 flex-grow overflow-auto">
            {network_membership_invitations.map((item, index) => (
              <FlexRow key={index} className="w-full rounded-3xl items-center shadow-custom1 p-4 mb-4">
                <FlexCol className="flex-1">
                  <div className="text-sm">{item?.user_name}</div>
                  <div className="text-xs text-inactive mt-1">{item?.user_email}</div>
                </FlexCol>
                <FlexCol className="flex-shrink-0 justify-center items-center">
                  <FlexRow
                    className="border p-4 rounded-full"
                    onClick={() => {
                      setSelectedItem(item);
                      setIsDeleteModalActive(true);
                    }}>
                    <CloseIcon fill="#999999" />
                  </FlexRow>
                </FlexCol>
              </FlexRow>
            ))}
          </FlexCol>
        </FlexCol>
      )}
      {isInviteMemberModalActive && <InviteMemberModal isOpen={isInviteMemberModalActive} onClose={() => setIsInviteMemberModalActive(false)} />}
      {selectedItem && isDeleteModalActive && (
        <DoubleConfirmModal
          isOpen={isDeleteModalActive}
          heading="Decline member invitation"
          description={`Are you sure you want to decline this membership invitation for\n ${selectedItem.user_name || selectedItem.user_email}?`}
          submitLabel="Yes, decline"
          isFetching={loading}
          onSubmit={() => dispatch(declineMemberInviteAction(selectedItem))}
          onCancel={() => setIsDeleteModalActive(false)}
          onClose={() => setIsDeleteModalActive(false)}
        />
      )}
    </FlexCol>
  );
};
