import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as UrqlProvider } from 'urql';
import { ToastContainer } from 'react-toastify';

import RootRouter from '@app/routes';
import configureStore from '@app/store';
import { urqlClient } from '@app/utils';

import '@shared/styles/typography.css';
import '@shared/styles/variables.css';
import '@shared/styles/base.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import { isMobile } from 'react-device-detect';

function App() {
  const [rootStore, setRootStore] = useState(undefined);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore();
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  useEffect(() => {
    if (isMobile) {
      document.getElementsByTagName('html')[0].style.height = `${window.innerHeight}px`;
      document.getElementsByTagName('body')[0].style.height = `100%`;
      document.getElementById('root').style.height = `100%`;
    }
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <Provider store={rootStore.store}>
      <PersistGate loading={null} persistor={rootStore.persistor}>
        <UrqlProvider value={urqlClient}>
          <RootRouter />
          <ToastContainer />
        </UrqlProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
