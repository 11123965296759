import React from 'react';
import FlexCol from './FlexCol';

export const AnalyticsCard = ({ title, value = 0 }) => {
  return (
    <FlexCol className="p-5 pr-1 border rounded-xl bg-white relative">
      <div className="text-xs text-dark">{title}</div>
      <div className="text-34 py-1 text-dark font-thin">{value.toLocaleString()}</div>
    </FlexCol>
  );
};
