const GetPendingPosts = `
  query GetPendingPosts ($network_uuid: uuid!) {
    content (where: { network_uuid: { _eq: $network_uuid }, _not: { content_reject: {} }, admin_approved: { _eq: false }}, order_by: { created_at: desc }) {
      uuid
      heading
      sub_heading
      admin_approved
      body
      article_link
      video_link
      created_at
      creator {
        uuid
        name
        user_profile {
          photo
          username
        }
        posts: content_aggregate (where: { network_uuid: { _eq: $network_uuid } }) {
          total: aggregate {
            count
          }
        }
      }
      media {
        original_url
        type
      }
      meta: content_meta {
        description
        position
      }
      content_partial {
        uuid
        content_partial_poll {
          uuid
          options: content_partial_poll_options {
            poll_option {
              __typename
              uuid
              text
            }
          }
        }
        content_partial_roundtable {
          uuid
        }
        content_partial_opportunity_listing {
          criteria: content_partial_opportunity_listing_criteria {
            item: opportunity_listing_criteria {
              text
            }
          }
          company {
            name
            photo
          }
        }
        content_partial_availability_listing {
          credentials: content_partial_availability_listing_credentials {
            credential: availability_listing_credential {
              text
            }
          }
        }
      }
    }
  }
`;

export default GetPendingPosts;
