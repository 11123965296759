import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
// <PhoneInput
// className="border-gray-300 border text-xs rounded-lg w-52 overflow-hidden"
// placeholder="Phone"
// defaultCountry="US"
// value={phone}
// onChange={(value) => onChange('phone', value)}
// />

import { Input } from './Input';
import CloseIcon from './CloseIcon';
import FlexRow from './FlexRow';

export const InvitationsRow = ({ item: { name, email, phone }, isCSV = false, onDelete = () => {}, onChange = () => {} }) => {
  return (
    <FlexRow className="w-full text-xs text-label items-center">
      <Input
        className="border-gray-300 border rounded-lg w-52 overflow-hidden"
        type="text"
        isRequired
        value={name}
        placeholder="Full name"
        inputFieldClassName="py-3 w-full px-3 text-xs text-gray-800"
        onChange={({ target }) => onChange('name', target.value)}
        // onBlur={({ target }) => onAdd(target.value)}
        // onKeyDown={({ target, key }) => key === 'Enter' && onAdd(target.value)}
      />
      {isCSV && <div className="w-8" />}
      <div className="flex-0.5" />
      <FlexRow className="flex-1 items-center">
        <Input
          className="border-gray-300 border rounded-lg w-52 overflow-hidden"
          type="text"
          isRequired={isCSV}
          value={email}
          placeholder="email@domain.com"
          inputFieldClassName="py-3 px-3 w-full text-xs text-gray-800 rounded-lg"
          onChange={({ target }) => onChange('email', target.value)}
        />
      </FlexRow>
      {!isCSV && <div className="flex-1 text-label text-center text-xs">or</div>}
      {!isCSV && (
        <FlexRow className="flex-1">
          <span className="w-52 border-gray-300 border rounded-xl">
            <PhoneInput
              className="rounded-xl bg-white text-xs text-gray-800 px-4 py-3"
              placeholder="Phone"
              defaultCountry="US"
              value={phone}
              onChange={(value) => onChange('phone', value)}
            />
          </span>
        </FlexRow>
      )}
      {isCSV && <div className="flex-grow w-full" />}
      <FlexRow className="cursor-pointer w-8 justify-center items-center" onClick={onDelete}>
        <CloseIcon />
      </FlexRow>
    </FlexRow>
  );
};
