import mapDateTime from './mapDateTime';
import mapNetworkMembershipRequest from './mapNetworkMembershipRequest';
import mapNetworkMembershipInvitation from './mapNetworkMembershipInvitation';
import mapNetworkUser from './mapNetworkUser';

// const REST_BASE_URL = process.env.REST_BASE_URL;

const mapNetwork = ({
  uuid,
  id,
  name,
  avatar = '',
  created_at,
  updated_at,
  archived_at,
  network_membership_requests = [],
  network_membership_invitations = [],
  network_users = [],
}) => ({
  uuid,
  id,
  name,
  avatar: avatar && avatar.length > 0 ? avatar : null,
  createdAt: created_at && mapDateTime(created_at),
  updatedAt: updated_at && mapDateTime(updated_at),
  archived_at: archived_at && mapDateTime(archived_at),
  network_membership_requests: network_membership_requests.map((x) => mapNetworkMembershipRequest(x)),
  network_membership_invitations: network_membership_invitations.map((x) => mapNetworkMembershipInvitation(x)),
  network_users: network_users.map((x) => mapNetworkUser(x)),
});

export default mapNetwork;
