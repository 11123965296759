import React from 'react';

import { CloudImage, CloudVideo } from '@app/utils';
import { Avatar } from '@app/components';

import NewHeartIcon from '@app/components/NewHeartIcon';
import NewMessageIcon from '@app/components/NewMessageIcon';
import ThreeDotsIcon from '@app/components/ThreeDotsIcon';
import { formatDateTimeAgo } from '@shared/utils/formatDate';
import { ContentCardLink } from './ContentCardLink';
import { isMobile } from 'react-device-detect';

const templateRect = { width: 270, height: 540 };
const mediaReact = { width: 174, hegith: 96 };
const renderScreenshot =
  (type, small = false) =>
  (url) =>
    type?.includes('video') ? CloudVideo(url, small ? mediaReact : templateRect) : CloudImage(url, small ? mediaReact : templateRect);

export const ContentCard = ({ selectedItem }) => {
  const isPoll = selectedItem.content_partial && selectedItem.content_partial.content_partial_poll;
  const isMediaPoll = selectedItem.content_partial && selectedItem.content_partial.content_partial_poll && selectedItem.media;
  const isOpportunity = selectedItem.content_partial && selectedItem.content_partial.content_partial_opportunity_listing;
  const isAvailablility = selectedItem.content_partial && selectedItem.content_partial.content_partial_availability_listing;
  const isRoundtable = selectedItem.content_partial && selectedItem.content_partial.content_partial_roundtable;
  const actionColor = isRoundtable ? 'white' : 'black';
  return (
    <div className={`rounded-xl overflow-hidden flex flex-col relative ${!isMobile ? 'border' : ''}`} style={{ minHeight: 540, minWidth: 270 }}>
      <div className={`absolute left-4 right-4 mb-2 flex flex-col text-${actionColor} ${isOpportunity || isAvailablility ? 'top-16' : 'top-20'}`}>
        <div className="flex flex-row justify-start w-full">
          <Avatar
            className="border-none rounded-full overflow-hidden"
            name={selectedItem.creator.name}
            imageSrc={selectedItem.creator.user_profile.photo}
          />
          <div className="text-xs flex ml-2 flex-col justify-center">
            <span>{selectedItem.creator?.name}</span>
            <div className="text-gray-300">
              <span>@{selectedItem.creator?.user_profile?.username} • </span>
              <span>{formatDateTimeAgo(selectedItem.created_at)}</span>
            </div>
          </div>
        </div>
        {(!selectedItem.media || isPoll) && (
          <div className={`w-full mt-4 ${isMediaPoll ? 'flex' : ''}`}>
            <div className={`bg-white w-4/5 ${isMediaPoll ? 'p-4 rounded-lg bg-opacity-90' : ''}`}>
              <div className={`text-xs leading-5 ${isMediaPoll ? 'text-black' : ''}`}>{selectedItem.heading}</div>
              {isPoll && selectedItem.content_partial.content_partial_poll.options.length === 2 && (
                <div className="rounded-xl flex flex-row w-4/5 mt-5 shadow-custom1 text-base text-gray-700 font-semibold">
                  <div className="text-center w-full flex justify-center items-center border-r">
                    <span className="py-2">{selectedItem.content_partial.content_partial_poll.options[0].poll_option.text}</span>
                  </div>
                  <div className="text-center w-full flex justify-center items-center">
                    <span className="py-2">{selectedItem.content_partial.content_partial_poll.options[1].poll_option.text}</span>
                  </div>
                </div>
              )}
              {isPoll && selectedItem.content_partial.content_partial_poll.options.length === 3 && (
                <div className="rounded-xl flex flex-col w-4/5 mt-5 shadow-custom1 text-sm text-gray-700 font-semibold">
                  <div className="w-full flex items-center border-b py-1">
                    <div className="rounded-full bg-black text-white text-xxs w-4 h-4 mx-2 flex justify-center items-center">
                      <span>A</span>
                    </div>
                    <span className="py-2">{selectedItem.content_partial.content_partial_poll.options[0].poll_option.text}</span>
                  </div>
                  <div className="w-full flex items-center border-b py-1">
                    <div className="rounded-full bg-black text-white text-xxs w-4 h-4 mx-2 flex justify-center items-center">
                      <span>B</span>
                    </div>
                    <span className="py-2">{selectedItem.content_partial.content_partial_poll.options[1].poll_option.text}</span>
                  </div>
                  <div className="w-full flex items-center py-1">
                    <div className="rounded-full bg-black text-white text-xxs w-4 h-4 mx-2 flex justify-center items-center">
                      <span>C</span>
                    </div>
                    <span className="py-2">{selectedItem.content_partial.content_partial_poll.options[2].poll_option.text}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {isOpportunity && (
          <div className="flex flex-col items-start">
            <div className="w-4/5 font-semibold text-7xl mt-2">{selectedItem.heading}</div>
            <div className="w-4/5 text-xl mt-4 mb-2">{selectedItem.sub_heading}</div>
            {selectedItem.content_partial.content_partial_opportunity_listing.criteria.map((oItem, index) => (
              <div key={index} className="flex flex-row text-sm">
                <div className="transform rotate-45 mr-2">⎦</div>
                <div>{oItem.item.text}</div>
              </div>
            ))}
            <div className="bg-white rounded px-6 py-1 flex justify-center items-center mt-4">
              <span className="text-sm text-black">Apply now</span>
            </div>
          </div>
        )}
        {isAvailablility && (
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center">
              <Avatar imageSrc={selectedItem.creator.user_profile.photo} name={selectedItem.creator.name} />
            </div>
            <div className="w-4/5 font-semibold text-lg mt-2">{selectedItem.heading}</div>
            <div className="border-b-2 w-4/5 border-white my-4" />
            <div className="w-4/5 text-base">{selectedItem.body}</div>
            <div className="border-b-2 w-4/5 border-white my-4" />
            {selectedItem.content_partial.content_partial_availability_listing.credentials.map((oItem, index) => (
              <div key={index} className="flex flex-row text-sm">
                <div className="mr-2">•</div>
                <div>{oItem.credential.text}</div>
              </div>
            ))}
            <div className="bg-white rounded px-6 py-1 flex justify-center items-center mt-4">
              <span className="text-sm text-black">Contact me</span>
            </div>
          </div>
        )}
        {isRoundtable && (
          <div className="flex flex-col">
            <div className="font-semibold text-white mb-10 mt-2">{selectedItem.heading}</div>
            <div className="flex-row flex">
              <div className="bg-white rounded text-center py-1.5 px-2">
                <div className="text-black text-tiny">Book this conversation</div>
              </div>
            </div>
          </div>
        )}
        {!isRoundtable && selectedItem.media && !selectedItem.media?.type?.includes('video') && (
          <img
            className="object-cover rounded-xl mt-5"
            style={{ ...mediaReact }}
            src={renderScreenshot(selectedItem.media.type, true)(selectedItem.media.original_url)}
          />
        )}
        {!isRoundtable && selectedItem.media && selectedItem.media?.type?.includes('video') && (
          <video
            id="video-player"
            autoPlay
            loop
            type="video/mp4"
            className="object-cover cld-video-player rounded-xl mt-5"
            style={{ ...mediaReact }}
            src={renderScreenshot(selectedItem.media.type, true)(selectedItem.media.original_url)}
          />
        )}
        {(selectedItem?.article_link || selectedItem?.video_link) && <ContentCardLink link={selectedItem.article_link || selectedItem.video_link} />}
      </div>
      {isRoundtable && selectedItem.media && !selectedItem.media?.type?.includes('video') && (
        <>
          <img
            className="object-cover"
            style={{ ...templateRect }}
            src={renderScreenshot(selectedItem.media.type)(selectedItem.media.original_url)}
          />
          <div
            className={`bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 right-0 h-1/3${
              selectedItem.media ? ' opacity-90' : 'opacity-0'
            } ${isOpportunity || isAvailablility ? 'h-4/5' : ''}`}
          />
        </>
      )}
      <div className={`absolute bottom-2 right-4 mb-2 flex flex-row text-${actionColor}`}>
        <div className="flex flex-col justify-center items-center">
          <div className="mt-6 flex items-center justify-start">
            <NewHeartIcon fill={actionColor} width={40} height={40} />
          </div>
          <div className="mt-4 flex items-center justify-start">
            <NewMessageIcon fill={actionColor} width={24} height={24} />
          </div>
          <div className="mt-4 flex items-center justify-start">
            <ThreeDotsIcon fill={actionColor} width={30} height={30} />
          </div>
        </div>
      </div>
    </div>
  );
};
