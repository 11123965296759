import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MainMenuModal, CreateNetworkModal } from './modals';
import packageInfo from '../../package.json';

import { NetworkSwitcher } from './NetworkSwitcher';

import { activeNetworkSelector } from '@app/store/network/selector';
// import { useTour } from '@reactour/tour';
import { isSuperAdminSelector, networkRoleSelector } from '@app/store/session/selector';
import { MEMBER_ROLE, NETWORK_ADMIN_ROLE } from '@app/../shared/constants';
import FlexCol from './FlexCol';
import FlexRow from './FlexRow';
import { NavMenuItem } from './NavMenuItem';

import UsersIcon from '@app/assets/images/users.svg';
import MailIcon from '@app/assets/images/mail.svg';
import EyeIcon from '@app/assets/images/eye.svg';
import BarchartIcon from '@app/assets/images/bar-chart.svg';
// import CreditCardIcon from '@app/assets/images/credit-card.svg';
import SettingsIcon from '@app/assets/images/settings.svg';
import LogoutIcon from '@app/assets/images/log-out.svg';

const superAdminMenuItems = [
  { label: 'Networks', link: '/networks', key: '/networks', icon: UsersIcon },
  { label: 'Users', link: '/users', key: '/users', icon: UsersIcon },
  { label: 'Logs', link: '/logs', key: '/logs', icon: BarchartIcon },
  // { label: 'Knowledge', link: '/knowledge', key: '/knowledge' },
];

export const SideNavigation = ({ activePath }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { id } = useSelector(activeNetworkSelector);
  const role = useSelector(networkRoleSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  // const { isOpen: isTourOpened } = useTour();

  const networkAdminMenuItems = [
    { label: 'Members', link: `/networks/${id}/members`, key: '/members', icon: UsersIcon },
    { label: 'Invitations', link: `/networks/${id}/invitations`, key: '/invitations', icon: MailIcon },
    { label: 'Moderation', link: `/networks/${id}/pending-posts`, key: '/pending', bottomSeparator: true, icon: EyeIcon },
    // { label: 'Knowledge', link: `/networks/${id}/knowledge`, key: '/knowledge' },
    // { label: 'Interests', link: `/networks/${id}/interests`, key: '/interests', bottomSeparator: true },
    { label: 'Analytics', link: `/networks/${id}/analytics`, key: '/analytics', icon: BarchartIcon },
    // { label: 'Billing', link: `/networks/${id}/billing`, key: '/billing', icon: CreditCardIcon },
    // { label: 'Settings', link: `/networks/${id}/settings`, key: '/settings', bottom: true },
  ];

  const networkMemberMenuItems = []; //{ label: 'Billing', link: `/networks/${id}/billing`, key: '/billing', icon: CreditCardIcon }];

  const networkAdminMenuBottomItems = [
    { label: 'Settings', link: `/networks/${id}/settings`, key: '/settings', icon: SettingsIcon },
    { label: 'Logout', link: '#', isLogout: true, icon: LogoutIcon },
  ];

  const networkManagerMenuItems = [
    { label: 'Moderation', link: `/networks/${id}/pending-posts`, key: '/pending', icon: EyeIcon },
    { label: 'Analytics', link: `/networks/${id}/analytics`, key: '/analytics', icon: BarchartIcon },
    // { label: 'Interests', link: `/networks/${id}/interests`, key: '/interests' },
  ];

  const networkManagerMenuBottomItems = [
    { label: 'Logout', link: '#', isLogout: true, icon: LogoutIcon },
    // { label: 'Interests', link: `/networks/${id}/interests`, key: '/interests' },
  ];

  const menuItems = !id
    ? superAdminMenuItems
    : role === NETWORK_ADMIN_ROLE || isSuperAdmin
    ? networkAdminMenuItems
    : role === MEMBER_ROLE
    ? networkMemberMenuItems
    : networkManagerMenuItems;
  const menuBottomItems = !id
    ? networkManagerMenuBottomItems
    : role === NETWORK_ADMIN_ROLE || isSuperAdmin
    ? networkAdminMenuBottomItems
    : networkManagerMenuBottomItems;

  return (
    <nav className="bg-white shadow-lg z-10 flex flex-shrink-0 w-nav-s xl:w-nav-x transition-all duration-100">
      <FlexCol className="flex-1 h-full w-full pt-8">
        <FlexRow className="px-6">
          <NetworkSwitcher onClick={() => setShowMenu(true)} />
        </FlexRow>
        <FlexCol className="flex-1 w-full my-auto overflow-y-auto justify-center">
          {menuItems.map(({ label, link, key, bottomSeparator, icon }, index) => (
            <NavMenuItem
              label={label}
              link={link}
              index={key}
              icon={icon}
              activePath={activePath}
              // isTourOpened={isTourOpened}
              bottomSeparator={bottomSeparator}
              key={index}
            />
          ))}
        </FlexCol>
        <FlexCol>
          {menuBottomItems.map(({ label, link, key, bottomSeparator, icon, isLogout }, index) => (
            <NavMenuItem
              label={label}
              link={link}
              index={key}
              activePath={activePath}
              // isTourOpened={isTourOpened}
              bottomSeparator={bottomSeparator}
              key={index}
              icon={icon}
              isLogout={isLogout}
            />
          ))}
        </FlexCol>
        <p className="text-xs text-inactive mt-4 ml-8">v{packageInfo.version}</p>
      </FlexCol>
      <MainMenuModal
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
        onCreate={() => {
          setShowMenu(false);
          setShowCreateModal(true);
        }}
      />
      {showCreateModal && <CreateNetworkModal isOpen={showCreateModal} onClose={() => setShowCreateModal(false)} />}
    </nav>
  );
};
