import React from 'react';
import { isMobile } from 'react-device-detect';

export const Switch = ({ value = true, onClick, onChange }) => {
  return (
    <div
      className={`${isMobile ? 'my-2' : ''}`}
      onClick={(e) => {
        if (!onChange && onClick) {
          e.preventDefault();
          onClick();
        }
      }}>
      <label className="flex items-center cursor-pointer relative">
        <input type="checkbox" checked={value} className="sr-only" onChange={(e) => onChange && onChange(e.target.checked)} />
        <div className={`toggle-bg bg-gray-200 border-2 border-gray-200 rounded-full ${isMobile ? 'h-6 w-43px' : 'h-4 w-7'}`}></div>
      </label>
    </div>
  );
};
