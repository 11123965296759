import React, { useState } from 'react';

export const HashtagItem = ({ hashtag, onDelete = () => {} }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="flex-1 border border-gray-300 rounded-lg justify-center items-center p-3 relative"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}>
      <span className="text-base font-medium text-gray-800">{hashtag.text}</span>
      {hover && (
        <div className="absolute right-2 top-0 bottom-0 flex items-center justify-center">
          <div
            className="border-2 font-bold text-xs border-gray-600 text-gray-600 bg-white rounded-full flex justify-center items-center w-5 h-5 ml-2 cursor-pointer"
            onClick={onDelete}>
            <span>✕</span>
          </div>
        </div>
      )}
    </div>
  );
};
