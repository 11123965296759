const GetNetworkCategories = `
  query GetNetworkCategories($network_uuid: uuid!) {
    network_category(where: {network_uuid: {_eq: $network_uuid}} order_by: { order: asc }) {
      order
      network_uuid
      category_uuid
      category {
        uuid
        category
        tags: network_category_hashtags(where: { network_uuid: { _eq: $network_uuid }} order_by: { created_at: asc }) {
          hashtag {
            uuid
            text
          }
        }
      }
    }
  }
`;

export default GetNetworkCategories;
