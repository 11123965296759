import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardContainer, UserTable } from '@app/components';

import { userSelector } from '@app/store/session/selector';
import { fetchAdminUsersAction, fetchUsersAction, updateUserSearchAction } from '@app/store/users/actions';
import { userSearchKeySelector, usersSelector } from '@app/store/users/selector';
import { Input } from '@app/components/Input';

export const UsersPage = () => {
  const dispatch = useDispatch();
  const { uuid: user_uuid } = useSelector(userSelector);
  const items = useSelector(usersSelector);
  const search = useSelector(userSearchKeySelector);
  useEffect(() => {
    if (user_uuid) {
      dispatch(fetchAdminUsersAction());
      dispatch(fetchUsersAction());
    }
  }, [dispatch, user_uuid]);

  return (
    <DashboardContainer className="px-5 py-4">
      <div className="flex flex-1 flex-col">
        <UserTable
          items={items}
          search={search}
          action={
            <Input
              placeholder="Search"
              value={search}
              inputFieldClassName="h-10"
              onChange={(e) => dispatch(updateUserSearchAction(e.target.value))}
            />
          }
        />
      </div>
    </DashboardContainer>
  );
};
