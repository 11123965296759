const InsertCategoryToNetwork = `
  mutation InsertCategoryToNetwork(
    $category_uuid: uuid,
    $network_uuid: uuid,
    $order: Int,
  ) {
    insert_network_category_one(object: {
      category_uuid: $category_uuid,
      network_uuid: $network_uuid,
      order: $order,
    }) {
      __typename
    }
  }
`;

export default InsertCategoryToNetwork;
