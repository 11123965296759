const GetHashtagByName = `
  query GetHashtagByName($hashtag: String, $category_uuid: uuid, $network_uuid: uuid) {
    hashtag(where: { text: { _ilike: $hashtag} }) {
      uuid
      category: network_category_hashtags(where: { network_uuid: { _eq: $network_uuid }, category_uuid: { _eq: $category_uuid } }) {
        uuid: category_uuid
      }
    }
  }
`;

export default GetHashtagByName;
