import { all, fork, put, select, take } from 'redux-saga/effects';

import { getError, showErrorToast, urqlClient } from '@app/utils';
import { FAIL, START, SUCCESS } from '../common';

import { FETCH_LOGS, UPDATE_EXCEPTION_STATUS } from './actions';
import GetLogs from '@app/queries/GetLogs';
import UpdateExceptionStatus from '@app/mutations/UpdateExceptionStatus';
import { logFilterSelector } from './selector';

function* fetchLogs() {
  while (true) {
    const { payload } = yield take(FETCH_LOGS + START);
    const state = yield select();
    const { resolved } = logFilterSelector(state);
    const { data, error } = yield urqlClient.query(GetLogs, { resolved, platform: payload }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_LOGS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_LOGS + SUCCESS, payload: data.exception });
    }
  }
}

function* updateException() {
  while (true) {
    const { payload: uuid } = yield take(UPDATE_EXCEPTION_STATUS + START);
    const { data, error } = yield urqlClient.mutation(UpdateExceptionStatus, { uuid }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: UPDATE_EXCEPTION_STATUS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: UPDATE_EXCEPTION_STATUS + SUCCESS, payload: data.update_exception_by_pk.uuid });
    }
  }
}

export function* logsSagas() {
  yield all([fork(fetchLogs), fork(updateException)]);
}
