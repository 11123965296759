import React, { useEffect, useState } from 'react';

import { NETWORK_ADMIN_ROLE } from '@shared/constants';
import { DoubleConfirmModal, UserDetailModal } from '@app/components/modals';
import { useDispatch, useSelector } from 'react-redux';
import { userActionStatusSelector } from '@app/store/network/selector';
import { blockUserAction, makeAdminAction } from '@app/store/network/actions';
import FlexRow from './FlexRow';
import { Input } from './Input';
import FlexCol from './FlexCol';
import SearchIcon from '@app/assets/images/searchIcon.svg';
import RightArrow from '@app/assets/images/right-arrow.svg';
import _ from 'lodash';
import { Avatar } from './Avatar';

const filterSearchUser = (items = [], searchKey = '') => {
  const groups = _.groupBy(
    items.filter(
      ({ user }) =>
        user?.phone?.includes(searchKey) ||
        user?.name?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchKey.toLowerCase()),
    ),
    (item) => (item?.role === NETWORK_ADMIN_ROLE ? 'admin' : 'member'),
  );
  return [..._.sortBy(groups.admin, 'user.name'), ..._.sortBy(groups.member, 'user.name')];
};

export const MobileMemberTable = ({ items, isSuperAdmin = false }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isBlockModalActive, setIsBlockModalActive] = useState(false);
  const [isUnblockModalActive, setIsUnblockModalActive] = useState(false);
  const [isPromoteModalActive, setIsPromoteModalActive] = useState(false);
  const [isDemoteModalActive, setIsDemoteModalActive] = useState(false);
  const [isPromoteManagerModalActive, setIsPromoteManagerModalActive] = useState(false);
  const [isDemoteManagerModalActive, setIsDemoteManagerModalActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { loading, success } = useSelector(userActionStatusSelector);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setIsBlockModalActive(false);
    setIsUnblockModalActive(false);
    setIsPromoteModalActive(false);
    setIsDemoteModalActive(false);
    setIsPromoteManagerModalActive(false);
    setIsDemoteManagerModalActive(false);
  }, [loading, success]);

  useEffect(() => {
    const item = items.find(({ user_uuid }) => selectedItem?.user_uuid === user_uuid);
    setSelectedItem(item);
  }, [items, selectedItem]);

  return (
    <FlexCol className="flex-1 w-full py-7">
      <FlexCol className="px-7">
        <div className="font-sans text-34 text-dark">All members</div>
        <div className="text-base text-inactive">All active members.</div>
      </FlexCol>
      <FlexCol className="px-7">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full rounded-full px-4 py-3 mt-12 items-center shadow-none flex bg-bg1"
          inputFieldClassName="search-box w-full text-black px-2"
          rightElement={<img src={SearchIcon} className="w-4 h-4 mr-2" />}
        />
      </FlexCol>
      <FlexCol className="py-4 px-7 h-full overflow-y-scroll">
        {filterSearchUser(items, search).map((item, index) => (
          <FlexRow
            key={index}
            className="items-center p-5 mt-4 rounded-xl shadow-custom1 text-dark text-11 member-table-row"
            onClick={() => {
              setShowDetails(true);
              setSelectedItem(item);
            }}>
            <Avatar
              name={item?.user?.name}
              className="w-12 h-12 border rounded-full flex-shrink-0 overflow-hidden bg-primary"
              labelClassName="text-xs text-white"
              imageSrc={item?.user?.user_profile?.photo}
            />
            <FlexCol className="flex-1 ml-4">
              <div className="text-sm">{item?.user?.name}</div>
              <div className="text-xs text-inactive mt-1">@{item?.user?.user_profile?.username}</div>
            </FlexCol>
            <FlexCol className="items-right justify-center flex-shrink-0 select-none">
              <img src={RightArrow} style={{ color: '#222222', opacity: '0.3', width: 16, height: 16 }} />
            </FlexCol>
            <FlexRow className="items-center flex-shrink-0 justify-between"></FlexRow>
          </FlexRow>
        ))}
      </FlexCol>
      {isBlockModalActive && selectedItem && (
        <DoubleConfirmModal
          isOpen={isBlockModalActive}
          heading="Block member"
          description={`Are you sure you want to block\n ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, block"
          isFetching={loading}
          onSubmit={() => dispatch(blockUserAction(selectedItem))}
          onCancel={() => setIsBlockModalActive(false)}
          onClose={() => setIsBlockModalActive(false)}
        />
      )}
      {isUnblockModalActive && selectedItem && (
        <DoubleConfirmModal
          isOpen={isUnblockModalActive}
          heading="Unblock member"
          description={`Are you sure you want to unblock\n ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, unblock"
          isFetching={loading}
          onSubmit={() => dispatch(blockUserAction(selectedItem))}
          onCancel={() => setIsUnblockModalActive(false)}
          onClose={() => setIsUnblockModalActive(false)}
        />
      )}
      {(isPromoteModalActive || isPromoteManagerModalActive) && selectedItem && (
        <DoubleConfirmModal
          isOpen={isPromoteModalActive || isPromoteManagerModalActive}
          heading={isPromoteModalActive ? 'Make admin' : 'Make Content moderator'}
          description={`Are you sure you want to promote ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, promote"
          isFetching={loading}
          onSubmit={() => dispatch(makeAdminAction({ user: selectedItem, forAdmin: isPromoteModalActive }))}
          onCancel={() => {
            setIsPromoteModalActive(false);
            setIsPromoteManagerModalActive(false);
          }}
          onClose={() => {
            setIsPromoteModalActive(false);
            setIsPromoteManagerModalActive(false);
          }}
        />
      )}
      {(isDemoteModalActive || isDemoteManagerModalActive) && selectedItem && (
        <DoubleConfirmModal
          isOpen={isDemoteModalActive || isDemoteManagerModalActive}
          heading="Demote member"
          description={`Are you sure you want to demote ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, demote"
          isFetching={loading}
          onSubmit={() => dispatch(makeAdminAction({ user: selectedItem, forAdmin: isDemoteModalActive }))}
          onCancel={() => {
            setIsDemoteModalActive(false);
            setIsDemoteManagerModalActive(false);
          }}
          onClose={() => {
            setIsDemoteModalActive(false);
            setIsDemoteManagerModalActive(false);
          }}
        />
      )}
      {showDetails && selectedItem && (
        <UserDetailModal
          isOpen={showDetails}
          item={selectedItem}
          isSuperAdmin={isSuperAdmin}
          setIsBlockModalActive={setIsBlockModalActive}
          setIsUnblockModalActive={setIsUnblockModalActive}
          setIsPromoteModalActive={setIsPromoteModalActive}
          setIsDemoteModalActive={setIsDemoteModalActive}
          setIsPromoteManagerModalActive={setIsPromoteManagerModalActive}
          setIsDemoteManagerModalActive={setIsDemoteManagerModalActive}
          onClose={() => {
            setShowDetails(false);
            setSelectedItem(null);
          }}
        />
      )}
    </FlexCol>
  );
};
