export const NETWORK_ADMIN_ROLE = "NETWORK_ADMIN";
export const NETWORK_MANAGER_ROLE = "NETWORK_MANAGER";
export const MEMBER_ROLE = "MEMBER";
export const BLOCKED_ROLE = "BLOCKED";

export const PENDING_NETWORK_USER_RELATIONSHIP_TYPE = "PENDING";
export const CONNECTED_NETWORK_USER_RELATIONSHIP_TYPE = "CONNECTED";
export const DECLINED_NETWORK_USER_RELATIONSHIP_TYPE = "DECLINED";
export const BLOCKED_NETWORK_USER_RELATIONSHIP_TYPE = "BLOCKED";
export const MUTED_NETWORK_USER_RELATIONSHIP_TYPE = "MUTED";

export const SUPER_ADMIN_VIEW_CONTEXT = "999999";
export const APPSTORE_LINK = "https://declaration.page.link/app";

export const NETWORK_ACCESS_REQUEST_COUNT_USERS_OPTIONS = [
  {
    label: "0 - 100",
    value: "(0,100)",
  },
  {
    label: "100 - 500",
    value: "(100,500)",
  },
  {
    label: "500 - 2,000",
    value: "(500,2000)",
  },
  {
    label: "2,000 - 5,000",
    value: "(2000,5000)",
  },
  {
    label: "5,000 - 10,000",
    value: "(2000,10000)",
  },
  {
    label: "10,000+",
    value: "(10000,)",
  },
];

export const BASE_CONTENT_TEMPLATE_TYPE = "BASE_CONTENT_TEMPLATE_TYPE";
export const POLL_CONTENT_TEMPLATE_TYPE = "POLL_CONTENT_TEMPLATE_TYPE";
export const AVAILABILITY_LISTING_CONTENT_TEMPLATE_TYPE =
  "AVAILABILITY_LISTING_CONTENT_TEMPLATE_TYPE";
export const OPPORTUNITY_LISTING_CONTENT_TEMPLATE_TYPE =
  "OPPORTUNITY_LISTING_CONTENT_TEMPLATE_TYPE";
export const EVENT_CONTENT_TEMPLATE_TYPE = "EVENT_CONTENT_TEMPLATE_TYPE";
export const SESSION_CONTENT_TEMPLATE_TYPE = "SESSION_CONTENT_TEMPLATE_TYPE";
export const ROUNDTABLE_CONTENT_TEMPLATE_TYPE =
  "ROUNDTABLE_CONTENT_TEMPLATE_TYPE";
export const MEDIA_CONTENT_TEMPLATE_TYPE = "MEDIA_CONTENT_TEMPLATE_TYPE";
export const ARTICLE_LINK_CONTENT_TEMPLATE_TYPE =
  "ARTICLE_LINK_CONTENT_TEMPLATE_TYPE";
export const VIDEO_LINK_CONTENT_TEMPLATE_TYPE =
  "VIDEO_LINK_CONTENT_TEMPLATE_TYPE";

export const USER_NOTIFICATION_SETTINGS = {
  OFF: "OFF",
  CONNECTED: "CONNECTED",
};

export const NOTIFICATION_TYPES = {
  CONTENT_CREATE: "CONTENT_CREATE",
  CONTENT_LIKE: "CONTENT_LIKE",
  CONTENT_MENTION: "CONTENT_MENTION",
  DIRECT_MESSAGE: "DIRECT_MESSAGE",
  LOOP_INVITE: "LOOP_INVITE",
  LOOP_MESSAGE: "LOOP_MESSAGE",
  MEMBER_CONNECT_REQUEST: "MEMBER_CONNECT_REQUEST",
  MEMBER_CONNECT: "MEMBER_CONNECT",
  POST_COMMENT: "POST_COMMENT",
  POST_REPLY: "POST_REPLY",
  ROUNDTABLE_INVITE: "ROUNDTABLE_INVITE",
  ROUNDTABLE_LIVE: "ROUNDTABLE_LIVE",
  ROUNDTABLE_SCHEDULE: "ROUNDTABLE_SCHEDULE",
  ROUNDTABLE_BOOK: "ROUNDTABLE_BOOK",
  ROUNDTABLE_SELF_BOOK: "ROUNDTABLE_SELF_BOOK",
  ROUNDTABLE_DECLINE: "ROUNDTABLE_DECLINE",
  REACT_MESSAGE: "REACT_MESSAGE",
  USER_REFERRAL_FINISH: "USER_REFERRAL_FINISH",
  CONTENT_POLL_VOTE: "CONTENT_POLL_VOTE",
  CONTENT_DECLINE: "CONTENT_DECLINE",
};

export const ROUNDTABLE_USER_ROLE = {
  MODERATOR: "MODERATOR",
  SPEAKER: "SPEAKER",
  LISTENER: "ATTENDEE",
};

export const ROUNDTABLE_USER_INVITE_STATUS = {
  ACCEPT: "ACCEPT",
  PENDING: "PENDING",
  REQUEST: "REQUEST",
  DECLINED: "DECLINED",
};

export const ROUNDTABLE_USER_STATUS = {
  REQUEST_SPEAK: "REQUEST_SPEAK",
  MUTE: "MUTE",
  UNMUTE: "UNMUTE",
};

export const POST_DECLINE_REASONS = [
  "Violence and criminal behavior",
  "Objectionable content",
  "Safety",
  "Integrity and authenticity",
  "Respecting intellectual property",
  "Other",
];

export const NETWORK_PAID_FETURES = {
  ROUNDTABLE_CREATION: "ROUNDTABLE_CREATION",
  ROUNDTABLE_JOIN: "ROUNDTABLE_JOIN",
};

export const SYSTEM_USER_EMAIL = "contact@declaration.net";

export const SYSTEM_USER_TEMPLATE = {
  WELCOME_MESSAGE: {
    toAdmin:
      "We are honored that you've joined and we don't take that lightly.\n\nJust a few steps left to finalize your account on the desktop dashboard.\n\n1. Update your categories of interest for your bespoke audience.\n\n2. Invite colleagues and assign them admin roles to help manage your space.\n\n3. Set your monthly membership fee.",
    toMember:
      "We are honored that you've joined and we don't take that lightly.\n\nDeclaration was designed to remove the clutter from social media.\n\nEvery member in your space has opted-in to be here. A hyper-curated audience of goal-oriented members who share the same goal.\n\nThis is what makes you and your community special.\n\nWe've got your back. Dare to be great.",
  },
  PUBLIC_LOOP_NAME: "the_power_of_loops",
  PUBLIC_LOOP_MESSAGES: [
    "Information loops are powerful.",
    "Join loops to learn or share real-time information about the things you care about most.",
    "Use loops to continue discussions that go beyond comments in the feed.",
  ],
  PRIVATE_LOOP_NAME: "5_growth_tips",
  PRIVATE_LOOP_MESSAGES: [
    "1. At first, you will create a lot of content and culture, but once it takes off the community will power itself.",
    "2. Post unique content that can't be found anyplace else online - think insider knowledge. Members join to get tools to help them make more money.",
    "3. Host one group event each month to discuss what is working and what do your members need help with.",
    "4. Lastly, be smart about which group you choose to build. It's your edge. For exclusive networks, smaller groups value close connections. Large networks have too much noise.",
    "5. For large networks, these members value content. More people means more posts activity and new connections made.",
  ],
  PUBLIC_POSTS: [
    {
      media: {
        data: {
          original_url: "ThankYou",
          type: "video/mp4",
        },
      },
    },
    {
      heading:
        "What you need to build a great community\n\n• Have at least 1 event a month\n\n• Unique content that can’t be found anyplace online\n\n• Knowledge sharing of information, skills, or expertise",
    },
    {
      media: {
        data: {
          original_url: "Roundtable",
          type: "image/jpeg",
        },
      },
    },
    {
      heading:
        "Leverage polls to get real-time feedback on what members want from your space.",
      content_partial_poll_options: [
        {
          poll_option: {
            data: {
              text: "Content",
            },
          },
        },
        {
          poll_option: {
            data: {
              text: "Guest speakers",
            },
          },
        },
        {
          poll_option: {
            data: {
              text: "Connections",
            },
          },
        },
      ],
    },
    {
      heading:
        "Pro tip: Encourage members to share their challenges with the community. It’s how 83% of questions asked by Salesforce customers are answered by other customers.",
    },
    {
      media: {
        data: {
          original_url: "Welcome",
          type: "video/mp4",
        },
      },
    },
  ],
};

export const NETWORK_DEFAULT_CATEGORIES = [
  {
    name: "Knowledge",
    hashtags: [
      "Science",
      "The Future",
      "Physics",
      "Psychology",
      "History",
      "Philosophy",
      "Education",
      "Space",
      "Biology",
      "Covid-19",
      "Math",
    ],
  },
  {
    name: "Tech",
    hashtags: [
      "Venture Capital",
      "Startups",
      "AI",
      "Engineering",
      "Angel Investing",
      "DTC",
      "Product",
      "Crypto",
      "Saas",
      "VR/AR",
      "Marketing",
    ],
  },
  {
    name: "Hustle",
    hashtags: [
      "Networking",
      "Pitch Practice",
      "Instagram",
      "TikTok",
      "Angel Investing",
      "DTC",
      "Stocks",
      "Small Business",
      "Entrepreneurship",
    ],
  },
];
