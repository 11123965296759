import { all, fork, put, call, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { axiosInstance, getError, showErrorToast, urqlClient } from '@app/utils';
import { FAIL, START, SUCCESS } from '../common';

import { AUTHORIZE_NETOWRK_USER, SUBSCRIBE_NETWORK_PLAN } from './actions';
import { removeAuthToken, setAuthToken } from '@app/config';
import GetNetworkById from '@app/queries/GetNetworkById';

function* authorizeNetworkUser() {
  while (true) {
    const {
      payload: { token, networkId },
    } = yield take(AUTHORIZE_NETOWRK_USER + START);
    try {
      yield call(setAuthToken, token);
      const { data, error } = yield urqlClient.query(GetNetworkById, { id: networkId }).toPromise();

      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: AUTHORIZE_NETOWRK_USER + FAIL, payload: getError(error) });
        yield call(removeAuthToken);
        yield put(push('/'));
      } else {
        const { data: user } = yield axiosInstance.get('/user-payment-info');
        yield put({ type: AUTHORIZE_NETOWRK_USER + SUCCESS, payload: { network: data.network[0], user } });
      }
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: AUTHORIZE_NETOWRK_USER + FAIL, payload: getError(error) });
      yield call(removeAuthToken);
      yield put(push('/'));
    }
  }
}

function* subscribeNetworkPlan() {
  while (true) {
    const { payload } = yield take(SUBSCRIBE_NETWORK_PLAN + START);
    try {
      const { data } = yield axiosInstance.post('/stripe-subscribe', payload);
      yield put({ type: SUBSCRIBE_NETWORK_PLAN + SUCCESS, payload: data });
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: SUBSCRIBE_NETWORK_PLAN + FAIL, payload: getError(error) });
    }
  }
}

export function* mobileSagas() {
  yield all([fork(authorizeNetworkUser), fork(subscribeNetworkPlan)]);
}
