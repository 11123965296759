// import { MEMBER_ROLE, NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE } from '@app/../shared/constants';
import { NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE } from '@app/../shared/constants';
import { ComingSoon } from '@app/components';
import { Logs } from './Logs';
import { NetworkAnalyticsPage } from './NetworkAnalyticsPage';
// import { NetworkBillingPage } from './NetworkBillingPage';
import { NetworkInterestsPage } from './NetworkInterestsPage';
import { NetworkInvitationsPage } from './NetworkInvitationsPage';
import { NetworkKnowledgePage } from './NetworkKnowledgePage';
import { NetworkPendingPage } from './NetworkPendingPage';
import { NetworkSettingsPage } from './NetworkSettingsPage';
import { NetworksPage } from './NetworksPage';
import { NetworkUsersPage } from './NetworkUsersPage';
import { UsersPage } from './UsersPage';

export const routes = [
  {
    key: '/members',
    exact: true,
    path: '/networks/:id/members',
    component: NetworkUsersPage,
    permission: [NETWORK_ADMIN_ROLE],
  },
  {
    key: '/pending',
    exact: true,
    path: '/networks/:id/pending-posts',
    component: NetworkPendingPage,
    permission: [NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE],
  },
  {
    key: '/analytics',
    exact: true,
    path: '/networks/:id/analytics',
    component: NetworkAnalyticsPage,
    permission: [NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE],
  },
  {
    key: '/interests',
    exact: true,
    path: '/networks/:id/interests',
    component: NetworkInterestsPage,
    permission: [NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE],
  },
  {
    key: '/settings',
    exact: true,
    path: '/networks/:id/settings',
    component: NetworkSettingsPage,
    permission: [NETWORK_ADMIN_ROLE],
  },
  // {
  //   key: '/billing',
  //   exact: true,
  //   path: '/networks/:id/billing',
  //   component: NetworkBillingPage,
  //   permission: [NETWORK_ADMIN_ROLE, MEMBER_ROLE],
  // },
  // {
  //   key: '/billing',
  //   exact: true,
  //   path: '/networks/:id/billing/:status',
  //   component: NetworkBillingPage,
  //   permission: [NETWORK_ADMIN_ROLE, MEMBER_ROLE],
  // },
  // {
  //   key: '/members',
  //   exact: true,
  //   path: '/networks/:id',
  //   component: ComingSoon,
  //   permission: [NETWORK_ADMIN_ROLE],
  // },
  {
    key: '/knowledge',
    exact: true,
    path: '/networks/:id/knowledge',
    component: NetworkKnowledgePage,
    permission: [NETWORK_ADMIN_ROLE],
  },
  {
    key: '/invitations',
    exact: true,
    path: '/networks/:id/invitations',
    component: NetworkInvitationsPage,
    permission: [NETWORK_ADMIN_ROLE],
  },
  {
    key: '/networks',
    exact: true,
    path: '/networks',
    component: NetworksPage,
    permission: [NETWORK_ADMIN_ROLE],
  },
  {
    key: '/users',
    exact: true,
    path: '/users',
    component: UsersPage,
    permission: [NETWORK_ADMIN_ROLE],
  },
  {
    key: '/knowledge',
    exact: true,
    path: '/knowledge',
    component: ComingSoon,
    permission: [NETWORK_ADMIN_ROLE],
  },
  {
    key: '/logs',
    exact: true,
    path: '/logs',
    component: Logs,
  },
];
