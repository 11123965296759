import React from 'react';

import { Button } from './Button';
import FlexRow from './FlexRow';
import { SpinnerIcon } from './SpinnerIcon';

export const Input = ({
  label,
  value,
  type, // text, email, password, submit
  placeholder,
  disabled = false,
  rows,
  maxLength,
  inputFieldClassName,
  isAutofocused = false,
  isRequired = false,
  isFetching = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyDown = () => {},
  className = '',
  labelStyle = '',
  rightElement = undefined,
}) => {
  const uniqueId = `input-${type}-${(label || Math.random().toString()).replace(/[^\w_]/g, '-')}`;
  return (
    <div className={`input relative ${className}`}>
      {type !== 'submit' && label && (
        <label htmlFor={uniqueId} className={labelStyle || ''}>
          {label}
          <span className="text-red-600">{isRequired ? '*' : ''}</span>
        </label>
      )}
      {type === 'submit' && <Button className="w-full py-2" type={type} label={label} size="large" isFetching={isFetching} />}

      {type === 'text' && rows && (
        <textarea
          id={uniqueId}
          autoFocus={isAutofocused}
          required={isRequired}
          rows={rows}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={inputFieldClassName ? inputFieldClassName : ''}
        />
      )}

      {type !== 'submit' && !rows && (
        <input
          id={uniqueId}
          type={type}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          autoFocus={isAutofocused}
          required={isRequired}
          onChange={onChange}
          disabled={disabled}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          className={inputFieldClassName ? inputFieldClassName : ''}
        />
      )}
      {!!rightElement && <FlexRow className="absolute right-2 top-0 bottom-0 items-center justify-center">{rightElement}</FlexRow>}

      {isFetching && type !== 'submit' && (
        <div className="absolute top-0 right-0">
          <SpinnerIcon fill="inherit" />
        </div>
      )}
    </div>
  );
};
