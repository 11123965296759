import { createSelector } from 'reselect';

const getUsersState = (state) => state.users;
export const userSearchKeySelector = createSelector([getUsersState], ({ searchKey = '' }) => searchKey);
export const usersSelector = createSelector([getUsersState], ({ items = [], searchKey }) => {
  return items
    .filter(
      (user) =>
        user?.phone?.includes(searchKey) ||
        user?.name?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
        Object.values(user?.networksById)
          .map(({ name }) => name)
          .filter((name) => name?.toLowerCase()?.includes(searchKey?.toLowerCase()))?.length > 0,
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1));
});
