import { Env } from '@app/config';
import jsonwebtoken from 'jsonwebtoken';

const { JWT_SECRET_KEY } = Env;

export const generateRegisterLink = (userId, networkId, networkName) => {
  const token = jsonwebtoken.sign({ userId, networkId, networkName }, JWT_SECRET_KEY);
  return `${Env.BASE_URL}/registration/${token}`;
};

export const parseRegisterToken = (token) => {
  return jsonwebtoken.decode(token, JWT_SECRET_KEY);
};
