import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { Button, PageContainer } from '@app/components';
import LogInForm from '@app/components/LogInForm';
import { submitEmail, submitPhone, submitPhoneCode } from '@app/store/session/actions';
import { isLoggedInSelector, sessionSelector } from '@app/store/session/selector';

import FlexRow from '@app/components/FlexRow';
import LOGO from '@app/assets/images/logo.svg';
import CheckEmail from '@app/assets/images/checkEmail.svg';
import { Input } from '@app/components/Input';

export const Login = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedInSelector);
  const [code, setCode] = useState('');
  const { loading, emailSuccess, phoneSuccess } = useSelector(sessionSelector);

  if (loggedIn) {
    return <Redirect to="/networks" />;
  }

  return (
    <PageContainer className="flex-col">
      <div className="flex flex-full h-full items-center justify-center w-80">
        {!(emailSuccess || phoneSuccess) && (
          <LogInForm
            isFetching={loading}
            onSubmit={({ value, type }) => dispatch(type === 'email' ? submitEmail(value) : submitPhone({ phone: value }))}
          />
        )}
        {!loading && emailSuccess && (
          <div className="relative h-full flex flex-col w-full items-center justify-center">
            <img src={CheckEmail} className="w-16 h-16" />
            <div className="text-center text-30 leading-8 mt-6 font-sans">
              Check your email to
              <br />
              verify your account.
            </div>
          </div>
        )}
        {!loading && phoneSuccess && (
          <form
            className="relative h-full flex flex-col w-full items-center justify-center"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(submitPhoneCode({ code }));
            }}>
            <div className="text-center text-30 leading-8 mt-6 font-sans">
              Enter the code
              <br />
              we just texted you.
            </div>
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter Code"
              isRequired
              className="bg-white shadow-custom1 rounded-xl px-4 py-2 mt-8"
            />
            <Button label="Next" className="text-white bg-primary-green rounded-xl w-1/2 py-2 mt-10" />
            <Button label="Didn't receive a code? Tap here to resend." className="mt-4 text-label hover:shadow-none text-xs" />
          </form>
        )}
      </div>
      <FlexRow className="absolute top-8">
        <a href="/">
          <img src={LOGO} className="" />
        </a>
      </FlexRow>
    </PageContainer>
  );
};
