import { NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE } from '@shared/constants';

const GetUserAsAdmin = `
  query GetUserAsAdmin($uuid: uuid!) {
    user_by_pk(uuid: $uuid) {
      __typename
      uuid
      id
      name
      email
      created_at
      updated_at
      user_profile {
        photo
      }
      stripe_merchants {
        user_uuid
        network_id
        account_id
        charges_enabled
        details_submitted
        payouts_enabled
      }
      network_users(
        where: { _or: [{role: {_eq: ${NETWORK_ADMIN_ROLE} }}, {role: {_eq: ${NETWORK_MANAGER_ROLE} }}]},
        order_by: {network: {name: asc}}
      ) {
        network {
          __typename
          uuid
          id
          name
          avatar
          archived_at
        }
      }
      super_admin {
        __typename
        user_uuid
      }
    }
    network {
      __typename
      uuid
      id
      name
      avatar
      archived_at
    }
  }
`;

export default GetUserAsAdmin;
