import { all, fork } from 'redux-saga/effects';
import { logsSagas } from './logs/saga';
import { mobileSagas } from './mobile/saga';
import { networkSagas } from './network/saga';
import { sessionSagas } from './session/saga';
import { usersSagas } from './users/saga';

export default function* rootSaga() {
  yield all([fork(sessionSagas)]);
  yield all([fork(networkSagas)]);
  yield all([fork(usersSagas)]);
  yield all([fork(logsSagas)]);
  yield all([fork(mobileSagas)]);
}
