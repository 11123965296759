import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  allNetworkSelector,
  deleteNetworkSelector,
  networkArchivedVisibleSelector,
  networkRequestsSelector,
  networkSearchSelector,
} from '@app/store/network/selector';
import { NetworkTable, Button, NetworkAccessRequestTable, DashboardContainer } from '@app/components';
import { CreateNetworkModal, DoubleConfirmModal } from '@app/components/modals';
import {
  deleteNetworkkAction,
  initDeleteNetworkAction,
  toggleNetworkArchivedVisibleAction,
  updateNetworkSearchAction,
} from '@app/store/network/actions';
import { Input } from '@app/components/Input';

export const NetworksPage = () => {
  const dispatch = useDispatch();
  const [showCreate, setShowCreate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const requests = useSelector(networkRequestsSelector);
  const allNetworks = useSelector(allNetworkSelector);
  const networkSearchKey = useSelector(networkSearchSelector);
  const hideArchived = useSelector(networkArchivedVisibleSelector);
  const deleteNetwork = useSelector(deleteNetworkSelector);
  useEffect(() => {
    if (!showCreate) {
      setSelectedNetwork({});
    }
  }, [showCreate]);

  useEffect(() => {
    if (!deleteNetwork.loading && deleteNetwork.success) {
      setShowConfirmDelete(false);
      setSelectedNetwork({});
    }
  }, [deleteNetwork]);

  useEffect(() => {
    dispatch(initDeleteNetworkAction());
  }, [dispatch, showConfirmDelete]);

  const handleCreateNew = () => {
    setShowCreate(true);
  };
  const handleAccept = ({ id }) => {
    setSelectedNetwork(requests.find((sItem) => sItem.id === id));
    setShowCreate(true);
  };
  const handleDecline = ({ id }) => {
    setSelectedNetwork(requests.find((sItem) => sItem.id === id));
    setShowConfirmDelete(true);
  };
  const handleDelete = ({ id }) => {
    dispatch(deleteNetworkkAction(id));
  };
  return (
    <DashboardContainer className="px-5 py-4">
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col">
          <div className="mb-9">
            <NetworkAccessRequestTable items={requests} onAccept={handleAccept} onDecline={handleDecline} />
          </div>
          <NetworkTable
            items={allNetworks}
            action={
              <div className="flex flex-col items-end">
                <Button label="Create new Network" onClick={handleCreateNew} />
                <div className="flex flex-row justify-center items-center mt-2">
                  <Input
                    placeholder="Search network..."
                    value={networkSearchKey}
                    onChange={(e) => dispatch(updateNetworkSearchAction(e.target.value))}
                    inputFieldClassName="h-10 w-48"
                  />
                  <div
                    className="cursor-pointer flex flex-row w-full ml-4 items-center select-none"
                    onClick={() => dispatch(toggleNetworkArchivedVisibleAction())}>
                    <div
                      className={`w-5 h-5 rounded flex flex-shrink-0 items-center justify-center mr-2 ${
                        hideArchived ? 'bg-primary' : 'border-gray-600 border-2'
                      }`}>
                      {hideArchived && <span className="text-white font-bold">✓</span>}
                    </div>
                    <div className="text-xs">Hide Archived Networks</div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        {showCreate && (
          <CreateNetworkModal
            initialValues={
              selectedNetwork && selectedNetwork.user
                ? {
                    name: selectedNetwork.networkName,
                    email: selectedNetwork.user.email,
                    adminName: selectedNetwork.user.name,
                  }
                : {}
            }
            isOpen={showCreate}
            onClose={() => setShowCreate(false)}
          />
        )}
        <DoubleConfirmModal
          isOpen={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          heading={`Are you sure you want to decline this access request for ${selectedNetwork.networkName}?`}
          submitLabel="Yes, decline"
          isFetching={deleteNetwork.loading}
          onSubmit={() => handleDelete({ id: selectedNetwork.id })}
          onCancel={() => setShowConfirmDelete(false)}
        />
      </div>
    </DashboardContainer>
  );
};
