import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';

import { InvitationsRow } from './InvitationsRow';
import { Button } from '.';
import { showErrorToast } from '@app/utils';
import templateFile from '@app/assets/csvTemplate.csv';
import FlexRow from './FlexRow';
import FlexCol from './FlexCol';

export const CsvInviteSection = ({ onSendInvitation = () => {}, loading, success }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading && success) {
      setData([]);
    }
  }, [loading, success]);

  const handleChange = (index, key, value) => {
    const newItems = [...data];
    newItems[index] = { ...data[index], [key]: value };
    setData(newItems);
  };

  const onDelete = (index) => {
    setData(data.filter((item, sIndex) => sIndex !== index));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSendInvitation(data);
  };

  const onDownload = (e) => {
    e.preventDefault();
    document.getElementById('csv-file-download').click();
  };

  const onSelectUpload = (e) => {
    e.preventDefault();
    document.getElementById('csv-file-input').click();
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: handleCSV,
        header: true,
      });
    }
  };

  const handleCSV = ({ data: res, error }) => {
    if (error && error.length) {
      showErrorToast('Invalid CSV file format');
      return;
    }
    console.log(res);
    const items = res
      .filter((row) => row['Member name'] && row['Email address'])
      .map((row) => ({ name: row['Member name'], email: row['Email address'] }));
    if (!items.length) {
      showErrorToast('Invalid CSV file or empty file');
      return;
    }
    setData(items);
  };

  return (
    <form
      className="flex-full flex flex-col items-center overflow-hidden overflow-y-scroll p-7 h-content bg-white rounded-xl mt-8 mr-8 shadow-custom1"
      onSubmit={onSubmit}>
      <div className="w-full flex flex-full flex-col items-center overflow-hidden overflow-y-scroll">
        {!data.length && (
          <>
            <FlexRow className="items-center justify-between w-full">
              <FlexCol>
                <div className="font-medium text-dark text-15">Upload a CSV</div>
                <div className="text-xs text-inactive tracking-tight text-center mt-1">Send bulk email invitations with just a one click</div>
              </FlexCol>
              <FlexRow className="text-center flex justify-center items-center flex-shrink-0">
                <Button
                  type="submit"
                  label="Download CSV"
                  className="w-32 bg-white shadow-custom1 py-2"
                  labelClassName="text-label text-xs"
                  onClick={onDownload}
                />
                <a href={templateFile} id="csv-file-download" download="DeclarationInvites.csv" className="hidden" />
                <Button
                  type="submit"
                  label="Upload CSV"
                  className="w-32 bg-primary-green shadow-custom1 py-2 ml-3"
                  labelClassName="text-white text-xs"
                  onClick={onSelectUpload}
                />
                <input id="csv-file-input" className="hidden" type="file" accept=".csv" onChange={onFileChange} />
              </FlexRow>
            </FlexRow>
            <div className="mt-10 mb-4 relative">
              <div className="flex flex-row border-b border-l border-r">
                <div className="w-40 p-2 bg-inactive text-white text-10 font-medium border-r">Member name</div>
                <div className="w-40 p-2 bg-inactive text-white text-10 font-medium">Email address</div>
              </div>
              {[0, 1, 2].map((row) => (
                <EmptyRow key={row} />
              ))}
              <div className="absolute inset-0 bg-gradient-to-t from-white via-transparent" />
            </div>
          </>
        )}
        {!!data.length && (
          <div className="w-full flex flex-col justify-center items-center py-4">
            <span className="text-9xl font-thin">{data.length}</span>
            <div className="text-sm tracking-tight text-center text-gray-500 mt-1 font-medium mb-4">
              members have been uploaded and are ready for invitations to be sent
            </div>
            <Button type="submit" label="Send email invitations" className="shadow-xl px-5 h-9" />
            <div className="flex flex-col w-full items-center mt-8">
              {data.map((item, index) => (
                <InvitationsRow
                  key={index}
                  item={item}
                  isCSV
                  onDelete={() => onDelete(index)}
                  onChange={(key, value) => handleChange(index, key, value)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

const EmptyRow = () => (
  <div className="flex flex-row border-b border-l border-r">
    <div className="w-40 p-4  text-sm font-medium border-r" />
    <div className="w-40 p-4 text-white text-sm font-medium" />
  </div>
);
