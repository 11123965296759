import {
  NETWORK_ADMIN_ROLE,
  NETWORK_MANAGER_ROLE,
  MEMBER_ROLE,
  BLOCKED_ROLE,
} from '../constants';

const verbalizeUserRole = (role) =>
  (() => {
    // console.log('ROLE', role);
    switch (role) {
      case NETWORK_ADMIN_ROLE:
        return 'Admin';
      case NETWORK_MANAGER_ROLE:
        return 'Content moderator';
      case MEMBER_ROLE:
        return 'Member';
      case BLOCKED_ROLE:
        return 'Blocked';
      default:
        return 'Unknown Role';
    }
  })();

export default verbalizeUserRole;
