import React from 'react';
import FlexRow from './FlexRow';

import { SpinnerIcon } from './SpinnerIcon';

export const Button = ({ label, type, className = '', labelClassName = '', leftIcon, rightIcon, isFetching = false, onClick = () => {} }) => {
  return (
    <button
      className={`flex relative rounded-lg items-center justify-center overflow-hidden ${className}`}
      disabled={isFetching}
      type={type}
      onClick={onClick}>
      {leftIcon && <span className="icon-wrapper">{leftIcon}</span>}

      <span className={`label ${labelClassName}`}>{label}</span>
      {isFetching && (
        <FlexRow className={`absolute inset-0 bg-white bg-opacity-80 items-center justify-center`}>
          <SpinnerIcon fill="inherit" />
        </FlexRow>
      )}

      {rightIcon && <span className="icon-wrapper">{rightIcon}</span>}
    </button>
  );
};

export default Button;
