import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import jsonwebtoken from 'jsonwebtoken';
import { isMobile } from 'react-device-detect';

import { Login, Registration } from '@app/pages/auth';
import Dashboard from '@app/pages/dashboard';

import { LoadingView } from '@app/components';
import { parseQuery } from '@app/utils';

import { history } from '@app/store';
import { authorizingSelector, isLoggedInSelector } from '@app/store/session/selector';
import { routerSelector } from '@app/store/router/selector';
import { getUserAction, submitCode } from '@app/store/session/actions';
import { getAuthToken } from '@app/config';
import MobileDashboard from '@app/pages/mobileDashboard';

const RootRouter = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const authorizing = useSelector(authorizingSelector);
  const {
    location: { search },
  } = useSelector(routerSelector);

  useEffect(() => {
    const getSavedToken = async () => {
      const token = await getAuthToken();
      if (token) {
        const user_uuid = jsonwebtoken.decode(token).sub;
        dispatch(getUserAction(user_uuid));
      }
      setReady(true);
    };
    getSavedToken();
  }, [dispatch]);

  useEffect(() => {
    // if (isMobile) {
    //   return;
    // }
    const queryParams = parseQuery(search);
    if (queryParams.email && queryParams.code) {
      dispatch(submitCode({ email: queryParams.email, code: queryParams.code }));
    }
  }, [search, dispatch]);

  if (authorizing || !ready) {
    return <LoadingView />;
  }

  return (
    <div className="w-full h-full justify-center items-center">
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route path="/membership/" component={Membership} /> */}
          <Route exact path="/registration/:token" component={Registration} />
          <Route path="/" component={isMobile ? MobileDashboard : Dashboard} />
          {isLoggedIn ? <Redirect to="/networks" /> : <Redirect to="/login" />}
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default RootRouter;
