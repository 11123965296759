import * as React from 'react';

function FlagIcon(props) {
  return (
    <svg width="11px" height="17px" viewBox="0 0 11 17" style={{ overflow: 'visible' }} {...props}>
      <path
        d="M10.6869 0.675156C10.4903 0.537778 10.2395 0.505267 10.0146 0.587644C10.001 0.592778 8.63744 1.08631 7.36976 1.08631C6.73909 1.08631 6.25729 0.959444 5.97764 0.719644C5.42056 0.242 4.63076 0 3.63073 0C2.10613 0 0.547311 0.563933 0.4818 0.587889C0.192378 0.693244 0 0.968489 0 1.27624L0 15.9429C0 16.348 0.328289 16.6762 0.733333 16.6762C1.13838 16.6762 1.46667 16.348 1.46667 15.9429L1.46667 7.6428C1.94064 7.47536 2.74193 7.2424 3.5398 7.2424C4.17707 7.2424 4.67402 7.39469 5.01673 7.69462C5.63493 8.23582 6.45724 8.50984 7.45971 8.50984C9.01291 8.50984 10.5072 7.83884 10.57 7.81024C10.8318 7.69169 11 7.43038 11 7.14291L11 1.27624C11 1.03693 10.8829 0.812533 10.6869 0.675156ZM9.53333 6.64327C9.05936 6.81096 8.2566 7.04342 7.46044 7.04342C6.82318 7.04342 6.32598 6.89138 5.98327 6.5912C5.36507 6.05024 4.54276 5.77622 3.54004 5.77622C2.78153 5.77622 2.0372 5.93609 1.46691 6.10304L1.46691 1.81329C1.98049 1.66662 2.82822 1.46642 3.63073 1.46642C4.26189 1.46642 4.7432 1.59329 5.02309 1.83309C5.58018 2.31073 6.36998 2.55273 7.37024 2.55273C8.15051 2.55273 8.94056 2.40509 9.53407 2.25744L9.53407 6.64327L9.53333 6.64327Z"
        transform="matrix(1 0 0 1 0 -7.247536E-13)"
        fill="#222222"
        stroke="none"
      />
    </svg>
    // <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    //   <path
    //     d="M18.22 2.81a.879.879 0 00-.806-.105c-.016.006-1.651.598-3.172.598-.756 0-1.334-.152-1.67-.44C11.906 2.29 10.959 2 9.759 2c-1.828 0-3.698.676-3.776.705a.879.879 0 00-.578.826v17.59a.88.88 0 101.759 0v-9.955c.568-.2 1.53-.48 2.486-.48.764 0 1.36.183 1.771.542.742.65 1.728.978 2.93.978 1.863 0 3.655-.805 3.73-.84a.879.879 0 00.516-.8V3.532a.88.88 0 00-.375-.721zm-1.383 7.157c-.568.201-1.531.48-2.486.48-.764 0-1.36-.182-1.771-.542-.742-.649-1.728-.978-2.93-.978-.91 0-1.803.192-2.487.392V4.175c.616-.176 1.633-.416 2.595-.416.757 0 1.334.152 1.67.44.668.572 1.615.863 2.815.863.936 0 1.883-.178 2.595-.355v5.26z"
    //   />
    // </svg>
  );
}

export default FlagIcon;
