import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LoadingView, ModerationNavContainer } from '@app/components';
import { approvePendingPostAction, fetchPendingPostsAction, fetchUsersByNetworkAction } from '@app/store/network/actions';
import { currentNetworkSelector, pendingPostsSelector } from '@app/store/network/selector';

import { PostDeclineModal } from '@app/components/modals/PostDeclineModal';
import FlexRow from '@app/components/FlexRow';
import { PendingPostDetailModal } from '@app/components/modals';

export const NetworkPendingPage = () => {
  const dispatch = useDispatch();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const { uuid: network_uuid } = useSelector(currentNetworkSelector);
  const { loading, items = [], success } = useSelector(pendingPostsSelector);
  const [selectedItem, setSelectedItem] = useState();
  const { id: networkId } = useParams();
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  useEffect(() => {
    if (!loading && success) {
      setSelectedItem(null);
      setShowDetailModal(false);
    }
  }, [loading, success]);

  useEffect(() => {
    if (network_uuid) {
      dispatch(fetchPendingPostsAction());
    }
  }, [dispatch, network_uuid]);

  const onApprove = () => {
    if (network_uuid && selectedItem) {
      dispatch(approvePendingPostAction(selectedItem.uuid));
    }
  };

  return (
    <>
      <FlexRow className="h-full overflow-auto flex-1">
        <ModerationNavContainer
          title="Moderation"
          subtitle="Filter the quality of content in your space."
          items={items}
          selectedItem={selectedItem}
          onChangeItem={(value) => {
            setSelectedItem(value);
            setShowDetailModal(true);
          }}
        />
      </FlexRow>
      {showDetailModal && selectedItem && (
        <PendingPostDetailModal
          isOpen={showDetailModal}
          onClose={() => setShowDetailModal(false)}
          item={selectedItem}
          onApprove={onApprove}
          setShowDeclineModal={() => setShowDeclineModal(true)}
        />
      )}
      {showDeclineModal && selectedItem && (
        <PostDeclineModal
          isOpen={showDeclineModal}
          content={selectedItem}
          onSuccess={() => {
            setSelectedItem(null);
            setShowDeclineModal(false);
          }}
          onClose={() => setShowDeclineModal(false)}
        />
      )}
      {loading && <LoadingView className="border absolute inset-0 bg-black bg-opacity-10" />}
    </>
  );
};
