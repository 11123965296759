import React from 'react';
import moment from 'moment';
import { BaseModal } from './BaseModal';
import DownArrow from '@app/assets/images/down-arrow.svg';
import FlexRow from '../FlexRow';
import FlexCol from '../FlexCol';
import { Avatar } from '../Avatar';
import { CloudImage } from '@app/utils';
import { Switch } from '../Switch';
import { NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE, MEMBER_ROLE } from '@shared/constants';
import { isMobile } from 'react-device-detect';

const renderProfileImage = (url) => CloudImage(url, { width: 100, height: 100 });

export const UserDetailModal = ({
  isOpen,
  onClose,
  item,
  isSuperAdmin,
  setIsBlockModalActive = () => {},
  setIsUnblockModalActive = () => {},
  setIsPromoteModalActive = () => {},
  setIsDemoteModalActive = () => {},
  setIsPromoteManagerModalActive = () => {},
  setIsDemoteManagerModalActive = () => {},
}) => {
  const isAdmin = item.role === NETWORK_ADMIN_ROLE;
  const isManager = item.role === NETWORK_MANAGER_ROLE;
  const isMember = item.role === MEMBER_ROLE;
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className={`bg-white flex flex-col h-full ml-auto  ${isMobile ? 'w-full p-7' : 'w-72 px-4'}`}>
      <FlexRow className="w-full py-4">
        <img src={DownArrow} className="rotate-90 transform cursor-pointer" style={{ width: 42, height: 42 }} onClick={onClose} />
      </FlexRow>
      <FlexCol className={`w-full items-center justify-center ${!isMobile ? 'flex-1' : ''}`}>
        <Avatar
          name={item?.user.name}
          className="w-72px h-72px rounded-full overflow-hidden bg-primary"
          labelClassName="text-lg text-white"
          imageSrc={renderProfileImage(item?.user?.user_profile?.photo)}
        />
        <div className="font-sans whitespace-pre-line text-center text-2xl mt-3">{item?.user?.name?.split(' ').join('\n')}</div>
        <div className="text-center text-label mt-9 text-xs">@{item?.user?.user_profile?.username}</div>
        <div className="text-center text-label mt-1.5 text-xs">{item?.user?.email || 'No email'}</div>
        <div className="text-center text-label mt-1.5 text-xs">{item?.user?.user_profile?.location || 'No location'}</div>
        {!!item?.user?.user_profile?.date_of_birth && (
          <div className="text-center text-label mt-1.5 text-xs">{moment.utc(item?.user?.user_profile?.date_of_birth).format('MMM DD, yyyy')}</div>
        )}
        <FlexCol className="px-4 w-full">
          {isSuperAdmin && (
            <FlexRow className="mt-12 shadow-custom1 bg-white w-full px-3 py-2 items-center justify-between rounded-lg">
              <div className="text-dark text-xs">Make admin</div>
              <Switch value={isAdmin} onClick={() => (isAdmin ? setIsDemoteModalActive(true) : setIsPromoteModalActive(true))} />
            </FlexRow>
          )}
          <FlexRow className="mt-3 shadow-custom1 bg-white w-full px-3 py-2 items-center justify-between rounded-lg">
            <div className="text-dark text-xs">Make moderator</div>
            <Switch
              value={isManager && !isMember}
              onClick={() => (isManager ? setIsDemoteManagerModalActive(true) : setIsPromoteManagerModalActive(true))}
            />
          </FlexRow>
          <FlexRow className="mt-3 shadow-custom1 bg-white w-full px-3 py-2 items-center justify-between rounded-lg">
            <div className="text-xs text-danger">Block this member</div>
            <Switch value={!!item.is_blocked} onClick={() => (item.is_blocked ? setIsUnblockModalActive(true) : setIsBlockModalActive(true))} />
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </BaseModal>
  );
};
