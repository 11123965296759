import { formatDateTimeAgo } from '@shared/utils/formatDate';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Avatar } from './Avatar';
import EyeIcon from './EyeIcon';
import FlexCol from './FlexCol';
import FlexRow from './FlexRow';

export const ModerationNavContainer = ({ className = '', title, subtitle, items = [], selectedItem, onChangeItem = () => {} }) => {
  return (
    <FlexCol className={`${className} ${isMobile ? 'w-full' : 'w-80'} h-full py-5 flex-shrink-0`}>
      <FlexCol className="px-8">
        <p className={`font-medium ${isMobile ? 'font-sans text-34' : 'text-lg '}`}>{title}</p>
        <p className="text-xs text-inactive mt-1">{subtitle}</p>
      </FlexCol>
      <FlexCol className="mt-7 h-full px-8 overflow-auto">
        {items.map((item, index) => (
          <FlexRow
            key={index}
            onClick={() => onChangeItem(item)}
            className={`items-center p-5 rounded-xl mb-4 cursor-pointer hover:bg-gray-100 ${
              selectedItem?.uuid === item?.uuid || isMobile ? 'bg-white shadow-custom1' : ''
            }`}>
            <FlexRow className="items-center justify-center w-11 h-11 bg-white border rounded-full flex-shrink-0">
              <Avatar className="w-11 h-11 rounded-full overflow-hidden" imageSrc={item?.creator?.user_profile?.photo} name={item?.creator?.name} />
            </FlexRow>
            <FlexCol className="ml-2 flex-1">
              <div className="text-dark text-sm">{item?.creator?.name}</div>
              <FlexRow className="justify-between">
                <span className="text-xs text-inactive mt-1">{`${item?.creator?.posts?.total?.count} ${
                  item?.creator?.posts?.total?.count <= 1 ? 'post' : 'posts'
                }`}</span>
                <span className="text-xs text-inactive mt-1">{formatDateTimeAgo(item?.created_at).replace(' ago', '')}</span>
              </FlexRow>
            </FlexCol>
          </FlexRow>
        ))}
        {items.length === 0 && (
          <FlexRow className={`items-center p-5 rounded-xl mb-4 cursor-pointer bg-white shadow-custom1 hover:bg-gray-100`}>
            <FlexRow className="items-center justify-center w-11 h-11 bg-bg1 border rounded-full flex-shrink-0">
              <EyeIcon width={15} />
            </FlexRow>
            <FlexCol className="flex-1 ml-2">
              <span className="text-sm text-dark">No posts to review</span>
            </FlexCol>
          </FlexRow>
        )}
      </FlexCol>
    </FlexCol>
  );
};
