const InsertHastagToCategory = `
  mutation InsertHastagToCategory(
    $hashtag_uuid: uuid,
    $category_uuid: uuid,
    $network_uuid: uuid,
  ) {
    insert_network_category_hashtag_one(object: {
      hashtag_uuid: $hashtag_uuid,
      category_uuid: $category_uuid,
      network_uuid: $network_uuid
    }) {
      hashtag_uuid
    }
  }
`;

export default InsertHastagToCategory;
