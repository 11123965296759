import mapDateTime from './mapDateTime';

const mapNetworkUser = ({ network_uuid, user_uuid, role, is_blocked, admin_approved, user, created_at, updated_at }) => {
  return {
    networkUuid: network_uuid,
    userUuid: user_uuid,
    role,
    createdAt: created_at && mapDateTime(created_at),
    updatedAt: updated_at && mapDateTime(updated_at),
    isBlocked: is_blocked,
    adminApproved: admin_approved,
    user: {
      uuid: user.uuid,
      name: user.name,
      email: user.email,
      phone: user.phone,
      referrerUuid: user.referrer_uuid,
      profile: { ...user.user_profile },
    },
  };
};

export default mapNetworkUser;
