import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MobileMemberTable } from '@app/components';
import { currentNetworkSelector } from '@app/store/network/selector';
import { userSelector } from '@app/store/session/selector';
import { useParams } from 'react-router-dom';
import { fetchUsersByNetworkAction } from '@app/store/network/actions';

export const NetworkUsersAllPage = () => {
  const dispatch = useDispatch();
  const { id: networkId } = useParams();
  const { network_users: items = [] } = useSelector(currentNetworkSelector);
  const user = useSelector(userSelector);
  React.useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);
  return <MobileMemberTable items={items} isSuperAdmin={user?.isSuperAdmin} />;
};
