import { createAction } from 'redux-actions';
import { START } from '../common';

export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_NETWORK_USERS = 'FETCH_NETWORK_USERS';
export const UPDATE_USER_SEARCH = 'UPDATE_USER_SEARCH';

export const fetchAdminUsersAction = createAction(FETCH_ADMIN_USERS + START);
export const fetchUsersAction = createAction(FETCH_USERS + START);
export const fetchNetworkUsersAction = createAction(FETCH_NETWORK_USERS + START);
export const updateUserSearchAction = createAction(UPDATE_USER_SEARCH);
