import * as React from 'react';

function SupermanIcon(props) {
  return (
    <svg viewBox="0 0 50 50" width="1em" height="1em" {...props}>
      <path d="M8.54 9l-6.852 7.996 5.64 6.672c.207.273.457.562.79.926L25 44.547l.766-.902 22.547-26.649-6.5-7.582a.761.761 0 00-.157-.18L41.457 9zm.92 2h3.685c-2.536 1.535-5.415 4.14-6.774 8.438l-2.059-2.434zM25 11c7.8-.2 8.898 4.8 9 5.2.102.6.7 1 1.2.8H41c.602 0 1-.398 1-1v-3.297l3.688 4.3-4.07 4.81C39.882 17.8 32.557 17.8 26 17.8c-6.2 0-10-.102-10-2 0 .199.2-4.602 9-4.801zm7.559 0h3.664a45.947 45.947 0 00-1.32 2.402A8.812 8.812 0 0032.558 11zM12.188 26.309C16.12 26.996 22.627 27 32 27c1.2 0 2.102.3 2.5.8.3.5.2 1.102.098 1.602-.399 1.2-1.797 2.797-3.899 3.696-1.8.703-4.199.902-5.601.902h-.196C24.5 31.898 23 29 19 29c-1.934 0-3.168.402-3.89.766zm18.12 8.87L25 41.454l-5.086-6.012c1.035.211 2.34.414 3.887.461h.797c1.113 0 3.53-.078 5.71-.722z" />
    </svg>
  );
}

export default SupermanIcon;
