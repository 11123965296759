import { parsePhoneNumber } from 'react-phone-number-input';

export const formatPhone = (value) => {
  if (!value) {
    return '';
  }
  const formattedPhone = parsePhoneNumber(value, 'US').nationalNumber;
  if (formattedPhone) {
    const newValue = formattedPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || [];
    return !newValue[2] ? newValue[1] : '(' + newValue[1] + ') ' + newValue[2] + (newValue[3] ? '-' + newValue[3] : '');
  } else {
    return value;
  }
};
