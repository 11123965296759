import { applyMiddleware, combineReducers, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

import rootSaga from './rootSaga';
import { sessionReducer } from './session/reducers';
import { createBrowserHistory } from 'history';
import { networkReducer } from './network/reducers';
import { usersReducer } from './users/reducers';
import { logsReducer } from './logs/reducers';
import { mobileReducer } from './mobile/reducers';
export const history = createBrowserHistory();

const sessionPeristConfig = {
  key: 'session',
  storage,
  whitelist: ['showTutorial'],
};

const rootReducer = (history) => {
  return combineReducers({
    session: persistReducer(sessionPeristConfig, sessionReducer),
    network: networkReducer,
    users: usersReducer,
    logs: logsReducer,
    mobile: mobileReducer,
    router: connectRouter(history),
  });
};

const rootReducerInstance = rootReducer(history);

const configureStore = async () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
  };

  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducerInstance);

  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history))));

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
