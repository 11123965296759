import React, { useMemo } from 'react';

import { ActionMenu } from './ActionMenu';
import { Table } from './Table';

import FlagIcon from './FlagIcon';
import StarIcon from './StarIcon';
import { LogFilter } from './LogFilter';
import { useDispatch, useSelector } from 'react-redux';
import { logFilterSelector } from '@app/store/logs/selector';
import { fetchLogsAction, updateLogFilterAction } from '@app/store/logs/actions';

const Actions = ({ item, onResolve = () => {}, onShowStack = () => {} }) => {
  return (
    <div className="flex flex-row justify-end items-center">
      <ActionMenu
        items={[
          !item.resovled && {
            href: '#resolve',
            onClick: () => onResolve({ item }),
            label: 'Mark As Resolved',
            icon: <FlagIcon />,
            size: 'small',
          },
          {
            href: '#stack',
            onClick: () => onShowStack({ item }),
            label: 'Show details',
            icon: <StarIcon />,
            size: 'small',
          },
        ].filter((x) => x)}
        isPopoverOnly={true}
      />
    </div>
  );
};

export const LogTable = ({ items = [], onResolve = () => {}, onShowStack = () => {} }) => {
  const dispatch = useDispatch();
  const heading = `${items.length.toLocaleString()} Issues`;
  const { isMobile } = useSelector(logFilterSelector);
  const onChangePlatform = (platform) => {
    dispatch(fetchLogsAction(platform));
    dispatch(updateLogFilterAction({ key: 'isMobile', value: platform === 'ios' }));
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Index',
        accessor: '',
        Cell: (row) => {
          return <div>{Number(row.row.id) + 1}</div>;
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Type',
        accessor: 'name',
        // Cell: ({ value }) => <div className="rounded bg-gray-300 text-white text-center">{value}</div>,
        style: {
          width: '80px',
        },
      },
      {
        Header: 'Message',
        accessor: 'message',
        Cell: ({ value }) => <div className="pl-2 text-black font-bold">{value}</div>,
        style: {
          width: '600px',
        },
      },
      {
        Header: 'Version',
        accessor: 'build_version',
        Cell: ({ value }) => <div className="rounded bg-blue-400 text-white text-center">{value}</div>,
        style: {
          width: '50px',
        },
      },
      {
        Header: 'Platform',
        accessor: 'device',
        Cell: ({ value }) => <div className="rounded bg-gray-400 text-white text-center">{value}</div>,
        style: {
          width: '50px',
        },
      },
      {
        Header: 'Environment',
        accessor: 'env',
        Cell: ({ value }) => <div className="rounded bg-indigo-300 text-white text-center">{value}</div>,
        style: {
          width: '90px',
        },
      },
      {
        Header: 'User name',
        accessor: 'user.name',
        style: {
          width: '150px',
        },
      },
      {
        Header: 'User email',
        accessor: 'user.email',
        style: {
          width: '200px',
        },
      },
      {
        Header: 'Issued Network',
        accessor: 'network.name',
        style: {
          width: '150px',
        },
      },
      {
        Header: 'Issued Date',
        accessor: 'created_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
        style: {
          width: '200px',
        },
      },
      {
        Header: 'Status',
        accessor: 'resolved',
        Cell: ({ value }) => (
          <div className={`rounded text-white text-center bg-${value ? 'green' : 'red'}-400`}>{value ? 'Fixed' : 'Not fixed'}</div>
        ),
        style: {
          width: '80px',
        },
      },
      // {
      //   Header: 'Phone',
      //   accessor: 'user.phone',
      //   Cell: ({ value }) => formatPhone(value),
      //   style: {
      //     width: '213px',
      //   },
      // },
      // {
      //   Header: 'Location',
      //   accessor: 'user.profile.location',
      //   style: {
      //     width: '170px',
      //   },
      // },
      {
        Header: 'Action',
        id: 'actions',
        accessor: (item) => item,
        Cell: ({ value }) => <Actions item={value} onResolve={() => onResolve(value)} onShowStack={onShowStack} />,
      },
    ],
    [onResolve, onShowStack],
  );

  const rightHeading = (
    <div className="flex flex-row border rounded-lg overflow-hidden shadow-xl">
      <div
        className={`w-24 text-center py-1 cursor-pointer text-sm ${isMobile ? ' bg-primary font-semibold text-white' : ' bg-white'}`}
        onClick={() => onChangePlatform('ios')}>
        Mobile
      </div>
      <div
        className={`w-24 text-center py-1 cursor-pointer text-sm ${!isMobile ? ' bg-primary font-semibold text-white' : ' bg-white'}`}
        onClick={() => onChangePlatform('api')}>
        Backend
      </div>
    </div>
  );

  return (
    <>
      <Table heading={heading} rightHeading={rightHeading} showColumnHeader columns={columns} data={items} filter={<LogFilter />} />
    </>
  );
};
