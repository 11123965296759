import React, { useEffect, useState } from 'react';

import { CloudImage } from '@app/utils';
import FlexCol from './FlexCol';

const renderProfileImage = (url) => CloudImage(url, { width: 50, height: 50 });

export const Avatar = ({
  name = '',
  imageSrc,
  className = '',
  labelClassName = '',
  theme = 'primary', // primary, secondary
  size = 'medium', // small, medium, large
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(false);
  }, [imageSrc]);
  const initials =
    name && name.length > 0
      ? name
          .split(' ')
          .map((word) => word[0])
          .join('')
      : '??';

  const handleLoad = () => {
    setHasLoaded(true);
  };

  return (
    <FlexCol
      className={['avatar items-center justify-center flex-shrink-0', hasLoaded && 'image-loaded', !!imageSrc && 'has-src', theme, size, className]
        .filter((x) => x)
        .join(' ')}>
      {!imageSrc && <p className={`font-medium ${labelClassName}`}>{initials}</p>}

      {imageSrc && <img src={renderProfileImage(imageSrc)} onLoad={handleLoad} />}
    </FlexCol>
  );
};
