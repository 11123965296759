const UpdateNetworkCategoryOrders = `
  mutation UpdateNetworkCategoryOrders($object: [network_category_insert_input!]!) {
    insert_network_category(
      objects: $object,
      on_conflict: {
        constraint: network_category_pkey,
        update_columns: [order]
      }
    ) {
      affected_rows
    }
  }
`;

export default UpdateNetworkCategoryOrders;
