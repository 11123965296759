import React from 'react';

function Select({
  label,
  labelStyle,
  placeholder,
  className = '',
  optionStyle = '',
  options = [],
  value = '',
  isAutofocused = false,
  isRequired = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
}) {
  const uniqueId = `input-${(label || '').replace(/[^\w_]/g, '-')}`;
  const optionEls = options.map(({ label, value }) => (
    <option key={value} value={value}>
      {label || value}
    </option>
  ));

  return (
    <div className={`${className || ''}`} style={{ all: 'unset' }}>
      <label htmlFor={uniqueId} className={labelStyle || ''}>
        {label}
      </label>

      <select
        style={{ all: 'revert' }}
        className={`${optionStyle || ''}`}
        id={uniqueId}
        value={value}
        required={isRequired}
        autoFocus={isAutofocused}
        onChange={({ target }) => onChange(target.value)}
        onFocus={onFocus}
        onBlur={onBlur}>
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {optionEls}
      </select>
    </div>
  );
}

export default Select;
