import produce from 'immer';
import { handleActions } from 'redux-actions';

import mapUser from '@shared/mappings/mapUser';

import { SUCCESS } from '../common';
import { FETCH_ADMIN_USERS, FETCH_NETWORK_USERS, FETCH_USERS, UPDATE_USER_SEARCH } from './actions';
import { LOG_OUT } from '../session/actions';

const initialState = {
  admin: {},
  items: [],
  searchKey: '',
};

export const usersReducer = handleActions(
  {
    [LOG_OUT]: () => initialState,
    [FETCH_ADMIN_USERS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.admin = payload;
      }),
    [FETCH_USERS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = payload.user.map(mapUser);
      }),
    [FETCH_NETWORK_USERS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = payload.user;
      }),
    [UPDATE_USER_SEARCH]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searchKey = payload;
      }),
  },
  initialState,
);
