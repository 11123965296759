import { createAction } from 'redux-actions';
import { START } from '../common';

export const GET_USER = 'GET_USER';
export const SUBMIT_EMAIL = 'SUBMIT_EMAIL';
export const SUBMIT_CODE = 'SUBMIT_CODE';
export const SUBMIT_PHONE = 'SUBMIT_PHONE';
export const SUBMIT_PHONE_CODE = 'SUBMIT_PHONE_CODE';
export const CONNECT_STRIPE = 'CONNECT_STRIPE';
export const GOTO_STRIPE_DASHBOARD = 'GOTO_STRIPE_DASHBOARD';
export const LOG_OUT = 'LOG_OUT';
export const SET_SHOW_TUTORIAL = 'SET_SHOW_TUTORIAL';

export const submitEmail = createAction(SUBMIT_EMAIL + START);
export const submitCode = createAction(SUBMIT_CODE + START);
export const submitPhone = createAction(SUBMIT_PHONE + START);
export const submitPhoneCode = createAction(SUBMIT_PHONE_CODE + START);
export const getUserAction = createAction(GET_USER + START);
export const connectStripeAction = createAction(CONNECT_STRIPE + START);
export const gotoStripeDashboardAction = createAction(GOTO_STRIPE_DASHBOARD + START);
export const logoutAction = createAction(LOG_OUT);
export const setShowTutorial = createAction(SET_SHOW_TUTORIAL);
