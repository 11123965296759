import React, { useEffect, useState } from 'react';

import { BaseModal } from './BaseModal';
import { Input } from '../Input';
import CloseIcon from '../CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { createNetworkAction, initCreateNetworkAction } from '@app/store/network/actions';
import { createNetworkSelector } from '@app/store/network/selector';
import Button from '../Button';
import FlexRow from '../FlexRow';

export const CreateNetworkModal = ({ isOpen, onClose, initialValues = {}, accessRequestId = null }) => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector(createNetworkSelector);
  const [name, setName] = useState(initialValues.name || '');
  const [email, setEmail] = useState(initialValues.email || '');
  const [phone, setPhone] = useState(initialValues.phone || '');
  const [adminName, setAdminName] = useState(initialValues.adminName || '');

  useEffect(() => {
    dispatch(initCreateNetworkAction());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && success) {
      onClose();
    }
  }, [loading, onClose, success]);

  const onChangePhone = (value) => {
    const newValue = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    setPhone(!newValue[2] ? newValue[1] : '(' + newValue[1] + ') ' + newValue[2] + (newValue[3] ? '-' + newValue[3] : ''));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      createNetworkAction({
        name,
        email,
        phone: phone.replace(/\D/g, ''),
        adminName,
        accessRequestId,
      }),
    );
  };
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className="m-auto p-4 px-6 bg-white rounded-xl shadow-xl w-full max-w-xs" overlayClassName="flex">
      <div className="absolute cursor-pointer right-4" onClick={onClose}>
        <CloseIcon />
      </div>
      <form onSubmit={handleSubmit}>
        <p className="text-center w-full my-6 font-medium">Create new network</p>
        <div className="mb-6">
          <Input
            className="border-gray-200 rounded-2xl border-2 text-gray-600 p-2 w-full"
            inputFieldClassName="w-full"
            type="text"
            value={name}
            label="Network name"
            isRequired
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="mb-6">
          <Input
            className="border-gray-200 rounded-2xl border-2 text-gray-600 p-2 w-full"
            inputFieldClassName="w-full"
            type="text"
            value={adminName}
            label="Network admin name"
            isRequired
            onChange={(event) => setAdminName(event.target.value)}
          />
        </div>
        <div className="mb-6">
          <Input
            className="border-gray-200 rounded-2xl border-2 text-gray-600 p-2 w-full"
            inputFieldClassName="w-full"
            type="email"
            value={email}
            label="Network admin email"
            isRequired
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="mb-6">
          <Input
            className="border-gray-200 rounded-2xl border-2 text-gray-600 p-2 w-full"
            inputFieldClassName="w-full"
            isRequired={false}
            type="text"
            value={phone}
            label="Network admin phone"
            onChange={(event) => onChangePhone(event.target.value)}
          />
        </div>

        <FlexRow className="row mb-3 justify-center">
          <Button
            className="bg-primary-green shadow-custom1 py-2 px-3"
            labelClassName="text-white text-sm"
            label="Create Network"
            type="submit"
            isFetching={loading}
          />
        </FlexRow>
      </form>
    </BaseModal>
  );
};
