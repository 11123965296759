import * as React from 'react';

function MessageIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M21.546 2.437a1.819 1.819 0 00-1.101-.457c-.272-.02-.517.053-.763.147-.397.15-.792.303-1.19.454l-2.228.849c-.957.364-1.915.729-2.87 1.095-1.036.393-2.07.789-3.106 1.184L7.332 6.835l-2.414.92c-.494.19-.988.376-1.482.565l-.266.1-.047.018c-.145.055-.291.107-.418.198a1.96 1.96 0 00-.515.518c-.184.26-.27.605-.277.919-.005.306.086.666.258.922.196.29.437.515.755.66.063.031.126.059.19.09.292.137.587.273.88.411.943.437 1.886.878 2.829 1.315.811.378 1.621.754 2.431 1.133.05.024.102.047.153.07l.28.602.897 1.928 1.011 2.173.72 1.546.122.265c.349.75 1.277 1.177 2.069.906.277-.096.502-.224.714-.43a.825.825 0 00.1-.114 1.83 1.83 0 00.29-.515 8.395 8.395 0 00.062-.162l.034-.09.17-.445c.226-.588.45-1.178.674-1.766.33-.865.66-1.728.988-2.595l1.157-3.032c.39-1.026.78-2.05 1.173-3.078.348-.912.696-1.824 1.041-2.736.253-.66.506-1.325.758-1.986l.321-.839c.123-.32.135-.646.072-.982a1.653 1.653 0 00-.516-.887zM20.08 4.196c-.15.389-.297.779-.446 1.168l-.68 1.788-.842 2.21c-.31.811-.62 1.623-.927 2.433l-.938 2.46-.873 2.29c-.245.64-.488 1.28-.73 1.919-.172.45-.346.902-.516 1.354-.045.123-.092.245-.139.366-.003-.006-.007-.013-.01-.023l-.214-.456c-.247-.533-.494-1.065-.743-1.596l-1.735-3.73-.09-.198 4.308-4.31c.339-.338.366-.961 0-1.295-.368-.335-.933-.363-1.296 0L9.9 12.885c-1.156-.538-2.311-1.077-3.47-1.613-.843-.394-1.689-.786-2.533-1.18l.144-.053 1.21-.462 1.811-.69 2.23-.849c.81-.308 1.62-.619 2.43-.926.816-.311 1.63-.62 2.445-.933l2.278-.869 1.895-.722c.444-.169.886-.339 1.328-.506.18-.07.362-.137.541-.207-.042.11-.09.22-.13.32z" />
    </svg>
  );
}

export default MessageIcon;
