import React, { useEffect, useState } from 'react';

import { AnalyticsCard, AnalyticsFilter } from '@app/components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNetworkAnalyticsAction, fetchUsersByNetworkAction } from '@app/store/network/actions';

import { analyticsSelector, currentNetworkSelector } from '@app/store/network/selector';
import FlexRow from '@app/components/FlexRow';
import PageNavContainer from '@app/components/PageNavContainer';
import OverviewIcon from '@app/assets/images/overview.svg';
import FlexCol from '@app/components/FlexCol';

const MenuItems = [{ tab: 'overview', title: 'Overview', subtitle: 'General organization health.', icon: OverviewIcon }];

const getMonday = (d) => {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

const dateFilter = [
  { label: 'All time', date: null },
  { label: 'Day', date: new Date().setHours(0, 0, 0, 0) },
  { label: 'Week', date: getMonday(new Date()).setHours(0, 0, 0, 0) },
  { label: 'Month', date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0) },
];

export const NetworkAnalyticsPage = () => {
  const dispatch = useDispatch();
  const { id: networkId } = useParams();
  const { uuid: network_uuid } = useSelector(currentNetworkSelector);
  const [filter, setFilter] = useState(dateFilter[0]);
  const { data = [] } = useSelector(analyticsSelector);
  const [selectedTab, setSelectedTab] = useState('overview');
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  useEffect(() => {
    if (network_uuid) {
      dispatch(fetchNetworkAnalyticsAction(new Date(filter.date)));
    }
  }, [dispatch, network_uuid, filter]);

  return (
    <FlexRow className="h-full flex-1">
      <PageNavContainer
        title="Metrics and analytics"
        subtitle="Learn how members user your space."
        items={MenuItems}
        selectedTab={selectedTab}
        onChangeTab={(value) => setSelectedTab(value)}
      />
      <div className="p-7 h-content bg-white w-full rounded-xl mt-8 mr-8 shadow-custom1">
        <FlexRow className="w-full">
          <FlexCol className="flex-1">
            <div className="font-medium text-dark text-15">Invitations via email or text</div>
            <div className="text-xs text-inactive tracking-tight mt-1">
              At least one field must be filled out for your personal invite to be sent.
            </div>
          </FlexCol>
          <AnalyticsFilter options={dateFilter} filter={filter} onChange={(item) => setFilter(item)} />
        </FlexRow>
        {/* <div className="font-sans text-5xl mt-20 mb-8">{network_users.length.toLocaleString()} members</div> */}
        <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-4 md:gap-y-4 lg:grid-cols-3 lg:gap-4 2xl:grid-cols-4 2xl:gap-10">
          {data.map(({ label, count, unit }, index) => (
            <AnalyticsCard key={index} title={label} value={count.toLocaleString() + unit} />
          ))}
        </div>
      </div>
    </FlexRow>
  );
};
