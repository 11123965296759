import { fetchLogsAction, updateLogFilterAction } from '@app/store/logs/actions';
import { logFilterSelector, logSelector } from '@app/store/logs/selector';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from './Input';
import Select from './Select';

export const LogFilter = () => {
  const dispatch = useDispatch();
  const { builds, networks, resolvedStatus } = useSelector(logSelector);
  const { build, network, search, resolved } = useSelector(logFilterSelector);
  useEffect(() => {
    dispatch(fetchLogsAction());
  }, [resolved, dispatch]);
  return (
    <div className="py-2 w-full flex flex-row items-center">
      <div>
        <Input
          label="Search By:"
          className="flex flex-row items-center"
          labelStyle="w-auto px-0 m-0"
          value={search}
          onChange={({ target }) => dispatch(updateLogFilterAction({ key: 'search', value: target.value }))}
          inputFieldClassName="ml-4 border border-solid border-gray-500 py-1 px-2 w-48 rounded"
          placeholder="message, user, email..."
        />
      </div>
      <div className="ml-4">
        <Select
          label="Version:"
          labelStyle="m-0"
          value={build}
          options={builds.map((value) => ({ label: value, value }))}
          className="flex flex-row justify-center items-center"
          optionStyle="ml-2 rounded bg-gray-200 px-1 outline-none text-base"
          onChange={(value) => dispatch(updateLogFilterAction({ key: 'build', value }))}
        />
      </div>
      <div className="ml-4">
        <Select
          label="Network:"
          labelStyle="m-0"
          value={network}
          options={networks.map((value) => ({ label: value, value }))}
          className="flex flex-row justify-center items-center"
          optionStyle="ml-2 rounded bg-gray-200 px-1 outline-none text-base"
          onChange={(value) => dispatch(updateLogFilterAction({ key: 'network', value }))}
        />
      </div>
      <div className="ml-4">
        <Select
          label="Status:"
          labelStyle="m-0"
          value={resolved}
          options={resolvedStatus.map(({ label, value }) => ({ label, value }))}
          className="flex flex-row justify-center items-center"
          optionStyle="ml-2 rounded bg-gray-200 px-1 outline-none text-base"
          onChange={(value) => dispatch(updateLogFilterAction({ key: 'resolved', value }))}
        />
      </div>
    </div>
  );
};
