import mapDateTime from './mapDateTime';

const mapNetworkMembershipRequest = ({ uuid, id, name, body, created_at, updated_at, user }) => ({
  uuid,
  id,
  name,
  body,
  createdAt: mapDateTime(created_at),
  updatedAt: mapDateTime(updated_at),
  user: {
    uuid: user.uuid,
    name: user.name,
    email: user.email,
    phone: user.phone,
    profile: { ...user.user_profile },
  },
});

export default mapNetworkMembershipRequest;
