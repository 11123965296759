import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import Button from './Button';
import FlexRow from './FlexRow';

import { Input } from './Input';

function LogInForm({ isFetching = false, onSubmit = () => {} }) {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isEmailAuth, setIsEmailAuth] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ value: isEmailAuth ? email.trim() : phone.trim(), type: isEmailAuth ? 'email' : 'phone' });
  };

  return (
    <form className="w-full max-w-xs px-2 flex flex-col items-center" onSubmit={handleSubmit}>
      <p className="text-center text-3xl mb-4 font-sans">
        Login with your email
        <br />
        or mobile number.
      </p>
      <div className="mb-10 mt-4 w-full">
        <FlexRow className="w-full mb-2 shadow-custom1 bg-bg1 rounded-xl overflow-hidden">
          <Button
            label="Email"
            type="button"
            onClick={() => setIsEmailAuth(true)}
            className={`flex-full py-3 text-label text-sm ${isEmailAuth ? 'bg-white' : ''}`}
          />
          <Button
            label="Mobile"
            type="button"
            onClick={() => setIsEmailAuth(false)}
            className={`flex-full py-3 text-label text-sm ${!isEmailAuth ? 'bg-white' : ''}`}
          />
        </FlexRow>
        {isEmailAuth && (
          <Input
            type="email"
            className="rounded-xl z-10 bg-white shadow-custom1 mt-3"
            inputFieldClassName="rounded-xl px-5 py-3 w-full text-base"
            placeholder="Email address"
            isRequired
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        )}
        {!isEmailAuth && (
          <PhoneInput
            className="bg-white w-full shadow-custom1 rounded-xl px-4 py-3 mt-3"
            placeholder="Phone"
            defaultCountry="US"
            value={phone}
            onChange={(value) => setPhone(value)}
          />
        )}
      </div>
      <Button label="Next" className="w-1/2 py-2 rounded-xl bg-primary text-white" type="submit" isFetching={isFetching} />
      <p className="text-center text-gray-500 text-xs px-2 mt-4">
        By entering your information, you’re agreeing to our Terms of Service and Privacy Policy.
      </p>
    </form>
  );
}

export default LogInForm;
