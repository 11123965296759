import React, { useEffect, useState } from 'react';

import { InvitationsRow } from './InvitationsRow';
import { Button } from '.';

import ContactIcon from '@app/assets/images/users.svg';
import FlexRow from './FlexRow';
import FlexCol from './FlexCol';

const emptyRow = { name: '', email: '', phone: '' };

export const IndividualInviteSection = ({ onSendInvitation = () => {}, loading, success }) => {
  const [data, setData] = useState([emptyRow]);

  useEffect(() => {
    if (!loading && success) {
      setData([emptyRow]);
    }
  }, [loading, success]);

  const handleChange = (index, key, value) => {
    const newItems = [...data];
    newItems[index] = { ...data[index], [key]: value };
    setData(newItems);
  };

  const onDelete = (index) => {
    const items = data
      .filter((item, sIndex) => sIndex !== index)
      .map((item) => ({ name: item.name.trim(), email: item.email.trim(), phone: item.phone.trim() }));
    setData(items.map((item) => ({ ...item, phone: item.phone.indexOf('+1') === 0 ? item.phone.substring(2) : item.phone })));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log('SUBMIT data', data);
    const removedCountry = data.map((item) => ({ ...item, phone: item.phone.indexOf('+1') === 0 ? item.phone.substring(2) : item.phone }));
    // console.log('removedCountry', removedCountry);
    onSendInvitation(removedCountry);
  };

  return (
    <FlexCol className="w-full">
      <form
        className="flex-full flex flex-col items-center overflow-hidden overflow-y-scroll p-7 h-content bg-white rounded-xl mt-8 mr-8 shadow-custom1"
        onSubmit={onSubmit}>
        <FlexRow className="items-center justify-between w-full">
          <FlexCol>
            <div className="font-medium text-dark text-15">Invitations via email or text</div>
            <div className="text-xs text-inactive tracking-tight text-center mt-1">
              At least one field must be filled out for your personal invite to be sent.
            </div>
          </FlexCol>
          <FlexRow className="text-center flex justify-center items-center flex-shrink-0">
            <Button
              type="submit"
              label="Send invitations"
              className="w-32 bg-primary-green shadow-custom1 py-2"
              labelClassName="text-white text-xs"
            />
          </FlexRow>
        </FlexRow>
        <div className="w-full max-w-4xl flex flex-full flex-col items-left py-2 overflow-hidden overflow-y-scroll">
          <FlexCol className="w-full items-left mt-8">
            <FlexRow className="w-full text-xs text-label items-left">
              <span className="w-52">Member name</span>
              <div className="flex-0.5" />
              <span className="w-52">
                <span className="">*</span>Email address
              </span>
              <div className="flex-1" />
              <span className="w-52">
                <span className="">*</span>Mobile Phone
              </span>
              <div className="w-8" />
            </FlexRow>
            {data.map((item, index) => (
              <InvitationsRow key={index} item={item} onDelete={() => onDelete(index)} onChange={(key, value) => handleChange(index, key, value)} />
            ))}
          </FlexCol>
          <FlexRow className={`w-full ${data.length ? 'mt-2 justify-end ' : 'justify-center mt-4'}`}>
            <Button
              // theme={data.length ? 'secondary' : 'primary'}
              label={data.length ? 'Add more' : 'Add User'}
              labelClassName="text-xs text-inactive px-4"
              className="bg-transparent"
              onClick={(e) => {
                e.preventDefault();
                setData([...data, emptyRow]);
              }}
            />
          </FlexRow>
        </div>
      </form>
      <FlexRow className="text-center mt-5 text-label px-8 text-xs font-medium flex items-center ">
        <img src={ContactIcon} className="w-3.5 h-3.5 mr-2" />
        <span>Import your contact list and quickly add members</span>
      </FlexRow>
    </FlexCol>
  );
};
