import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUsersByNetworkAction } from '@app/store/network/actions';

import FlexRow from '@app/components/FlexRow';
import PageNavContainer from '@app/components/PageNavContainer';
import OverviewIcon from '@app/assets/images/overview.svg';

const MenuItems = [{ tab: 'overview', title: 'Overview', subtitle: 'General organization health.', mobileRoute: 'details', icon: OverviewIcon }];

export const NetworkAnalyticsPage = () => {
  const dispatch = useDispatch();
  const { id: networkId } = useParams();
  const [selectedTab, setSelectedTab] = useState('overview');
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  return (
    <FlexRow className="h-full flex-1">
      <PageNavContainer
        title="Analytics"
        subtitle="Learn how members user your space."
        items={MenuItems}
        selectedTab={selectedTab}
        onChangeTab={(value) => setSelectedTab(value)}
      />
    </FlexRow>
  );
};
