const UpdateNetworkArchive = `
  mutation UpdateNetworkArchive($network_uuid: uuid!, $archived_at: timestamptz) {
    update_network_by_pk(pk_columns: {uuid: $network_uuid}, _set: {archived_at: $archived_at}) {
      __typename
      uuid
    }
  }
`;

export default UpdateNetworkArchive;
