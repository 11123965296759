import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchUsersByNetworkAction } from '@app/store/network/actions';
import { DashboardContainer } from '@app/components';

export const NetworkKnowledgePage = () => {
  const dispatch = useDispatch();
  const { id: networkId } = useParams();
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);
  return (
    <DashboardContainer className="px-5 py-4">
      <div className="flex flex-1 flex-col items-center justify-center h-full">Knowledge</div>
    </DashboardContainer>
  );
};
