const sessionKey = 'declaration-dashboard-session';

let cachedToken = null;

export const setAuthToken = (token) => {
  cachedToken = token;
  localStorage.setItem(sessionKey, token);
};

export const getAuthToken = async () => {
  const token = (await localStorage.getItem(sessionKey)) || '';
  cachedToken = token;
  return token;
};

export const removeAuthToken = () => {
  localStorage.removeItem(sessionKey);
};

export const getSavedToken = () => {
  return cachedToken;
};
