import produce from 'immer';
import { handleActions } from 'redux-actions';

import { FAIL, START, SUCCESS } from '../common';
import { AUTHORIZE_NETOWRK_USER, SELECT_PLAN, SUBSCRIBE_NETWORK_PLAN } from './actions';
import { LOG_OUT } from '../session/actions';

const initialState = {
  loading: false,
  success: false,
  error: false,
  data: {},
  selectedPlan: {},
};

export const mobileReducer = handleActions(
  {
    [LOG_OUT]: () => initialState,
    [AUTHORIZE_NETOWRK_USER + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [AUTHORIZE_NETOWRK_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.data = { ...state.data, ...payload };
      }),
    [AUTHORIZE_NETOWRK_USER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [SUBSCRIBE_NETWORK_PLAN + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [SUBSCRIBE_NETWORK_PLAN + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
      }),
    [SUBSCRIBE_NETWORK_PLAN + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [SELECT_PLAN]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selectedPlan = payload;
      }),
  },
  initialState,
);
