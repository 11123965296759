import { createSelector } from 'reselect';
import { activeNetworkSelector } from '../network/selector';

const getSessionState = (state) => state.session;

export const sessionSelector = createSelector([getSessionState], (session) => session);

export const isLoggedInSelector = createSelector([getSessionState], (session) => session.loggedIn);

export const authorizingSelector = createSelector([getSessionState], (session) => session.authorizing);

export const userSelector = createSelector([getSessionState], ({ user }) => user);

export const isSuperAdminSelector = createSelector([userSelector], ({ isSuperAdmin }) => isSuperAdmin);

export const userStripeConnectSelector = createSelector([getSessionState], ({ stripe }) => stripe);

export const userMerchantInfoSelector = createSelector([userSelector, activeNetworkSelector], ({ stripe_merchants }, { id }) =>
  stripe_merchants.find(({ network_id }) => network_id === id),
);

export const showTutorialSelector = createSelector([getSessionState], (session) => session.showTutorial);

export const networkRoleSelector = createSelector([userSelector, activeNetworkSelector], ({ uuid }, { network_users = [] }) => {
  return network_users.find(({ user_uuid }) => user_uuid === uuid)?.role;
});
