import React from 'react';
import { useSelector } from 'react-redux';

import DownArrow from '@app/assets/images/down-arrow.svg';
import MobileDownArrow from '@app/assets/images/down-arrow-white.svg';
import SupermanIcon from './SupermanIcon';
import CloseIcon from './CloseIcon';

import { networkSelector } from '@app/store/network/selector';
import { Avatar } from './Avatar';
import { isMobile } from 'react-device-detect';
import FlexRow from './FlexRow';

export const NetworkSwitcher = ({ onClick = () => {}, onClose = () => {} }) => {
  const { selected: selectedNetwork } = useSelector(networkSelector);

  return (
    <FlexRow className="items-center w-full cursor-pointer">
      <FlexRow className="flex-1 items-center" onClick={onClick}>
        {!selectedNetwork.id && (
          <SupermanIcon
            width={30}
            height={30}
            className={isMobile ? 'border border-white w-12 h-12 rounded-md' : ''}
            fill={isMobile ? 'white' : 'black'}
          />
        )}
        {selectedNetwork.id && (
          <Avatar
            name={selectedNetwork.name}
            className={`overflow-hidden rounded-md border ${isMobile ? 'border-white w-12 h-12 text-white' : 'w-8 h-8'}`}
            imageSrc={selectedNetwork.avatar}
          />
        )}
        <img
          src={isMobile ? MobileDownArrow : DownArrow}
          className={`flex-shrink-0 xl:block ${isMobile ? 'block' : 'hidden'}`}
          style={{ width: isMobile ? 32 : 21, height: isMobile ? 32 : 21 }}
        />
      </FlexRow>
      <CloseIcon fill="white" width={24} height={24} onClick={onClose} />
    </FlexRow>
  );
};
