const UpdateNetwork = `
  mutation UpdateNetwork($uuid: uuid!, $avatar: String, $name: String, $post_approval: Boolean = false) {
    update_network_by_pk(pk_columns: {uuid: $uuid}, _set: {avatar: $avatar, name: $name}) {
      __typename
      uuid
    }
    insert_network_setting_one(
      object: {
        network_uuid: $uuid,
        post_approval: $post_approval
      },
      on_conflict: {
        constraint: network_setting_pkey,
        update_columns: [post_approval],
      }
    ) {
      __typename
    }
  }
`;

export default UpdateNetwork;
