import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MemberTable, NetworkMembershipRequestTable, NetworkMembershipInvitationTable } from '@app/components';
import { DoubleConfirmModal } from '@app/components/modals';

import { requestStatusSelector, currentNetworkSelector } from '@app/store/network/selector';
import { userSelector } from '@app/store/session/selector';
import {
  accepAllMemberRequestsAction,
  acceptMemberRequestAction,
  declineMemberInviteAction,
  declineMemberRequestAction,
  fetchUsersByNetworkAction,
} from '@app/store/network/actions';
import PageNavContainer from '@app/components/PageNavContainer';
import FlexRow from '@app/components/FlexRow';
import FlexCol from '@app/components/FlexCol';

import HandIcon from '@app/assets/images/hand.svg';
import SendIcon from '@app/assets/images/send.svg';
import UsersIcon from '@app/assets/images/users.svg';
import { isMobile } from 'react-device-detect';

const MenuItems = [
  { tab: 'request', title: 'Requests to join', subtitle: 'New people who want to join.', mobileRoute: 'requests', icon: HandIcon },
  { tab: 'invite', title: 'Invites sent', subtitle: 'Invites sent to non members.', mobileRoute: 'pendings', icon: SendIcon },
  { tab: 'all', title: 'Members', subtitle: 'All active members', mobileRoute: 'all', icon: UsersIcon },
];

export const NetworkUsersPage = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('all');
  const [isInsertAllModalActive, setIsInsertAllModalActive] = useState(false);
  const [isInsertModalActive, setIsInsertModalActive] = useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [isRevokeModalActive, setIsRevokeModalActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { id: networkId } = useParams();
  const { loading, success } = useSelector(requestStatusSelector);
  const user = useSelector(userSelector);
  const { network_users: items = [], network_membership_requests = [], network_membership_invitations = [] } = useSelector(currentNetworkSelector);

  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);
  useEffect(() => {
    if (!loading && success) {
      setIsInsertAllModalActive(false);
      setIsInsertModalActive(false);
      setIsDeleteModalActive(false);
      setIsRevokeModalActive(false);
    }
  }, [loading, success]);

  return (
    <FlexRow className="flex-1">
      <PageNavContainer
        title="Members"
        subtitle="Manage, accept invites, and assign roles."
        items={MenuItems}
        selectedTab={selectedTab}
        onChangeTab={(value) => setSelectedTab(value)}
      />
      {!isMobile && (
        <FlexCol className="flex-1 h-full">
          {selectedTab === 'request' && (
            <div className="p-7 h-content bg-white rounded-xl mt-8 mr-8 shadow-custom1">
              <NetworkMembershipRequestTable
                items={network_membership_requests}
                onAccept={({ item }) => {
                  setSelectedItem(item);
                  setIsInsertModalActive(true);
                }}
                onDecline={({ item }) => {
                  setSelectedItem(item);
                  setIsDeleteModalActive(true);
                }}
              />
            </div>
          )}
          {selectedTab === 'invite' && (
            <div className="p-7 h-content bg-white rounded-xl mt-8 mr-8 shadow-custom1">
              <NetworkMembershipInvitationTable
                items={network_membership_invitations}
                onDecline={({ item }) => {
                  setSelectedItem(item);
                  setIsRevokeModalActive(true);
                }}
              />
            </div>
          )}
          {selectedTab === 'all' && <MemberTable items={items} isSuperAdmin={user?.isSuperAdmin} />}
          <DoubleConfirmModal
            isOpen={isInsertAllModalActive}
            heading="Accept all member requests"
            description={`Are you sure you want to accept all membership requests?`}
            submitLabel="Yes, accept all"
            isFetching={loading}
            onSubmit={() => dispatch(accepAllMemberRequestsAction())}
            onCancel={() => setIsInsertAllModalActive(false)}
            onClose={() => setIsInsertAllModalActive(false)}
          />
          {selectedItem && isInsertModalActive && (
            <DoubleConfirmModal
              isOpen={isInsertModalActive}
              heading="Accept member request"
              description={`Are you sure you want to accept this membership request from\n ${selectedItem.user.name || selectedItem.user.email}?`}
              submitLabel="Yes, accept"
              isFetching={loading}
              onSubmit={() => dispatch(acceptMemberRequestAction(selectedItem))}
              onCancel={() => setIsInsertModalActive(false)}
              onClose={() => setIsInsertModalActive(false)}
            />
          )}
          {selectedItem && isDeleteModalActive && (
            <DoubleConfirmModal
              isOpen={isDeleteModalActive}
              heading="Decline member request"
              description={`Are you sure you want to decline the request from\n ${
                selectedItem.user.name || selectedItem.user.email
              } to join your private network?`}
              submitLabel="Yes, decline"
              isFetching={loading}
              onSubmit={() => dispatch(declineMemberRequestAction(selectedItem))}
              onCancel={() => setIsDeleteModalActive(false)}
              onClose={() => setIsDeleteModalActive(false)}
            />
          )}
          {selectedItem && isRevokeModalActive && (
            <DoubleConfirmModal
              isOpen={isRevokeModalActive}
              heading="Decline member invitation"
              description={`Are you sure you want to decline this membership invitation for\n ${selectedItem.user_name || selectedItem.user_email}?`}
              submitLabel="Yes, decline"
              isFetching={loading}
              onSubmit={() => dispatch(declineMemberInviteAction(selectedItem))}
              onCancel={() => setIsRevokeModalActive(false)}
              onClose={() => setIsRevokeModalActive(false)}
            />
          )}
        </FlexCol>
      )}
    </FlexRow>
  );
};
