const GetLogs = `
  query GetLogs($resolved: Boolean = false, $platform: String = "ios") {
    exception (order_by: { created_at: desc }, where: { resolved: { _eq: $resolved }, device: { _eq: $platform }}) {
      uuid
      message
      name
      device
      build_version
      env
      user {
        uuid
        name
        email
      }
      network {
        uuid
        name
      }
      stack
      resolved
      created_at
      updated_at
    }
  }
`;

export default GetLogs;
