import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation, matchPath, useHistory } from 'react-router-dom';
// import { TourProvider } from '@reactour/tour';
import { routes as originRoutes } from './routes';
import { isMobile } from 'react-device-detect';

import { MobileSideNavigation } from '@app/components';

import { isLoggedInSelector, isSuperAdminSelector, networkRoleSelector, showTutorialSelector } from '@app/store/session/selector';
import { WelcomeModal } from '@app/components/modals';
// import steps from '../tour/steps';
import { NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE } from '@app/../shared/constants';
import FlexCol from '@app/components/FlexCol';
import FlexRow from '@app/components/FlexRow';
import MobileDownArrow from '@app/assets/images/down-arrow.svg';
import MenuIcon from '@app/components/MenuIcon';

// const maskRadius = 10;
export default function MobileDashboard() {
  const { pathname } = useLocation();
  const history = useHistory();
  const loggedIn = useSelector(isLoggedInSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const role = useSelector(networkRoleSelector);
  const showTutorial = useSelector(showTutorialSelector);
  const [openWelcome, setOpenWelcome] = useState(!isSuperAdmin && showTutorial);
  const routes = originRoutes.filter((route) => isSuperAdmin || route.permission?.includes(role));
  const [showNavMenu, setShowNavMenu] = useState(false);
  const activePath = routes.find((route) => matchPath(pathname, route));

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  // <TourProvider
  // steps={steps}
  // padding={{ mask: [10, 2], popover: 4, wrapper: 0 }}
  // position="right"
  // showBadge={false}
  // showNavigation={false}
  // showCloseButton={false}
  // disableInteraction={true}
  // maskClassName="opacity-80"
  // styles={{
  //   popover: (base) => ({
  //     ...base,
  //     borderRadius: maskRadius,
  //   }),
  //   maskArea: (base) => ({ ...base, rx: maskRadius }),
  // }}
  // onClickMask={() => {}}
  // className="bg-transparent shadow-none max-w-none h-4 flex -top-2">

  // {role === MEMBER_ROLE && <Redirect from="/networks/:id/members" to="/networks/:id/billing" />}
  return (
    <FlexRow className="flex-1 h-full" id="dashbaord">
      <FlexCol className="flex-1 w-full h-full overflow-y-auto bg-main overflow-x-auto">
        {isMobile && !showNavMenu && (
          <FlexRow className="p-7">
            {activePath?.baseRouter && <MenuIcon width={22} height={22} onClick={() => setShowNavMenu(true)} />}
            {!activePath?.baseRouter && (
              <div onClick={() => history.goBack()}>
                <img src={MobileDownArrow} className="rotate-90 transform cursor-pointer w-8 h-8" />
              </div>
            )}
          </FlexRow>
        )}
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
          {isSuperAdmin && <Redirect to="/networks" />}
          {role === NETWORK_ADMIN_ROLE && <Redirect from="/networks/:id" to="/networks/:id/members" />}
          {role === NETWORK_MANAGER_ROLE && <Redirect from="/networks/:id" to="/networks/:id/pending-posts" />}
          {role === NETWORK_MANAGER_ROLE && <Redirect from="/networks/:id/members" to="/networks/:id/pending-posts" />}
        </Switch>
      </FlexCol>
      {showNavMenu && (
        <FlexCol className="absolute inset-0 w-full h-full border border-black">
          <MobileSideNavigation onClose={() => setShowNavMenu(false)} />
        </FlexCol>
      )}
      <WelcomeModal isOpen={openWelcome} onClose={() => setOpenWelcome(false)} />
    </FlexRow>
  );
}
