import { createSelector } from 'reselect';
import _ from 'lodash';

const getNetworkState = (state) => state.network;

export const networkSelector = createSelector([getNetworkState], (network) => ({ ...network, items: _.orderBy(network.items, 'name', 'asc') }));
export const networkSearchSelector = createSelector([getNetworkState], ({ searchKey = '' }) => searchKey);
export const networkArchivedVisibleSelector = createSelector([getNetworkState], ({ hideArchived }) => hideArchived);
export const activeNetworkSelector = createSelector([getNetworkState], ({ selected }) => selected || {});
export const allNetworkSelector = createSelector([getNetworkState], ({ allNetworks = [], searchKey = '', hideArchived }) => {
  const networks = _.groupBy(
    allNetworks
      .filter(({ name }) => name?.toLowerCase().includes(searchKey.toLowerCase()))
      .filter(({ archived_at }) => (hideArchived ? !archived_at : true)),
    (item) => (!item?.archived_at ? 'active' : 'archived'),
  );
  return [..._.orderBy(networks.active, 'name'), ..._.orderBy(networks.archived, 'name')];
});
export const networkRequestsSelector = createSelector([getNetworkState], ({ networkRequests }) => networkRequests || []);
export const createNetworkSelector = createSelector([getNetworkState], ({ createNetwork }) => createNetwork);
export const deleteNetworkSelector = createSelector([getNetworkState], ({ deleteNetwork }) => deleteNetwork);
export const currentNetworkSelector = createSelector([getNetworkState], ({ currentNetwork }) => currentNetwork);
export const networkSettingSelector = createSelector([getNetworkState], ({ networkSetting }) => networkSetting);
export const inviteMemberSelector = createSelector([getNetworkState], ({ inviteMember }) => inviteMember);
export const requestStatusSelector = createSelector([getNetworkState], ({ requestStatus }) => requestStatus);
export const userActionStatusSelector = createSelector([getNetworkState], ({ actionStatus }) => actionStatus);
export const analyticsSelector = createSelector([getNetworkState], ({ networkAnalytics }) => networkAnalytics);
export const pendingPostsSelector = createSelector([getNetworkState], ({ pendings }) => pendings);
export const declinePostSelector = createSelector([getNetworkState], ({ declinePost }) => declinePost);
export const categorySelector = createSelector([getNetworkState], ({ category }) => category);
export const categoryActionSelector = createSelector([categorySelector], ({ categoryAction }) => categoryAction);
