import React from 'react';

import { Avatar } from './Avatar';

export const UserCell = ({
  value,
  theme = 'primary', // primary, secondary
  showEmail = false,
  footer,
  nameClass = '',
}) => {
  return (
    <div className="flex flex-row items-center">
      <span className="flex-0-auto">
        <Avatar
          name={value.name}
          className="w-7 h-7 border rounded-full overflow-hidden bg-primary"
          labelClassName="text-xs text-white"
          imageSrc={value.image}
          theme={theme}
        />
      </span>

      <div className="ml-2">
        <p className={'name ' + nameClass}>{value.name || 'Unknown Name'}</p>

        {showEmail && value.email && <p className="email">{value.email}</p>}

        {footer}
      </div>
    </div>
  );
};
