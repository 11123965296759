import React, { useEffect, useState } from 'react';

import { BaseModal } from './BaseModal';
import { Input } from '../Input';
import CloseIcon from '../CloseIcon';
import { useDispatch, useSelector } from 'react-redux';

import { categoryActionSelector } from '@app/store/network/selector';
import { showErrorToast } from '@app/utils';
import { addCategoryAction } from '@app/store/network/actions';

export const AddCategoryModal = ({ isOpen, onClose, categories }) => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector(categoryActionSelector);
  const [value, setValue] = useState('');

  useEffect(() => {
    // dispatch(initCreateNetworkAction());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && success) {
      onClose();
    }
  }, [loading, onClose, success]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value.length < 2 || value.length > 20) {
      showErrorToast('Section name length must be between 3 and 20');
      return;
    }
    if (categories.map((category) => category.toLowerCase()).includes(value)) {
      showErrorToast('Section already exists.');
      return;
    }
    dispatch(addCategoryAction({ category: value, order: categories.length }));
  };
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => !loading && onClose()}
      className="m-auto p-4 px-6 bg-white rounded-xl shadow-xl w-full max-w-xs"
      overlayClassName="flex">
      <div className="absolute cursor-pointer right-4" onClick={() => !loading && onClose()}>
        <CloseIcon />
      </div>
      <form onSubmit={handleSubmit}>
        <p className="text-center w-full my-6 font-medium">Create new category</p>
        <div className="mb-6">
          <Input type="text" value={value} label="Section Name" isRequired onChange={(event) => setValue(event.target.value)} />
        </div>
        <div className="button-row">
          <Input label="Create category" type="submit" isFetching={loading} />
        </div>
      </form>
    </BaseModal>
  );
};
