import React from 'react';
import FlexCol from './FlexCol';
import { LoadingView } from './LoadingVIew';

export const Section = ({ title, description, style, children, loading }) => {
  return (
    <div className="relative overflow-hidden" style={style && style}>
      <h2 className="text-15 font-medium mb-1">{title}</h2>
      {description && (
        <div className="text-inactive text-xs">
          {description.split('\n').map((i, idx) => (
            <p key={idx}>{i}</p>
          ))}
        </div>
      )}
      <FlexCol className="mt-5">{children}</FlexCol>
      {loading && (
        <div className="absolute top-0 right-0 h-10 w-10">
          <LoadingView />
        </div>
      )}
    </div>
  );
};
