import { axiosInstance, getError, showErrorToast, urqlClient } from '@app/utils';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { FAIL, START, SUCCESS } from '../common';
import { CONNECT_STRIPE, GET_USER, GOTO_STRIPE_DASHBOARD, LOG_OUT, SUBMIT_CODE, SUBMIT_EMAIL, SUBMIT_PHONE, SUBMIT_PHONE_CODE } from './actions';

import { Env, removeAuthToken, setAuthToken } from '@app/config';
import GetUserAsAdmin from '@app/queries/GetUserAsAdmin';
import mapUser from '@shared/mappings/mapUser';
import mapNetwork from '@shared/mappings/mapNetwork';
import { FETCH_NETWORK_BY_ID, SET_ACTIVE_NETWORK } from '../network/actions';
import { sessionSelector, userSelector } from './selector';

const { BASE_URL } = Env;
const redirectToBase = (url) => (window.location.href = url);

function* submitEmail() {
  while (true) {
    const { payload: email } = yield take(SUBMIT_EMAIL + START);
    try {
      const data = yield axiosInstance.post('/authenticate', { email, redirect: BASE_URL });
      yield put({ type: SUBMIT_EMAIL + SUCCESS, payload: data });
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: SUBMIT_EMAIL + FAIL, payload: getError(error) });
    }
  }
}

function* submitCode() {
  while (true) {
    const { payload } = yield take(SUBMIT_CODE + START);
    try {
      const { data } = yield axiosInstance.post('/authenticate', { email: payload.email, code: payload.code });
      if (data.jwt) {
        yield call(setAuthToken, data.jwt);
      }
      yield put({ type: SUBMIT_CODE + SUCCESS, payload: data });
      yield put({ type: GET_USER + START, payload: data.uuid });
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: SUBMIT_CODE + FAIL, payload: getError(error) });
    }
  }
}

function* submitPhone() {
  while (true) {
    const {
      payload: { phone },
    } = yield take(SUBMIT_PHONE + START);
    try {
      const data = yield axiosInstance.post('/authenticate-phone', { phone });
      yield put({ type: SUBMIT_PHONE + SUCCESS, payload: data });
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: SUBMIT_PHONE + FAIL, payload: getError(error) });
    }
  }
}

function* submitPhoneCode() {
  while (true) {
    const {
      payload: { code },
    } = yield take(SUBMIT_PHONE_CODE + START);
    try {
      const state = yield select();
      const { phone } = sessionSelector(state);

      const { data } = yield axiosInstance.post('/authenticate-phone', { phone, code });
      if (data.jwt) {
        yield call(setAuthToken, data.jwt);
      }
      yield put({ type: SUBMIT_PHONE_CODE + SUCCESS, payload: data });
      yield put({ type: GET_USER + START, payload: data.uuid });
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: SUBMIT_PHONE_CODE + FAIL, payload: getError(error) });
    }
  }
}

function* getUser() {
  while (true) {
    const { payload } = yield take(GET_USER + START);
    const state = yield select();
    const user = userSelector(state);
    const variable = { uuid: payload || user.uuid };

    const { data, error } = yield urqlClient.query(GetUserAsAdmin, variable).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: GET_USER + FAIL, payload: getError(error) });
    } else {
      const mappedUser = mapUser(data.user_by_pk);
      if (mappedUser.isSuperAdmin) {
        const allNetworks = data.network;

        mappedUser.networkUuids = allNetworks.map(({ uuid }) => uuid);
        mappedUser.networkIds = allNetworks.map(({ id }) => id);
        mappedUser.networksByUuid = allNetworks.reduce((accumulator, network) => {
          accumulator[network.uuid] = mapNetwork(network);
          return accumulator;
        }, {});
        mappedUser.networksById = allNetworks.reduce((accumulator, network) => {
          accumulator[network.id] = mapNetwork(network);
          return accumulator;
        }, {});
      }
      yield put({ type: GET_USER + SUCCESS, payload: mappedUser });
      // force logout if the user is not admin for any network and not a SuperAdmin
      const activeNetworks = Object.values(mappedUser?.networksById).filter(({ archived_at }) => !archived_at);
      if (!activeNetworks?.length && !mappedUser?.isSuperAdmin) {
        yield put({ type: LOG_OUT });
        continue;
      }

      yield put({ type: SET_ACTIVE_NETWORK, payload: mappedUser.isSuperAdmin ? {} : activeNetworks[0] || {} });
      if (!mappedUser.isSuperAdmin) {
        yield put({ type: FETCH_NETWORK_BY_ID + START, payload: activeNetworks[0].id });
        yield put(push(`/networks/${mappedUser.networkIds[0]}/members`));
      }
    }
  }
}

function* connectStripe() {
  while (true) {
    const { payload: networkId } = yield take(CONNECT_STRIPE + START);
    try {
      const { data } = yield axiosInstance.post('/stripe-user', { networkId });
      yield put({ type: CONNECT_STRIPE + SUCCESS, payload: data });
      yield call(redirectToBase, data.url);
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: CONNECT_STRIPE + FAIL, payload: getError(error) });
    }
  }
}

function* gotoStripeDashboard() {
  while (true) {
    const { payload: account_id } = yield take(GOTO_STRIPE_DASHBOARD + START);
    try {
      const { data } = yield axiosInstance.post('/stripe-dashboard', { account_id });
      yield put({ type: GOTO_STRIPE_DASHBOARD + SUCCESS, payload: data });
      yield call(window.open, `${data.url}#/account`, '_blank');
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: GOTO_STRIPE_DASHBOARD + FAIL, payload: getError(error) });
    }
  }
}

function* logOut() {
  while (true) {
    yield take(LOG_OUT);
    yield call(removeAuthToken);
  }
}

export function* sessionSagas() {
  yield all([
    fork(submitEmail),
    fork(submitCode),
    fork(submitPhone),
    fork(submitPhoneCode),
    fork(getUser),
    fork(logOut),
    fork(connectStripe),
    fork(gotoStripeDashboard),
  ]);
}
