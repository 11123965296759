import React, { useMemo } from 'react';

import { UserCell } from './UserCell';
import { ActionMenu } from './ActionMenu';
import { Table } from './Table';
import { formatDate } from '@shared/utils/formatDate';
import { formatPhone } from '@shared/utils/formatPhone';
import FlexRow from './FlexRow';

export const NetworkMembershipInvitationTable = ({ items, action, onDecline = () => {} }) => {
  const heading = `${items.length} pending invites`;
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: ({ user_name, user_profile }) => ({
          name: user_name,
          image: user_profile && user_profile.photo,
        }),
        Cell: ({ value }) => <UserCell value={value} theme="secondary" />,
        style: {
          width: '176px',
        },
      },
      {
        Header: 'Email',
        accessor: 'user_email',
        style: {
          width: '213px',
        },
      },
      {
        Header: 'Phone',
        accessor: 'user_phone',
        Cell: ({ value }) => formatPhone(value),
        style: {
          width: '213px',
        },
      },
      {
        Header: 'Sent at',
        accessor: 'created_at',
        // Cell: ({ value }) => formatDateTime(value)
        Cell: ({ value }) => {
          return `Sent ${formatDate(value)}`;
        },
        style: {
          width: '176px',
          textAlign: 'left',
        },
      },
      // {
      //   Header: 'Referrer',
      //   accessor: 'referrer',
      //   Cell: ({ value }) => (value ? value.name : ''),
      //   style: {
      //     width: '213px',
      //     textAlign: 'left',
      //   },
      // },
      {
        id: 'actions',
        accessor: (item) => item,
        Cell: ({ value }) => (
          <FlexRow className="justify-end items-center">
            <FlexRow className="border text-11 px-2 py-0.5 rounded-md text-inactive">
              <ActionMenu
                items={[
                  {
                    href: '#Decline',
                    onClick: () => onDecline({ item: value }),
                    label: 'Decline',
                    theme: 'tertiary',
                  },
                ]}
              />
            </FlexRow>
          </FlexRow>
        ),
      },
    ],
    [onDecline],
  );

  return <Table heading={heading} columns={columns} data={items} action={action} isCollapsible />;
};
