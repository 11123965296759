const InsertNetworkMembershipInvitation = `
  mutation InsertNetworkMembershipInvitation(
    $network_uuid: uuid,
    $user_name: String,
    $user_email: String,
    $user_phone: String,
    $referrer_uuid: uuid,
    $redirect: String
  ) {
    insert_network_membership_invitation_one(
      object: {
        network_uuid: $network_uuid,
        user_name: $user_name,
        user_email: $user_email,
        user_phone: $user_phone,
        referrer_uuid: $referrer_uuid,
        redirect: $redirect
      }
    ) {
      __typename
      network_uuid
      user_email
      user_name
      user_phone
      referrer_uuid
      created_at
      updated_at
    }
  }
`;

export default InsertNetworkMembershipInvitation;
