import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import FlexCol from './FlexCol';
import FlexRow from './FlexRow';
import RightArrow from '@app/assets/images/right-arrow.svg';

export default function PageNavContainer({ className = '', title, subtitle, items = [], selectedTab, onChangeTab = () => {} }) {
  const history = useHistory();
  const routes = useLocation();
  return (
    <FlexCol className={`${className} h-full px-8 py-5 flex-shrink-0 ${isMobile ? 'w-full' : 'w-80'}`}>
      <FlexCol className={`${isMobile ? 'mb-8' : ''}`}>
        <p className={`font-medium ${isMobile ? 'font-sans text-34' : 'text-lg'}`}>{title}</p>
        <p className="text-xs text-inactive mt-1">{subtitle}</p>
      </FlexCol>
      <FlexCol className="mt-7">
        {items.map(({ title, subtitle, icon, tab, mobileRoute }, index) => (
          <FlexRow
            key={index}
            onClick={() => {
              if (isMobile && mobileRoute) {
                history.push(`${routes.pathname}/${mobileRoute}`);
              } else {
                onChangeTab(tab);
              }
            }}
            className={`items-center p-5 rounded-xl mb-4 cursor-pointer hover:bg-gray-100 ${
              selectedTab === tab || isMobile ? 'bg-white shadow-custom1' : ''
            }`}>
            <FlexRow className="items-center justify-center w-11 h-11 bg-white border rounded-full flex-shrink-0">
              <img src={icon} className="w-4 h-4" />
            </FlexRow>
            <FlexCol className="flex-full justify-center ml-2 select-none">
              <p className="text-sm">{title}</p>
              <p className="text-xs text-inactive mt-1">{subtitle}</p>
            </FlexCol>
            {isMobile && (
              <FlexCol className="items-right justify-center flex-shrink-0 select-none">
                <img src={RightArrow} style={{ color: '#222222', opacity: '0.3', width: 16, height: 16 }} />
              </FlexCol>
            )}
          </FlexRow>
        ))}
      </FlexCol>
    </FlexCol>
  );
}
