import React from 'react';

import { SpinnerIcon } from './SpinnerIcon';
import theme from '@app/theme';

export const LoadingView = ({ className }) => {
  return (
    <div className={`w-full h-full justify-center items-center flex ${className || ''}`}>
      <SpinnerIcon width={50} height={50} fill={theme.colors.primary} />
    </div>
  );
};
