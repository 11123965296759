import React, { useMemo } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

import { UserCell } from './UserCell';
import { Table } from './Table';
import verbalizeUserRole from '@shared/utils/verbalizeUserRole';
import { BLOCKED_ROLE } from '@shared/constants';

export const UserTable = ({ items, action }) => {
  const heading = `${items.length.toLocaleString()} Users`;
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: ({ name, profile }) => ({
          name,
          image: profile && profile.photo,
        }),
        Cell: ({ value }) => <UserCell value={value} theme="secondary" />,
        style: {
          width: '25ch',
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        style: {
          width: '25ch',
        },
      },
      {
        Header: 'Phone',
        accessor: ({ phone }) => (phone ? parsePhoneNumber(phone, 'US').number : ''),
        style: {
          width: '25ch',
        },
      },
      {
        Header: 'Networks',
        accessor: ({ networkUuids, networksByUuid, rolesByNetworkUuid }) => ({
          networkUuids,
          networksByUuid,
          rolesByNetworkUuid,
        }),
        Cell: ({ value: { networkUuids, networksByUuid, rolesByNetworkUuid } }) => (
          <>
            {networkUuids.length === 0 ? (
              <p>-</p>
            ) : (
              networkUuids.map((networkUuid) => (
                <p
                  key={networkUuid}
                  style={{
                    color: rolesByNetworkUuid[networkUuid] === BLOCKED_ROLE ? 'red' : 'inherit',
                  }}>
                  {networksByUuid[networkUuid].name}
                  &nbsp;({verbalizeUserRole(rolesByNetworkUuid[networkUuid])})
                </p>
              ))
            )}
            <style
              dangerouslySetInnerHTML={{
                __html: `
                  p:not(:last-of-type) {
                    margin-bottom: 10px;
                  }
                `,
              }}
            />
          </>
        ),
        style: {
          width: '20ch',
        },
      },
    ],
    [],
  );

  return <Table heading={heading} columns={columns} data={items} action={action} isCollapsible />;
};
