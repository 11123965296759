import { all, fork, put, select, take } from 'redux-saga/effects';

import { getError, showErrorToast, urqlClient } from '@app/utils';
import { FAIL, START, SUCCESS } from '../common';

import { FETCH_ADMIN_USERS, FETCH_NETWORK_USERS, FETCH_USERS } from './actions';
import { userSelector } from '../session/selector';
import GetUserAsAdmin from '@app/queries/GetUserAsAdmin';
import GetNetworkUsers from '@app/queries/GetNetworkUsers';
import GetUsers from '@app/queries/GetUsers';

function* getAdminUsers() {
  while (true) {
    yield take(FETCH_ADMIN_USERS + START);
    const state = yield select();
    const { uuid } = userSelector(state);
    const { data, error } = yield urqlClient.query(GetUserAsAdmin, { uuid }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_ADMIN_USERS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_ADMIN_USERS + SUCCESS, payload: data });
    }
  }
}

function* getUsers() {
  while (true) {
    yield take(FETCH_USERS + START);
    const { data, error } = yield urqlClient.query(GetUsers).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_USERS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_USERS + SUCCESS, payload: data });
    }
  }
}

function* getNetworkUsers() {
  while (true) {
    yield take(FETCH_NETWORK_USERS + START);
    const state = yield select();
    const { uuid } = userSelector(state);
    const { data, error } = yield urqlClient.query(GetNetworkUsers, { uuid }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_NETWORK_USERS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_NETWORK_USERS + SUCCESS, payload: data });
    }
  }
}

export function* usersSagas() {
  yield all([fork(getAdminUsers), fork(getUsers), fork(getNetworkUsers)]);
}
