const mapAnalytics = ({ network, message }) => {
  const data = [];

  const newUsers = network[0] ? network[0].new_user.aggregate.count : 0;
  data.push({ label: 'New members', count: newUsers, unit: '' });

  const posts = network[0] ? network[0].post.aggregate.count : 0;
  data.push({ label: 'Posts on feed', count: posts, unit: '' });

  const roundtables = network[0] ? network[0].roundtable.aggregate.count : 0;
  data.push({ label: 'Roundtable conversations', count: roundtables, unit: '' });

  const conversations = network[0] ? network[0].conversation.aggregate.count : 0;
  data.push({ label: 'Message channels', count: conversations, unit: '' });

  const messages = message.aggregate.count;
  data.push({ label: 'Messages sent', count: messages, unit: '' });

  return data;
};

export default mapAnalytics;
