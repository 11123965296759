import React, { useEffect, useState } from 'react';

import { CsvInviteSection, IndividualInviteSection, LoadingView } from '@app/components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersByNetworkAction, inviteMemberBulkAction } from '@app/store/network/actions';
import { inviteMemberSelector } from '@app/store/network/selector';
import FlexRow from '@app/components/FlexRow';
import PageNavContainer from '@app/components/PageNavContainer';

import UserIcon from '@app/assets/images/user.svg';
import UsersIcon from '@app/assets/images/users.svg';

const MenuItems = [
  { tab: 'invite', title: 'Invite a member', subtitle: 'Add individual members.', icon: UserIcon },
  { tab: 'invite-bulk', title: 'Send bulk invitations', subtitle: 'Invite hundreds at a time.', icon: UsersIcon },
];

export const NetworkInvitationsPage = () => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector(inviteMemberSelector);
  const { id: networkId } = useParams();
  const [selectedTab, setSelectedTab] = useState('invite');
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  const onSendInvitation = (data) => {
    // if (!isCSV) {
    //   dispatch(inviteMemberBulkAction(data.filter((item) => !!item.email)));
    // } else {
    dispatch(inviteMemberBulkAction(data.filter((item) => !!item.email || !!item.phone)));
    // }
  };

  return (
    <FlexRow className="h-full flex-1">
      <PageNavContainer
        title="Invitations"
        subtitle="Send invite and have people join your space."
        items={MenuItems}
        selectedTab={selectedTab}
        onChangeTab={(value) => setSelectedTab(value)}
      />
      <div className=" w-full">
        {selectedTab === 'invite' && (
          <IndividualInviteSection onSendInvitation={(data) => onSendInvitation(data)} loading={loading} success={success} />
        )}
        {selectedTab === 'invite-bulk' && (
          <CsvInviteSection onSendInvitation={(data) => onSendInvitation(data)} loading={loading} success={success} />
        )}
      </div>
      {loading && <LoadingView className="absolute inset-0 z-10 bg-black bg-opacity-50" />}
    </FlexRow>
  );
};
