import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { UserCell } from './UserCell';
import { Table } from './Table';

const Cell = ({ value }) => (
  <div className="flex flex-wrap justify-start">
    {value.map(({ name, email, id, image }) => (
      <UserCell
        key={id}
        value={{
          name,
          email,
          image,
        }}
        showEmail
      />
    ))}
  </div>
);

export const NetworkTable = ({ items, action }) => {
  const heading = `${items.length} Networks`;
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: ({ name, intId, archived_at }) => ({
          name,
          intId,
          archived_at,
        }),
        style: {
          width: '20ch',
        },
        Cell: ({ value }) => (
          <Link className={`${value.archived_at ? 'text-red-600' : ''}`} to={`networks/${value.intId}/members`}>
            {value.name}
          </Link>
        ),
      },
      {
        Header: 'Member count',
        accessor: 'userCount',
        style: {
          width: '15ch',
        },
      },
      {
        Header: 'Admins',
        accessor: 'admins',
        Cell: ({ value }) => <Cell value={value} />,
      },
      {
        Header: 'Status',
        accessor: 'archived_at',
        style: {
          marginLeft: 'auto',
          width: '100px',
        },
        Cell: ({ value }) => (value ? <div className="text-red-700 font-medium">Archived</div> : ''),
      },
    ],
    [],
  );

  return <Table action={action} heading={heading} columns={columns} data={items} isCollapsible />;
};
