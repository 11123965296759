const DeleteCategory = `
  mutation DeleteCategory($category_uuid: uuid!, $network_uuid: uuid!) {
    delete_network_category_by_pk(
      network_uuid: $network_uuid,
      category_uuid: $category_uuid)
    {
      __typename
    }
    delete_network_category_hashtag(where: {
      category_uuid: { _eq: $category_uuid },
      network_uuid: { _eq: $network_uuid }
    }) {
      affected_rows
    }
  }
`;

export default DeleteCategory;
