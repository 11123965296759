import React, { useEffect, useState } from 'react';

import { AnalyticsCard } from '@app/components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNetworkAnalyticsAction, fetchUsersByNetworkAction } from '@app/store/network/actions';

import { analyticsSelector, currentNetworkSelector } from '@app/store/network/selector';
import FlexRow from '@app/components/FlexRow';
import FlexCol from '@app/components/FlexCol';

const getMonday = (d) => {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

const dateFilter = [
  { label: 'All', date: null },
  { label: 'Day', date: new Date().setHours(0, 0, 0, 0) },
  { label: 'Week', date: getMonday(new Date()).setHours(0, 0, 0, 0) },
  { label: 'Month', date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0) },
];

export const NetworkAnalyticsDetailPage = () => {
  const dispatch = useDispatch();
  const { id: networkId } = useParams();
  const { uuid: network_uuid } = useSelector(currentNetworkSelector);
  const [filter, setFilter] = useState(dateFilter[0]);
  const { data = [] } = useSelector(analyticsSelector);
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  useEffect(() => {
    if (network_uuid) {
      dispatch(fetchNetworkAnalyticsAction(new Date(filter.date)));
    }
  }, [dispatch, network_uuid, filter]);

  return (
    <FlexCol className="flex-1">
      <FlexRow className="w-full p-7">
        <FlexCol className="flex-1">
          <div className="font-medium text-dark text-34 font-sans">Overview</div>
          <div className="text-sm text-inactive tracking-tight mt-1">Learn where members are spending their time.</div>
        </FlexCol>
      </FlexRow>
      <FlexCol className="px-7">
        <FlexRow className="rounded-xl w-full border items-center justify-center bg-gray-100 flex-shrink-0">
          {dateFilter.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer py-2 text-center flex-1 font-medium
                            ${item.label === filter.label ? ' bg-white shadow-xl rounded-xl' : '  hover:bg-gray-200'}
                            ${index === dateFilter.length - 1 ? ' rounded-r-xl' : ''}
                            ${index === 0 ? ' rounded-l-xl' : ''}`}
              onClick={() => setFilter(item)}>
              {item.label}
            </div>
          ))}
        </FlexRow>
      </FlexCol>
      <div className="p-7 mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-4 md:gap-y-4 lg:grid-cols-3 lg:gap-4 2xl:grid-cols-4 2xl:gap-10">
        {data.map(({ label, count, unit }, index) => (
          <AnalyticsCard key={index} title={label} value={count.toLocaleString() + unit} />
        ))}
      </div>
    </FlexCol>
  );
};
