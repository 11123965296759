import { logoutAction } from '@app/store/session/actions';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import FlexCol from './FlexCol';
import FlexRow from './FlexRow';

export const NavMenuItem = ({ link, label, index, activePath, bottomSeparator, isLogout, icon, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onLink = () => {
    onClose();
    if (isLogout) {
      dispatch(logoutAction());
      history.push('/login');
    } else {
      history.push(link);
    }
  };

  return (
    <FlexCol className="w-full">
      <FlexRow className="relative w-full">
        {!isMobile && <div className={`absolute top-0 bottom-0 h-full left-0 w-1.5 ${activePath === index && 'bg-primary'}`} />}
        <Link
          onClick={onLink}
          key={link}
          data-tour={label}
          className={`px-8 flex-full flex flex-row items-center font-medium hover:bg-gray-100 ${
            isMobile ? ' text-white text-base my-6' : activePath === index ? 'text-dark text-xs h-8' : 'text-inactive text-xs h-8'
          }`}
          to={link}>
          <img
            src={icon}
            className={` mr-3 ${activePath !== index || isLogout ? 'opacity-60' : ''} ${isMobile ? 'filter invert w-5 h-5' : 'w-3 h-3'}`}
          />
          <span className={`${isMobile ? '' : 'hidden xl:block'} `}>{label}</span>
        </Link>
      </FlexRow>
      {bottomSeparator && <div className="border-b border-gray-200 h-1 mx-8 my-6" />}
    </FlexCol>
  );
};
