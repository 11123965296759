import React, { useState, useRef, useCallback } from 'react';

import Button from './Button';
import { ActionLink } from './ActionLink';
import KebabIcon from './KebabIcon';
import useClickOutside from '@shared/hooks/useClickOutside';
import useKeyDown from '@shared/hooks/useKeyDown';

export const ActionMenu = ({ items = [], isPopoverOnly = false }) => {
  const popoverRef = useRef();
  const isPopover = isPopoverOnly || items.length >= 3;
  const [isPopoverActive, setIsPopoverActive] = useState(false);

  function handleTogglePopover(event) {
    event.preventDefault();
    setIsPopoverActive(!isPopoverActive);
  }

  useKeyDown('Escape', () => setIsPopoverActive(false));

  useClickOutside(
    popoverRef,
    useCallback(() => setIsPopoverActive(false), []),
  );

  return (
    <div className={['action-menu', isPopoverActive && 'active'].filter((x) => x).join(' ')}>
      <div className="container">
        {!isPopover && (
          <ul className="static-list">
            {items.map((item) => (
              <li key={item.href}>
                <ActionLink href={item.href} onClick={item.onClick}>
                  <Button label={item.label} theme={item.theme} leftIcon={item.icon} />
                </ActionLink>
              </li>
            ))}
          </ul>
        )}

        {isPopover && (
          <a className="toggle p-4 flex justify-center items-center" href="#toggle-popover" onClick={handleTogglePopover}>
            <KebabIcon fill="inherit" />
          </a>
        )}

        {isPopover && (
          <div className="popover" ref={popoverRef}>
            <a className="toggle" href="#toggle-popover" onClick={handleTogglePopover}>
              <KebabIcon fill="inherit" />
            </a>
            <ul className="popover-list">
              {items.map((item) => (
                <li key={item.href}>
                  <ActionLink href={item.href} onClick={item.onClick}>
                    <Button label={item.label} theme="transparent" leftIcon={item.icon} size={item.size} className="w-full" />
                  </ActionLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .action-menu {
          }
  
          .action-menu .container {
            position: relative;
          }
  
          .action-menu .active .toggle {
            position: relative;
            left: 100%;
            transform: translateX(-100%);
          }
  
          .active .popover {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: var(--z-index-buoyant);
            box-shadow: var(--box-shadow);
            border-radius: var(--border-radius);
            padding-bottom: 10px;
  
            background: white;
          }
  
          .popover {
            display: none;
            width: max-content;
            min-width: 170px;
            text-align: right;
          }
  
          .popover li:not(:last-of-type) {
            margin-bottom: 20px;
          }

          .popover .icon-wrapper {
            position: relative;
          }
  
          .action-menu .static-list {
            display: flex;
            flex-flow: row;
          }
  
          .action-menu .static-list li:not(:last-child) {
            margin-right: 10px;
          }
  
          .action-menu ul {
            list-style: none;
          }
  
          .popover .toggle {
            font-weight: bold;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            fill: var(--gray);
          }
          `,
        }}
      />
    </div>
  );
};

export default ActionMenu;
