const InsertNewCategory = `
  mutation InsertNewCategory(
    $uuid: uuid,
    $category: String,
    $network_category: network_category_insert_input!
  ) {
    insert_category_one(object: {
      uuid: $uuid,
      category: $category
    }) {
      uuid
    }
    insert_network_category_one(object: $network_category) {
      __typename
    }
  }
`;

export default InsertNewCategory;
