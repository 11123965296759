import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { BaseModal } from './BaseModal';
import Button from '../Button';
import { Input } from '../Input';
import { POST_DECLINE_REASONS } from '@shared/constants';
import { showErrorToast } from '@app/utils';
import { useDispatch, useSelector } from 'react-redux';
import { declinePostSelector } from '@app/store/network/selector';
import { declinePendingPostAction } from '@app/store/network/actions';
import FlexRow from '../FlexRow';
import MobileDownArrow from '@app/assets/images/down-arrow.svg';

export const PostDeclineModal = ({ isOpen, onClose, onSuccess, content }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(declinePostSelector);
  const [selectedReason, setSelectReason] = useState(POST_DECLINE_REASONS[0]);
  const [otherValue, setOtherValue] = useState('');
  useEffect(() => {
    setOtherValue('');
  }, [selectedReason]);

  const onDecline = () => {
    if (selectedReason === 'Other' && !otherValue.trim()) {
      showErrorToast('Please select other decline reason or input others.');
      return;
    }
    dispatch(
      declinePendingPostAction({ content_uuid: content.uuid, creator_uuid: content.creator.uuid, reason: otherValue || selectedReason, onSuccess }),
    );
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className={`flex flex-col h-full ml-auto px-7 ${isMobile ? 'w-full bg-dark' : 'w-72 bg-white'}`}>
      <div className="flex flex-col h-full overflow-auto">
        <FlexRow className="p-7 pl-0" onClick={onClose}>
          <img src={MobileDownArrow} className="rotate-90 transform cursor-pointer w-8 h-8 filter invert" />
        </FlexRow>
        <div className={`${isMobile ? 'text-white text-xl' : 'text-lg'}`}>Post decline</div>
        <div className={`my-4 w-10/12 ${isMobile ? 'text-sm text-white' : 'text-xs text-label '}`}>
          Please select a reason for declining this post and we will share that with the member to improve their future posts.
        </div>
        <div>
          {POST_DECLINE_REASONS.map((reason, index) => (
            <FlexRow
              key={index}
              className={`items-center cursor-pointer w-full ${isMobile ? 'my-8' : 'my-4'}`}
              onClick={() => setSelectReason(reason)}>
              <div
                className={`w-5 h-5 rounded-full mr-2 flex justify-center border border-inactive items-center flex-shrink-0 ${
                  isMobile ? 'bg-white' : ''
                }`}>
                {selectedReason === reason && <div className="bg-primary rounded-full w-3 h-3" />}
              </div>
              <FlexRow className={`justify-center items-center ${isMobile ? 'text-white text-sm' : 'text-label text-xs'}`}>
                <div>{reason}</div>
                {selectedReason === reason && reason === 'Other' && (
                  <Input
                    type="text"
                    className="ml-4 py-0 w-64 rounded"
                    value={otherValue}
                    onChange={({ target }) => setOtherValue(target.value)}
                    placeholder="decline reason here..."
                    inputFieldClassName="p-2 rounded"
                  />
                )}
              </FlexRow>
            </FlexRow>
          ))}
        </div>
        <div className={`mt-3 flex w-full ${isMobile ? 'flex-row' : 'flex-col'}`}>
          <Button
            label="Submit"
            className={`w-full py-2 text-xs bg-primary-green text-white order-1 ${isMobile ? 'py-4 text-sm' : 'text-xs'}`}
            isFetching={loading}
            onClick={onDecline}
          />
          <Button
            label="Cancel"
            className={`w-full py-2 bg-bg1 text-inactive ${!isMobile ? 'mt-3 order-2 text-xs' : 'mr-3 text-sm'}`}
            onClick={onClose}
          />
        </div>
      </div>
    </BaseModal>
  );
};
