import React from 'react';

import { Button } from '../Button';
import CloseIcon from '../CloseIcon';
import { BaseModal } from './BaseModal';
import FlexRow from '../FlexRow';
import FlexCol from '../FlexCol';

export const DoubleConfirmModal = ({
  isOpen,
  onClose,
  heading = 'Are you sure?',
  description,
  submitLabel,
  cancelLabel,
  isFetching = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className="m-auto bg-white rounded-xl shadow-xl w-full max-w-xs" overlayClassName="flex">
      <div className="p-4">
        <form onSubmit={handleSubmit}>
          <p className="">{heading}</p>
          {description && (
            <div className="mt-8 text-sm">
              {description.split('\n').map((i, idx) => (
                <p key={idx}>{i}</p>
              ))}
            </div>
          )}
          <FlexCol className="mt-8">
            {submitLabel && (
              <FlexRow className="row mb-3 justify-center">
                <Button type="submit" className="bg-primary-green text-white p-2 px-4 text-11" label={submitLabel} isFetching={isFetching} />
              </FlexRow>
            )}
            {cancelLabel && (
              <FlexRow className="row mb-3 justify-center">
                <Button label={cancelLabel} size="large" className="text-11 p-2 px-4" theme="secondary" onClick={onCancel} />
              </FlexRow>
            )}
          </FlexCol>
        </form>
        <div className="absolute top-4 right-4" onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
    </BaseModal>
  );
};
