import { createAction } from 'redux-actions';
import { START } from '../common';

export const SET_ACTIVE_NETWORK = 'SET_ACTIVE_NETWORK';
export const RELOAD_NETWORKS = 'RELOAD_NETWORKS';
export const FETCH_NETWORKS = 'FETCH_NETWORKS';
export const FETCH_NETWORK_BY_ID = 'FETCH_NETWORK_BY_ID';
export const FETCH_NETWORK_REQUESTS = 'FETCH_NETWORK_REQUESTS';
export const INIT_CREATE_NETWORK = 'INIT_CREATE_NETWORK';
export const CREATE_NETWORK = 'CREATE_NETWORK';
export const INIT_DELETE_NETWORK = 'INIT_DELETE_NETWORK';
export const DELETE_NETWORK = 'DELETE_NETWORK';
export const UPDATE_NETWORK_SETTINGS = 'UPDATE_NETWORK_SETTINGS';
export const INVITE_MEMBER = 'INVITE_MEMBER';
export const INIT_INVITE_MEMBER = 'INIT_INVITE_MEMBER';
export const INVITE_MEMBER_BULK = 'INVITE_MEMBER_BULK';
export const ACCEPT_ALL_MEMBER_REQUESTS = 'ACCEPT_ALL_MEMBER_REQUESTS';
export const ACCEPT_MEMBER_REQUEST = 'ACCEPT_MEMBER_REQUEST';
export const DECLINE_MEMBER_REQUEST = 'DECLINE_MEMBER_REQUEST';
export const DECLINE_MEMBER_INVITE = 'DECLINE_MEMBER_INVITE';
export const BLOCK_USER = 'BLOCK_USER';
export const MAKE_ADMIN = 'MAKE_ADMIN';
export const APPROVE_USER = 'APPROVE_USER';
export const FETCH_NETWORK_ANALYTICS = 'FETCH_NETWORK_ANALYTICS';
export const FETCH_PENDING_POSTS = 'FETCH_PENDING_POSTS';
export const APPROVE_PENDING_POST = 'APPROVE_PENDING_POST';
export const DECLINE_PENDING_POST = 'DECLINE_PENDING_POST';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const ADD_HASHTAG = 'ADD_HASHTAG';
export const DELETE_HASHTAG = 'DELETE_HASHTAG';
export const INIT_CATEGORY_ACTION = 'INIT_CATEGORY_ACTION';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ARCHIVE_NETWORK = 'ARCHIVE_NETWORK';
export const UPDATE_NETWORK_SEARCH = 'UPDATE_NETWORK_SEARCH';
export const UPDATE_NETWORK_ARCHIVED_VISIBLE = 'UPDATE_NETWORK_ARCHIVED_VISIBLE';

export const setActiveNetworkAction = createAction(SET_ACTIVE_NETWORK);
export const reloadNetworkAction = createAction(RELOAD_NETWORKS);
export const fetchNetworksAction = createAction(FETCH_NETWORKS + START);
export const fetchNetworkByIdAction = createAction(FETCH_NETWORK_BY_ID + START);
export const fetchNetworkRequestsAction = createAction(FETCH_NETWORK_REQUESTS + START);
export const createNetworkAction = createAction(CREATE_NETWORK + START);
export const initCreateNetworkAction = createAction(INIT_CREATE_NETWORK);
export const deleteNetworkkAction = createAction(DELETE_NETWORK + START);
export const initDeleteNetworkAction = createAction(INIT_DELETE_NETWORK);
export const fetchUsersByNetworkAction = createAction(FETCH_NETWORK_BY_ID + START);
export const updateNetworkSettingsAction = createAction(UPDATE_NETWORK_SETTINGS + START);
export const inviteMemberAction = createAction(INVITE_MEMBER + START);
export const initInviteMemberAction = createAction(INIT_INVITE_MEMBER);
export const inviteMemberBulkAction = createAction(INVITE_MEMBER_BULK + START);
export const accepAllMemberRequestsAction = createAction(ACCEPT_ALL_MEMBER_REQUESTS + START);
export const acceptMemberRequestAction = createAction(ACCEPT_MEMBER_REQUEST + START);
export const declineMemberRequestAction = createAction(DECLINE_MEMBER_REQUEST + START);
export const declineMemberInviteAction = createAction(DECLINE_MEMBER_INVITE + START);
export const blockUserAction = createAction(BLOCK_USER + START);
export const makeAdminAction = createAction(MAKE_ADMIN + START);
export const approveUserAction = createAction(APPROVE_USER + START);
export const fetchNetworkAnalyticsAction = createAction(FETCH_NETWORK_ANALYTICS + START);
export const fetchPendingPostsAction = createAction(FETCH_PENDING_POSTS + START);
export const approvePendingPostAction = createAction(APPROVE_PENDING_POST + START);
export const declinePendingPostAction = createAction(DECLINE_PENDING_POST + START);
export const fetchCategoriesAction = createAction(FETCH_CATEGORIES + START);
export const addHashTagAction = createAction(ADD_HASHTAG + START);
export const deleteHashtagAction = createAction(DELETE_HASHTAG + START);
export const addCategoryAction = createAction(ADD_CATEGORY + START);
export const deleteCategoryAction = createAction(DELETE_CATEGORY + START);
export const archiveNetworkAction = createAction(ARCHIVE_NETWORK + START);
export const updateNetworkSearchAction = createAction(UPDATE_NETWORK_SEARCH);
export const toggleNetworkArchivedVisibleAction = createAction(UPDATE_NETWORK_ARCHIVED_VISIBLE);
