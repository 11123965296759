import axios from 'axios';
import { createClient as createUrqlClient } from 'urql';

import { Env, getAuthToken, getSavedToken } from '@app/config';

const REST_BASE_URL = Env.REST_BASE_URL;
const HASURA_BASE_URL = Env.HASURA_BASE_URL;

export const axiosInstance = axios.create({ baseURL: REST_BASE_URL });

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const imageAxios = axios.create({
  baseURL: 'https://api.cloudinary.com/v1_1/' + Env.CLOUDINARY_NAME + '/image/upload',
});

export const videoAxios = axios.create({
  baseURL: 'https://api.cloudinary.com/v1_1/' + Env.CLOUDINARY_NAME + '/video/upload',
});

export const urqlClient = createUrqlClient({
  url: HASURA_BASE_URL,
  fetchOptions: () => ({
    headers: {
      Authorization: getSavedToken() ? `Bearer ${getSavedToken()}` : '',
    },
  }),
});
