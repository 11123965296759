import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

export const ComingSoon = () => {
  const { id } = useParams();
  if (id) {
    return <Redirect to={`/networks/${id}/members`} />;
  }
  return (
    <div className="flex flex-1 w-full h-full items-center justify-center">
      <div className="text-3xl">Coming soon!</div>
    </div>
  );
};
