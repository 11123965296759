const GetNetworkById = `
  query GetNetworkById($id: bigint) {
    network(where: {id: {_eq: $id}}) {
      uuid
      id
      name
      avatar
      updated_at
      created_at
      archived_at
      network_membership_requests {
        __typename
        uuid
        id
        body
        created_at
        updated_at
        user {
          __typename
          uuid
          id
          name
          email
          phone
          updated_at
          created_at
          user_profile {
            username
            location
            photo
          }
        }
      }
      network_membership_invitations {
        __typename
        uuid
        id
        user_name
        user_email
        user_phone
        created_at
        updated_at
        referrer {
          name
          uuid
        }
      }
      network_users {
        __typename
        network_uuid
        user_uuid
        user {
          uuid
          id
          name
          email
          phone
          updated_at
          created_at
          referrer_uuid
          user_profile {
            username
            location
            photo
            date_of_birth
          }
        }
        role
        is_blocked
        admin_approved
        created_at
        updated_at
      }
      setting: network_setting {
        post_approval
      }
      plans: network_plans {
        network_uuid
        name
        description
        price_id
        price
        period
      }
    }
  }
`;

export default GetNetworkById;
