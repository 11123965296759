const UpdateContentApproveStatus = `
  mutation UpdateContentApproveStatus(
    $content_uuid: uuid!,
    $approved: Boolean = true
  ) {
    result: update_content_by_pk(
      pk_columns: {uuid: $content_uuid},
      _set: {admin_approved: $approved, created_at: "now()"}
    ) {
      __typename
      uuid
    }
  }
`;

export default UpdateContentApproveStatus;
