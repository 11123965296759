import { createAction } from 'redux-actions';
import { START } from '../common';

export const AUTHORIZE_NETOWRK_USER = 'AUTHORIZE_NETOWRK_USER';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SUBSCRIBE_NETWORK_PLAN = 'SUBSCRIBE_NETWORK_PLAN';

export const authrizeNetworkUserAction = createAction(AUTHORIZE_NETOWRK_USER + START);
export const selectPlanAction = createAction(SELECT_PLAN);
export const subscribeNetworkPlanAction = createAction(SUBSCRIBE_NETWORK_PLAN + START);
