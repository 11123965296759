import { all, fork, put, select, take } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { axiosInstance, CloudUploadMedia, getError, showErrorToast, showInfoToast, urqlClient } from '@app/utils';
import { FAIL, START, SUCCESS } from '../common';
import {
  ACCEPT_ALL_MEMBER_REQUESTS,
  ACCEPT_MEMBER_REQUEST,
  ADD_CATEGORY,
  ADD_HASHTAG,
  APPROVE_PENDING_POST,
  APPROVE_USER,
  ARCHIVE_NETWORK,
  BLOCK_USER,
  CREATE_NETWORK,
  DECLINE_MEMBER_INVITE,
  DECLINE_MEMBER_REQUEST,
  DECLINE_PENDING_POST,
  DELETE_CATEGORY,
  DELETE_HASHTAG,
  DELETE_NETWORK,
  FETCH_CATEGORIES,
  FETCH_NETWORKS,
  FETCH_NETWORK_ANALYTICS,
  FETCH_NETWORK_BY_ID,
  FETCH_NETWORK_REQUESTS,
  FETCH_PENDING_POSTS,
  INIT_CATEGORY_ACTION,
  INIT_INVITE_MEMBER,
  INVITE_MEMBER,
  INVITE_MEMBER_BULK,
  MAKE_ADMIN,
  RELOAD_NETWORKS,
  SET_ACTIVE_NETWORK,
  UPDATE_NETWORK_SETTINGS,
} from '../network/actions';
import { MEMBER_ROLE, NETWORK_ADMIN_ROLE, NETWORK_MANAGER_ROLE } from '@shared/constants';
import mapAnalytics from '@shared/mappings/mapAnalytics';

import GetNetworksWithAdmins from '@app/queries/GetNetworksWithAdmins';
import GetNetworkAccessRequests from '@app/queries/GetNetworkAccessRequests';
import GetNetworkById from '@app/queries/GetNetworkById';
import InsertNetworkMembershipInvitation from '@app/mutations/InsertNetworkMembershipInvitation';
import InsertNetworkWithUser from '@app/mutations/InsertNetworkWithUser';
import DeleteNetworkAccessRequest from '@app/mutations/DeleteNetworkAccessRequest';
import UpdateNetwork from '@app/mutations/UpdateNetwork';
import InsertNetworkMember from '@app/mutations/InsertNetworkMember';
import DeleteNetworkMembershipRequest from '@app/mutations/DeleteNetworkMembershipRequest';
import DeleteNetworkMembershipInvitation from '@app/mutations/DeleteNetworkMembershipInvitation';

import { FETCH_USERS } from '../users/actions';
import { GET_USER } from '../session/actions';
import { activeNetworkSelector, currentNetworkSelector } from './selector';
import { Env } from '@app/config';
import UpdateNetworkUserIsBlocked from '@app/mutations/UpdateNetworkUserIsBlocked';
import UpdateNetworkUserRole from '@app/mutations/UpdateNetworkUserRole';
import UpdateNetworkUserAdminApproved from '@app/mutations/UpdateNetworkUserAdminApproved';
import GetAnalyticsData from '@app/queries/GetAnalyticsData';
import { userSelector } from '../session/selector';
import GetPendingPosts from '@app/queries/GetPengingPosts';
import UpdateContentApproveStatus from '@app/mutations/UpdateContentApproveStatus';
import UpdateContentReject from '@app/mutations/UpdateContentReject';
import GetNetworkCategories from '@app/queries/GetNetworkCategories';
import GetHashtagByName from '@app/queries/GetHashtagByName';
import InsertHastagToCategory from '@app/mutations/InsertHastagToCategory';
import InsertNewHashtag from '@app/mutations/InsertNewHashtag';
import DeleteCategoryHashtag from '@app/mutations/DeleteCategoryHashtag';
import GetCategoryByName from '@app/queries/GetCategoryByName';
import InsertNewCategory from '@app/mutations/InsertNewCategory';
import InsertCategoryToNetwork from '@app/mutations/InsertCategoryToNetwork';
import DeleteCategory from '@app/mutations/DeleteCategory';
import InsertNetworkMembershipInvitations from '@app/mutations/InsertNetworkMembershipInvitations';
import UpdateNetworkArchive from '@app/mutations/UpdateNetworkArchive';

function* getNetworks() {
  while (true) {
    yield take(SET_ACTIVE_NETWORK);
    const state = yield select();
    const activeNetwork = activeNetworkSelector(state);
    if (activeNetwork.uuid) {
      yield put({ type: FETCH_NETWORK_BY_ID + START, payload: activeNetwork.id });
    } else {
      yield put({ type: FETCH_NETWORKS + START });
    }
  }
}

function* getAllNetworks() {
  while (true) {
    yield take(FETCH_NETWORKS + START);
    const { data, error } = yield urqlClient.query(GetNetworksWithAdmins).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_NETWORKS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_NETWORKS + SUCCESS, payload: data });
    }
  }
}

function* getNetworkAccessRequests() {
  while (true) {
    yield take(SET_ACTIVE_NETWORK);
    const state = yield select();
    const activeNetwork = activeNetworkSelector(state);
    if (activeNetwork.uuid) {
      continue;
    }
    yield put({ type: FETCH_NETWORK_REQUESTS + START });
    const { data, error } = yield urqlClient.query(GetNetworkAccessRequests).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_NETWORK_REQUESTS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_NETWORK_REQUESTS + SUCCESS, payload: data });
    }
  }
}

function* createNetwork() {
  while (true) {
    const {
      payload: { name, email, adminName, phone, accessRequestId },
    } = yield take(CREATE_NETWORK + START);
    const variable = {
      network_name: name,
      user_email: email,
      user_name: adminName,
      user_phone: phone,
      user_role: NETWORK_ADMIN_ROLE,
    };
    const { data, error } = yield urqlClient.mutation(InsertNetworkWithUser, variable).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: CREATE_NETWORK + FAIL, payload: getError(error) });
    } else {
      yield put({ type: CREATE_NETWORK + SUCCESS, payload: data });
      // reload networks
      yield put({ type: RELOAD_NETWORKS });
      // reload users
      yield put({ type: FETCH_USERS + START });

      if (accessRequestId) {
        yield urqlClient.mutation(DeleteNetworkAccessRequest, { uuid: accessRequestId });
      }
    }
  }
}

function* deleteNetwork() {
  while (true) {
    const { payload: uuid } = yield take(DELETE_NETWORK + START);
    const { data, error } = yield urqlClient.mutation(DeleteNetworkAccessRequest, { uuid }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: DELETE_NETWORK + FAIL, payload: getError(error) });
    } else {
      yield put({ type: DELETE_NETWORK + SUCCESS, payload: data });
      // reload networks
      yield put({ type: RELOAD_NETWORKS });
    }
  }
}

function* reloadNetworks() {
  while (true) {
    yield take(RELOAD_NETWORKS);
    const state = yield select();
    const activeNetwork = activeNetworkSelector(state);
    yield put({ type: SET_ACTIVE_NETWORK, payload: activeNetwork });
  }
}

function* getNetworkUsers() {
  while (true) {
    const { payload } = yield take(FETCH_NETWORK_BY_ID + START);
    if (payload) {
      urqlClient.requestPolicy = 'network-only';
      const { data, error } = yield urqlClient.query(GetNetworkById, { id: payload }).toPromise();

      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: FETCH_NETWORK_BY_ID + FAIL, payload: getError(error) });
      } else {
        yield put({ type: FETCH_NETWORK_BY_ID + SUCCESS, payload: data.network[0] });
      }
    }
  }
}

function* updateNetworkSettings() {
  while (true) {
    const {
      payload: { name, avatar, approval, uploadProgress = () => {} },
    } = yield take(UPDATE_NETWORK_SETTINGS + START);
    let avatarUploadResult = null;
    // upload avatar if changed
    try {
      if (avatar) {
        const { url } = yield CloudUploadMedia(avatar, {
          folder: 'network_logos',
          uploadProgress,
        });

        avatarUploadResult = url;
      }
      const state = yield select();
      const currentNetwork = currentNetworkSelector(state);

      const variable = { name, uuid: currentNetwork.uuid, avatar: avatarUploadResult || currentNetwork.avatar, post_approval: approval };

      const { data, error } = yield urqlClient.query(UpdateNetwork, variable).toPromise();

      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: UPDATE_NETWORK_SETTINGS + FAIL, payload: getError(error) });
      } else {
        yield put({ type: UPDATE_NETWORK_SETTINGS + SUCCESS, payload: data });
        yield put({ type: FETCH_NETWORK_BY_ID + START, payload: currentNetwork.id });
        yield put({ type: GET_USER + START });
      }
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: UPDATE_NETWORK_SETTINGS + FAIL, payload: getError(error) });
    }
  }
}

function* inviteMember() {
  while (true) {
    const { payload } = yield take(INVITE_MEMBER + START);
    if (!payload.user_phone && !payload.email) {
      const errorMessage = 'User email or phone number is required.';
      showErrorToast(errorMessage);
      yield put({ type: INVITE_MEMBER + FAIL, payload: { message: errorMessage } });
      continue;
    }
    const state = yield select();
    const currentNetwork = currentNetworkSelector(state);
    const user = userSelector(state);
    const variable = {
      network_uuid: payload.network_uuid || currentNetwork.uuid,
      user_name: payload.name,
      user_email: payload.email,
      user_phone: payload.user_phone,
      redirect: `${Env.MOBILE_BASE_URL}accept-invitation`,
      referrer_uuid: payload.user_uuid || user.uuid,
    };
    if (payload.register) {
      try {
        const { data } = yield axiosInstance.post('/member-register', variable);
        yield put({ type: INVITE_MEMBER + SUCCESS, payload: data });
      } catch (error) {
        showErrorToast(getError(error).message);
        yield put({ type: INVITE_MEMBER + FAIL, payload: getError(error) });
      }
    } else {
      const { data, error } = yield urqlClient.mutation(InsertNetworkMembershipInvitation, variable).toPromise();
      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: INVITE_MEMBER + FAIL, payload: getError(error) });
      } else {
        yield put({ type: INVITE_MEMBER + SUCCESS, payload: data });
        yield put({ type: FETCH_NETWORK_BY_ID + START, payload: currentNetwork.id });
      }
    }
  }
}

function* inviteMemberBulk() {
  while (true) {
    const { payload } = yield take(INVITE_MEMBER_BULK + START);
    const state = yield select();
    const currentNetwork = currentNetworkSelector(state);
    const user = userSelector(state);
    try {
      // console.log('PAYLOAD', payload);
      if (!payload || payload.length === 0) {
        throw new Error('Additional data required');
      }
      const items = payload.map((item) => ({
        network_uuid: currentNetwork.uuid,
        user_name: item.name,
        user_email: item.email || '',
        user_phone: item.phone || '',
        redirect: `${Env.MOBILE_BASE_URL}accept-invitation`,
        referrer_uuid: user.uuid,
      }));
      const { data, error } = yield urqlClient.mutation(InsertNetworkMembershipInvitations, { data: items }).toPromise();
      if (error) {
        showErrorToast('Already invited user or something went wrong.');
        yield put({ type: INVITE_MEMBER_BULK + FAIL, payload: getError(error) });
      } else {
        yield put({ type: INVITE_MEMBER_BULK + SUCCESS, payload: data });
        showInfoToast('Successfully sent invitations');
        yield put({ type: INIT_INVITE_MEMBER });
        yield put({ type: FETCH_NETWORK_BY_ID + START, payload: currentNetwork.id });
      }
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: INVITE_MEMBER_BULK + FAIL, payload: getError(error) });
    }
  }
}

function* acceptAllRequests() {
  while (true) {
    yield take(ACCEPT_ALL_MEMBER_REQUESTS + START);
    const state = yield select();
    const { uuid: network_uuid, name: network_name, avatar: network_avatar, network_membership_requests, id } = currentNetworkSelector(state);

    const { data, error } = yield all(
      network_membership_requests.map(({ user, uuid }) =>
        urqlClient
          .mutation(InsertNetworkMember, { network_uuid, user_uuid: user.uuid })
          .toPromise()
          .then(() => urqlClient.mutation(DeleteNetworkMembershipRequest, { uuid }).toPromise()),
      ),
    );
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: ACCEPT_ALL_MEMBER_REQUESTS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: ACCEPT_ALL_MEMBER_REQUESTS + SUCCESS, payload: data });
      yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
      yield axiosInstance.post('/accept-member-request', {
        network_name,
        network_avatar,
        emails: network_membership_requests.filter(({ user }) => !!user.email).map(({ user }) => user.email),
      });
    }
  }
}

function* acceptMemberRequest() {
  while (true) {
    const {
      payload: { uuid, user },
    } = yield take(ACCEPT_MEMBER_REQUEST + START);
    const state = yield select();
    const { uuid: network_uuid, name: network_name, avatar: network_avatar, id } = currentNetworkSelector(state);
    const { error } = yield urqlClient.mutation(InsertNetworkMember, { network_uuid, user_uuid: user.uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: ACCEPT_MEMBER_REQUEST + FAIL, payload: getError(error) });
      continue;
    }
    const { error: deleteError } = yield urqlClient.mutation(DeleteNetworkMembershipRequest, { uuid }).toPromise();
    if (deleteError) {
      showErrorToast(getError(error).message);
      yield put({ type: ACCEPT_MEMBER_REQUEST + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: ACCEPT_MEMBER_REQUEST + SUCCESS });
    yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
    if (user.email) {
      yield axiosInstance.post('/accept-member-request', {
        network_name,
        network_avatar,
        emails: [user.email],
      });
    }
  }
}

function* declineMemberRequest() {
  while (true) {
    const {
      payload: { uuid },
    } = yield take(DECLINE_MEMBER_REQUEST + START);
    const { error } = yield urqlClient.mutation(DeleteNetworkMembershipRequest, { uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: DECLINE_MEMBER_REQUEST + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: DECLINE_MEMBER_REQUEST + SUCCESS });

    const state = yield select();
    const { id } = currentNetworkSelector(state);
    yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
  }
}

function* declineMemberInvite() {
  while (true) {
    const {
      payload: { uuid },
    } = yield take(DECLINE_MEMBER_INVITE + START);
    const { error } = yield urqlClient.mutation(DeleteNetworkMembershipInvitation, { uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: DECLINE_MEMBER_INVITE + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: DECLINE_MEMBER_INVITE + SUCCESS });

    const state = yield select();
    const { id } = currentNetworkSelector(state);
    yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
  }
}

function* blockUser() {
  while (true) {
    const {
      payload: { user_uuid, is_blocked },
    } = yield take(BLOCK_USER + START);
    const state = yield select();
    const { id, uuid: network_uuid } = currentNetworkSelector(state);
    const variable = {
      network_uuid,
      user_uuid,
      blocked: !is_blocked,
    };
    const { error } = yield urqlClient.mutation(UpdateNetworkUserIsBlocked, variable).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: BLOCK_USER + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: BLOCK_USER + SUCCESS });

    yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
  }
}

function* makeAdmin() {
  while (true) {
    const {
      payload: {
        user: { user_uuid, role },
        forAdmin,
      },
    } = yield take(MAKE_ADMIN + START);
    const state = yield select();
    const { id, uuid: network_uuid } = currentNetworkSelector(state);

    let updatedRole = undefined;
    if (forAdmin) {
      updatedRole = role === NETWORK_ADMIN_ROLE ? MEMBER_ROLE : NETWORK_ADMIN_ROLE;
    } else {
      updatedRole = role === NETWORK_MANAGER_ROLE ? MEMBER_ROLE : NETWORK_MANAGER_ROLE;
    }

    const variable = {
      network_uuid,
      user_uuid,
      role: updatedRole,
    };
    const { error } = yield urqlClient.mutation(UpdateNetworkUserRole, variable).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: MAKE_ADMIN + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: MAKE_ADMIN + SUCCESS });
    yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
  }
}

function* approveUser() {
  while (true) {
    const {
      payload: { user_uuid },
    } = yield take(APPROVE_USER + START);
    const state = yield select();
    const { id, uuid: network_uuid } = currentNetworkSelector(state);
    const variable = {
      network_uuid,
      user_uuid,
      approved: true,
    };
    const { error } = yield urqlClient.mutation(UpdateNetworkUserAdminApproved, variable).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: APPROVE_USER + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: APPROVE_USER + SUCCESS });

    yield put({ type: FETCH_NETWORK_BY_ID + START, payload: id });
  }
}

function* getNetworkAnalytics() {
  while (true) {
    const { payload } = yield take(FETCH_NETWORK_ANALYTICS + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { data, error } = yield urqlClient.query(GetAnalyticsData, { network_uuid, start_date: payload }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_NETWORK_ANALYTICS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_NETWORK_ANALYTICS + SUCCESS, payload: mapAnalytics(data) });
    }
  }
}

function* getPendingPosts() {
  while (true) {
    yield take(FETCH_PENDING_POSTS + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { data, error } = yield urqlClient.query(GetPendingPosts, { network_uuid }).toPromise();

    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_PENDING_POSTS + FAIL, payload: getError(error) });
    } else {
      yield put({ type: FETCH_PENDING_POSTS + SUCCESS, payload: data.content });
    }
  }
}

function* approvePost() {
  while (true) {
    const { payload: content_uuid } = yield take(APPROVE_PENDING_POST + START);
    const { error, data } = yield urqlClient.mutation(UpdateContentApproveStatus, { content_uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: APPROVE_PENDING_POST + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: APPROVE_PENDING_POST + SUCCESS, payload: data.result.uuid });
  }
}

function* declinePost() {
  while (true) {
    const {
      payload: { content_uuid, creator_uuid, reason, onSuccess },
    } = yield take(DECLINE_PENDING_POST + START);
    const state = yield select();
    const user = userSelector(state);
    const uuid = uuidv4();
    const variable = {
      content_uuid,
      uuid,
      reject: {
        uuid,
        reason,
        rejected_by: user.uuid,
        posted_by: creator_uuid,
      },
    };
    const { error } = yield urqlClient.mutation(UpdateContentReject, variable).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: DECLINE_PENDING_POST + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: DECLINE_PENDING_POST + SUCCESS, payload: content_uuid });
    onSuccess();
  }
}

function* fetchCategories() {
  while (true) {
    yield take(FETCH_CATEGORIES + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { error, data } = yield urqlClient.mutation(GetNetworkCategories, { network_uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: FETCH_CATEGORIES + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: FETCH_CATEGORIES + SUCCESS, payload: data.network_category });
  }
}

function* addHashtag() {
  while (true) {
    const {
      payload: { category_uuid, hashtag },
    } = yield take(ADD_HASHTAG + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { data } = yield urqlClient.query(GetHashtagByName, { hashtag, category_uuid, network_uuid }).toPromise();

    if (data.hashtag.length === 0) {
      // insert new hastag
      const uuid = uuidv4();
      const network_category_hashtag = [
        {
          category_uuid,
          hashtag_uuid: uuid,
          network_uuid,
        },
      ];
      const { error } = yield urqlClient.query(InsertNewHashtag, { uuid, hashtag, network_category_hashtag }).toPromise();
      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: ADD_HASHTAG + FAIL, payload: getError(error) });
        continue;
      }
    } else {
      // insert existing hashtag to category
      const hashtag_uuid = data.hashtag[0].uuid;
      const { error } = yield urqlClient.query(InsertHastagToCategory, { hashtag_uuid, category_uuid, network_uuid }).toPromise();
      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: ADD_HASHTAG + FAIL, payload: getError(error) });
        continue;
      }
    }
    yield put({ type: ADD_HASHTAG + SUCCESS, payload: true });
    yield put({ type: FETCH_CATEGORIES + START });
  }
}

function* deleteHashtag() {
  while (true) {
    const {
      payload: { hashtag_uuid, category_uuid },
    } = yield take(DELETE_HASHTAG + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { error } = yield urqlClient.mutation(DeleteCategoryHashtag, { hashtag_uuid, category_uuid, network_uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: DELETE_HASHTAG + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: DELETE_HASHTAG + SUCCESS, payload: true });
    yield put({ type: FETCH_CATEGORIES + START });
  }
}

function* addCategory() {
  while (true) {
    const {
      payload: { category, order },
    } = yield take(ADD_CATEGORY + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { data } = yield urqlClient.query(GetCategoryByName, { category }).toPromise();

    if (data.category.length === 0) {
      // insert new category
      const uuid = uuidv4();
      const network_category = {
        category_uuid: uuid,
        network_uuid,
        order,
      };
      const { error } = yield urqlClient.query(InsertNewCategory, { uuid, category, network_category }).toPromise();
      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: ADD_CATEGORY + FAIL, payload: getError(error) });
        continue;
      }
    } else {
      // insert existing hashtag to category
      const category_uuid = data.category[0].uuid;
      const { error } = yield urqlClient.query(InsertCategoryToNetwork, { category_uuid, order, network_uuid }).toPromise();
      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: ADD_CATEGORY + FAIL, payload: getError(error) });
        continue;
      }
    }
    yield put({ type: ADD_CATEGORY + SUCCESS, payload: true });
    yield put({ type: FETCH_CATEGORIES + START });
    yield put({ type: INIT_CATEGORY_ACTION });
  }
}

function* deleteCategory() {
  while (true) {
    const {
      payload: { category_uuid },
    } = yield take(DELETE_CATEGORY + START);
    const state = yield select();
    const { uuid: network_uuid } = currentNetworkSelector(state);
    const { error } = yield urqlClient.mutation(DeleteCategory, { category_uuid, network_uuid }).toPromise();
    if (error) {
      showErrorToast(getError(error).message);
      yield put({ type: DELETE_CATEGORY + FAIL, payload: getError(error) });
      continue;
    }
    yield put({ type: DELETE_CATEGORY + SUCCESS, payload: true });
    yield put({ type: FETCH_CATEGORIES + START });
    yield put({ type: INIT_CATEGORY_ACTION });
  }
}

function* archiveNetwork() {
  while (true) {
    const { payload } = yield take(ARCHIVE_NETWORK + START);
    try {
      const state = yield select();
      const currentNetwork = currentNetworkSelector(state);

      const variable = { network_uuid: currentNetwork.uuid, archived_at: payload ? new Date() : undefined };

      const { data, error } = yield urqlClient.query(UpdateNetworkArchive, variable).toPromise();

      if (error) {
        showErrorToast(getError(error).message);
        yield put({ type: ARCHIVE_NETWORK + FAIL, payload: getError(error) });
      } else {
        yield put({ type: ARCHIVE_NETWORK + SUCCESS, payload: data });
        yield put({ type: FETCH_NETWORK_BY_ID + START, payload: currentNetwork.id });
        yield put({ type: GET_USER + START });
      }
    } catch (error) {
      showErrorToast(getError(error).message);
      yield put({ type: ARCHIVE_NETWORK + FAIL, payload: getError(error) });
    }
  }
}

export function* networkSagas() {
  yield all([
    fork(getNetworks),
    fork(getAllNetworks),
    fork(getNetworkAccessRequests),
    fork(createNetwork),
    fork(reloadNetworks),
    fork(deleteNetwork),
    fork(getNetworkUsers),
    fork(updateNetworkSettings),
    fork(inviteMember),
    fork(inviteMemberBulk),
    fork(acceptAllRequests),
    fork(acceptMemberRequest),
    fork(declineMemberRequest),
    fork(declineMemberInvite),
    fork(blockUser),
    fork(makeAdmin),
    fork(approveUser),
    fork(getNetworkAnalytics),
    fork(getPendingPosts),
    fork(approvePost),
    fork(declinePost),
    fork(fetchCategories),
    fork(addHashtag),
    fork(deleteHashtag),
    fork(addCategory),
    fork(deleteCategory),
    fork(archiveNetwork),
  ]);
}
