import React from 'react';
import FlexRow from './FlexRow';
import DownArrow from '@app/assets/images/down-arrow.svg';
import useClickOutside from '@shared/hooks/useClickOutside';
import useKeyDown from '@shared/hooks/useKeyDown';

export const AnalyticsFilter = ({ filter, onChange = () => {}, options }) => {
  const popoverRef = React.useRef();
  const [isPopoverActive, setIsPopoverActive] = React.useState(false);

  const handleTogglePopover = (event) => {
    event.preventDefault();
    setIsPopoverActive(!isPopoverActive);
  };

  useKeyDown('Escape', () => setIsPopoverActive(false));

  useClickOutside(
    popoverRef,
    React.useCallback(() => setIsPopoverActive(false), []),
  );

  return (
    <div className="flex flex-row rounded-xl relative items-center justify-center w-28" ref={popoverRef}>
      <FlexRow className="bg-bg1 rounded-md items-center p-1 pl-2 cursor-pointer w-full" onClick={handleTogglePopover}>
        <span className="text-10 flex-1 ml-2 font-medium">{filter.label}</span>
        <img src={DownArrow} className="flex-shrink-0 xl:block" style={{ width: 21, height: 21 }} />
      </FlexRow>
      {isPopoverActive && (
        <div className="flex flex-col absolute top-full right-0 rounded-lg overflow-hidden w-full bg-white shadow-custom1 z-10">
          {options.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer py-2 px-5 text-10 font-medium text-black ${index !== options.length - 1 ? 'border-b' : ''}`}
              onClick={(e) => {
                onChange(item);
                handleTogglePopover(e);
              }}>
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
