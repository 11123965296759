import React, { useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { Section } from './Section';
import { userSelector } from '@app/store/session/selector';
import { showErrorToast } from '@app/utils';
import { mobileSelector } from '@app/store/mobile/selector';
import { LoadingView } from './LoadingVIew';
import { loadStripe } from '@stripe/stripe-js';
import { Env } from '@app/config';
import Button from './Button';
import FlexRow from './FlexRow';
import { Input } from './Input';
import FlexCol from './FlexCol';

const stripePromise = loadStripe(Env.STRIPE_PUBLIC_KEY);

const cardStyle = {
  base: {
    color: '#000',
    fontWeight: '500',
    fontSize: '18px',
  },
};

export const NetworkBillingPaymentSection = () => {
  const user = useSelector(userSelector);
  const [processing, setProcessing] = useState(false);
  const { data = {}, loading, selectedPlan: plan } = useSelector(mobileSelector);

  return (
    <Section title="Credit card" description="Your card on file will be used for monthly billing." style={{ padding: 28, borderRadius: 12 }}>
      <Elements stripe={stripePromise}>
        <CheckoutForm user={user} setProcessing={setProcessing} />
      </Elements>
      {(loading || processing) && <LoadingView className="absolute inset-0 bg-black bg-opacity-25" />}
    </Section>
  );
};

const CheckoutForm = ({ user, onSubscribe = () => {}, setProcessing = () => {} }) => {
  const [name, setName] = useState(user.name);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: user.name,
        email: user.email,
        phone: user.phone,
      },
    });
    setProcessing(false);
    if (error) {
      showErrorToast(error.message);
      return;
    }
    onSubscribe(paymentMethod);
    console.log({ error, paymentMethod });
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <FlexCol>
        <div className="text-xs text-label font-medium mb-2">Name on card</div>
        <Input
          className="p-3 max-w-sm bg-bg1 rounded-xl text-label"
          inputFieldClassName="w-full"
          value={name}
          placeholder="Full name"
          onChange={(e) => setName(e.target.value)}
        />
      </FlexCol>
      <FlexCol className="mt-8 max-w-md">
        <div className="text-xs text-label font-medium mb-2">Card</div>
        <CardElement className="w-full p-3 rounded-xl bg-bg1" options={{ style: cardStyle }} />
      </FlexCol>
      <FlexCol className="max-w-md mt-8">
        <div className="text-xs text-label font-medium mb-2">Country</div>
        <Input className="p-3 w-full bg-bg1 rounded-xl text-label" inputFieldClassName="w-full" disabled placeholder="Select country" />
      </FlexCol>
      <FlexCol className="max-w-md mt-4">
        <div className="text-xs text-label font-medium mb-2">Address</div>
        <Input className="p-3 w-full bg-bg1 rounded-xl text-label" inputFieldClassName="w-full" placeholder="" />
      </FlexCol>
      <FlexCol className="max-w-md mt-4">
        <div className="text-xs text-label font-medium mb-2">City</div>
        <Input className="p-3 w-full bg-bg1 rounded-xl text-label" inputFieldClassName="w-full" placeholder="" />
      </FlexCol>
      <FlexRow className="max-w-md mt-4">
        <FlexCol className="flex-1">
          <div className="text-xs text-label font-medium mb-2">State</div>
          <Input className="p-3 w-full bg-bg1 rounded-xl text-label" inputFieldClassName="w-full" placeholder="" />
        </FlexCol>
        <FlexCol className="flex-1 ml-4">
          <div className="text-xs text-label font-medium mb-2">Zip code</div>
          <Input className="p-3 w-full bg-bg1 rounded-xl text-label" inputFieldClassName="w-full" placeholder="" />
        </FlexCol>
      </FlexRow>
      <FlexRow className="mt-12">
        <Button label="Update Card" type="submit" disabled={!stripe || !elements} className="border rounded bg-primary px-8 py-1 text-white" />
        <Button label="Cancel" className="ml-4 text-sm text-label px-2 hover:shadow-none" />
      </FlexRow>
    </form>
  );
};
