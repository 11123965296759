import React from 'react';
import { useTable } from 'react-table';
import FlexRow from './FlexRow';

const defaultPropGetter = () => ({});

// Expose some prop getters for headers, rows and cells, or more if you want!
export const Table = ({
  className = '',
  heading,
  filter,
  rightHeading,
  subHeadings = [],
  action,
  columns,
  data,
  showColumnHeader = false,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = useTable({
    columns,
    data,
  });

  return (
    <div className={`w-full ${className}`}>
      <div className={`${rows.length && heading ? 'mb-3' : ''}`}>
        <FlexRow className="w-full justify-between">
          {heading && <span className="flex-1 font-medium text-15">{heading}</span>}
          {rightHeading}
        </FlexRow>

        <div className="sub-headings-wrapper">
          {subHeadings.map((subHeading, idx) => (
            <h3 key={idx}>{subHeading}</h3>
          ))}
        </div>

        {action && <div className="action-wrapper flex-shrink-0">{action}</div>}
      </div>

      {filter}

      <div className="container1">
        <table {...getTableProps()} className="w-full border-collapse">
          {showColumnHeader && (
            <thead className="bg-white rounded-xl shadow-custom1 py-1 overflow-hidden">
              {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, sIndex) => (
                    <th key={sIndex} {...column.getHeaderProps()} className="text-10 font-light ">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                // Merge user row props in
                <tr key={i} {...row.getRowProps(getRowProps(row))} className={`${i !== rows.length - 1 ? 'border-b' : ''}`}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        // Return an array of prop objects and react-table will merge them appropriately
                        key={j}
                        {...cell.getCellProps([
                          {
                            className: `text-11 leading-5 py-3 ${cell.column.className}`,
                            style: cell.column.style,
                          },
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
