import produce from 'immer';
import { combineActions, handleActions } from 'redux-actions';

import { FAIL, START, SUCCESS } from '../common';
import {
  ACCEPT_ALL_MEMBER_REQUESTS,
  ACCEPT_MEMBER_REQUEST,
  ADD_CATEGORY,
  ADD_HASHTAG,
  APPROVE_PENDING_POST,
  APPROVE_USER,
  BLOCK_USER,
  CREATE_NETWORK,
  DECLINE_MEMBER_INVITE,
  DECLINE_MEMBER_REQUEST,
  DECLINE_PENDING_POST,
  DELETE_CATEGORY,
  DELETE_HASHTAG,
  DELETE_NETWORK,
  FETCH_CATEGORIES,
  FETCH_NETWORKS,
  FETCH_NETWORK_ANALYTICS,
  FETCH_NETWORK_BY_ID,
  FETCH_NETWORK_REQUESTS,
  FETCH_PENDING_POSTS,
  INIT_CATEGORY_ACTION,
  INIT_CREATE_NETWORK,
  INIT_DELETE_NETWORK,
  INIT_INVITE_MEMBER,
  INVITE_MEMBER,
  INVITE_MEMBER_BULK,
  MAKE_ADMIN,
  SET_ACTIVE_NETWORK,
  UPDATE_NETWORK_SETTINGS,
  UPDATE_NETWORK_SEARCH,
  UPDATE_NETWORK_ARCHIVED_VISIBLE,
} from './actions';
import { GET_USER, LOG_OUT } from '../session/actions';

const initialState = {
  items: [],
  searchKey: '',
  hideArchived: false,
  selected: {},
  allNetworks: [],
  networkRequests: [],
  currentNetwork: {},
  createNetwork: {
    loading: false,
    success: false,
    error: null,
  },
  deleteNetwork: {
    loading: false,
    success: false,
    error: null,
  },
  networkSetting: {
    loading: false,
    success: false,
    error: null,
  },
  networkAnalytics: {
    loading: false,
    success: false,
    error: null,
    data: [],
  },
  inviteMember: {
    loading: false,
    success: false,
    error: null,
  },
  requestStatus: {
    loading: false,
    success: false,
    error: null,
  },
  actionStatus: {
    loading: false,
    success: false,
    error: null,
  },
  pendings: {
    items: [],
    loading: false,
    success: false,
    error: null,
  },
  declinePost: {
    loading: false,
    success: false,
    error: null,
  },
  category: {
    items: [],
    loading: false,
    success: false,
    error: null,
    categoryAction: {
      loading: false,
      success: false,
      error: null,
    },
  },
};

export const networkReducer = handleActions(
  {
    [LOG_OUT]: () => initialState,
    [GET_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const { networksById = [] } = payload;
        draft.items = Object.keys(networksById).map((key) => networksById[key]);
      }),
    [SET_ACTIVE_NETWORK]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected = payload;
      }),
    [FETCH_NETWORKS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.allNetworks = payload.network.map(({ uuid, name, id, network_users_aggregate, archived_at, network_users }) => ({
          id: uuid,
          intId: id,
          name,
          userCount: network_users_aggregate.aggregate.count,
          archived_at,
          admins: network_users.map(({ user: { uuid, name, email, user_profile } }) => ({
            id: uuid,
            name,
            email,
            image: user_profile.photo,
          })),
          // TODO deal with more than 3 admins in a network
        }));
      }),
    [FETCH_NETWORK_REQUESTS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.networkRequests = payload.network_access_request.map(
          ({ uuid, requester_name, requester_email, community_name, body, user_count_range }) => ({
            id: uuid,
            user: {
              name: requester_name,
              email: requester_email,
            },
            networkName: community_name,
            body,
            userCountRange: user_count_range
              .replace(/\[/g, '')
              .replace(/\)/g, '')
              .split(',')
              .map((numberString) => {
                if (numberString[numberString.length - 1] === '1') {
                  return Number(Number(numberString) - 1).toLocaleString();
                }

                return Number(numberString).toLocaleString();
              })
              .join(' - '),
          }),
        );
      }),
    [FETCH_NETWORK_BY_ID + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.currentNetwork = payload;
        draft.selected = payload;
      }),
    [INIT_CREATE_NETWORK]: (state) =>
      produce(state, (draft) => {
        draft.createNetwork.loading = false;
        draft.createNetwork.success = false;
        draft.createNetwork.error = null;
      }),
    [CREATE_NETWORK + START]: (state) =>
      produce(state, (draft) => {
        draft.createNetwork.loading = true;
        draft.createNetwork.success = false;
        draft.createNetwork.error = null;
      }),
    [CREATE_NETWORK + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.createNetwork.loading = false;
        draft.createNetwork.success = true;
        draft.createNetwork.error = null;
      }),
    [CREATE_NETWORK + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.createNetwork.loading = false;
        draft.createNetwork.success = false;
        draft.createNetwork.error = payload;
      }),
    [INIT_DELETE_NETWORK]: (state) =>
      produce(state, (draft) => {
        draft.deleteNetwork.loading = false;
        draft.deleteNetwork.success = false;
        draft.deleteNetwork.error = null;
      }),
    [DELETE_NETWORK + START]: (state) =>
      produce(state, (draft) => {
        draft.deleteNetwork.loading = true;
        draft.deleteNetwork.success = false;
        draft.deleteNetwork.error = null;
      }),
    [DELETE_NETWORK + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.deleteNetwork.loading = false;
        draft.deleteNetwork.success = true;
        draft.deleteNetwork.error = null;
      }),
    [DELETE_NETWORK + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.deleteNetwork.loading = false;
        draft.deleteNetwork.success = false;
        draft.deleteNetwork.error = payload;
      }),
    [UPDATE_NETWORK_SETTINGS + START]: (state) =>
      produce(state, (draft) => {
        draft.networkSetting.loading = true;
        draft.networkSetting.success = false;
        draft.networkSetting.error = null;
      }),
    [UPDATE_NETWORK_SETTINGS + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.networkSetting.loading = false;
        draft.networkSetting.success = true;
        draft.networkSetting.error = null;
      }),
    [UPDATE_NETWORK_SETTINGS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.networkSetting.loading = false;
        draft.networkSetting.success = false;
        draft.networkSetting.error = payload;
      }),
    [combineActions(INVITE_MEMBER + START, INVITE_MEMBER_BULK + START)]: (state) =>
      produce(state, (draft) => {
        draft.inviteMember.loading = true;
        draft.inviteMember.success = false;
        draft.inviteMember.error = null;
      }),
    [combineActions(INVITE_MEMBER + SUCCESS, INVITE_MEMBER_BULK + SUCCESS)]: (state) =>
      produce(state, (draft) => {
        draft.inviteMember.loading = false;
        draft.inviteMember.success = true;
        draft.inviteMember.error = null;
      }),
    [combineActions(INVITE_MEMBER + FAIL, INVITE_MEMBER_BULK + FAIL)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.inviteMember.loading = false;
        draft.inviteMember.success = false;
        draft.inviteMember.error = payload;
      }),
    [INIT_INVITE_MEMBER]: (state) =>
      produce(state, (draft) => {
        draft.inviteMember = initialState.inviteMember;
      }),
    [combineActions(
      ACCEPT_ALL_MEMBER_REQUESTS + START,
      ACCEPT_MEMBER_REQUEST + START,
      DECLINE_MEMBER_REQUEST + START,
      DECLINE_MEMBER_INVITE + START,
    )]: (state) =>
      produce(state, (draft) => {
        draft.requestStatus.loading = true;
        draft.requestStatus.success = false;
        draft.requestStatus.error = null;
      }),
    [combineActions(
      ACCEPT_ALL_MEMBER_REQUESTS + SUCCESS,
      ACCEPT_MEMBER_REQUEST + SUCCESS,
      DECLINE_MEMBER_REQUEST + SUCCESS,
      DECLINE_MEMBER_INVITE + SUCCESS,
    )]: (state) =>
      produce(state, (draft) => {
        draft.requestStatus.loading = false;
        draft.requestStatus.success = true;
        draft.requestStatus.error = null;
      }),
    [combineActions(ACCEPT_ALL_MEMBER_REQUESTS + FAIL, ACCEPT_MEMBER_REQUEST + FAIL, DECLINE_MEMBER_REQUEST + FAIL, DECLINE_MEMBER_INVITE + FAIL)]: (
      state,
      { payload },
    ) =>
      produce(state, (draft) => {
        draft.requestStatus.loading = false;
        draft.requestStatus.success = false;
        draft.requestStatus.error = payload;
      }),
    [combineActions(BLOCK_USER + START, MAKE_ADMIN + START, APPROVE_USER + START)]: (state) =>
      produce(state, (draft) => {
        draft.actionStatus.loading = true;
        draft.actionStatus.success = false;
        draft.actionStatus.error = null;
      }),
    [combineActions(BLOCK_USER + SUCCESS, MAKE_ADMIN + SUCCESS, APPROVE_USER + SUCCESS)]: (state) =>
      produce(state, (draft) => {
        draft.actionStatus.loading = false;
        draft.actionStatus.success = true;
        draft.actionStatus.error = null;
      }),
    [combineActions(BLOCK_USER + FAIL, MAKE_ADMIN + FAIL, APPROVE_USER + FAIL)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.actionStatus.loading = false;
        draft.actionStatus.success = false;
        draft.actionStatus.error = payload;
      }),
    [FETCH_NETWORK_ANALYTICS + START]: (state) =>
      produce(state, (draft) => {
        draft.networkAnalytics.loading = true;
        draft.networkAnalytics.success = false;
        draft.networkAnalytics.error = null;
      }),
    [FETCH_NETWORK_ANALYTICS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.networkAnalytics.loading = false;
        draft.networkAnalytics.success = true;
        draft.networkAnalytics.error = null;
        draft.networkAnalytics.data = payload;
      }),
    [FETCH_NETWORK_ANALYTICS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.networkAnalytics.loading = false;
        draft.networkAnalytics.success = false;
        draft.networkAnalytics.error = payload;
      }),
    [combineActions(FETCH_PENDING_POSTS + START, APPROVE_PENDING_POST + START)]: (state) =>
      produce(state, (draft) => {
        draft.pendings.loading = true;
        draft.pendings.success = false;
        draft.pendings.error = null;
      }),
    [FETCH_PENDING_POSTS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.pendings.loading = false;
        draft.pendings.success = true;
        draft.pendings.error = null;
        draft.pendings.items = payload;
      }),
    [combineActions(FETCH_PENDING_POSTS + FAIL, APPROVE_PENDING_POST + FAIL)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.pendings.loading = false;
        draft.pendings.success = false;
        draft.pendings.error = payload;
      }),
    [APPROVE_PENDING_POST + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.pendings.loading = false;
        draft.pendings.success = true;
        draft.pendings.error = null;
        draft.pendings.items = state.pendings.items.filter((content) => content.uuid !== payload);
      }),
    [DECLINE_PENDING_POST + START]: (state) =>
      produce(state, (draft) => {
        draft.declinePost.loading = true;
        draft.declinePost.success = false;
        draft.declinePost.error = null;
      }),
    [DECLINE_PENDING_POST + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.declinePost.loading = false;
        draft.declinePost.success = true;
        draft.declinePost.error = null;
        draft.pendings.items = state.pendings.items.filter((content) => content.uuid !== payload);
      }),
    [DECLINE_PENDING_POST + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.declinePost.loading = false;
        draft.declinePost.success = false;
        draft.declinePost.error = payload;
      }),
    [FETCH_CATEGORIES + START]: (state) =>
      produce(state, (draft) => {
        draft.category.loading = true;
        draft.category.success = false;
        draft.category.error = null;
      }),
    [FETCH_CATEGORIES + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.category.loading = false;
        draft.category.success = true;
        draft.category.error = null;
        draft.category.items = payload;
      }),
    [FETCH_CATEGORIES + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.category.loading = false;
        draft.category.success = false;
        draft.category.error = payload;
      }),
    [combineActions(ADD_HASHTAG + START, DELETE_HASHTAG + START)]: (state) =>
      produce(state, (draft) => {
        draft.category.loading = true;
      }),
    [combineActions(ADD_HASHTAG + SUCCESS, DELETE_HASHTAG + SUCCESS)]: (state) =>
      produce(state, (draft) => {
        draft.category.loading = false;
      }),
    [combineActions(ADD_HASHTAG + FAIL, DELETE_HASHTAG + FAIL)]: (state) =>
      produce(state, (draft) => {
        draft.category.loading = false;
      }),
    [combineActions(ADD_CATEGORY + START, DELETE_CATEGORY + START)]: (state) =>
      produce(state, (draft) => {
        draft.category.categoryAction.loading = true;
        draft.category.categoryAction.success = false;
        draft.category.categoryAction.error = null;
      }),
    [combineActions(ADD_CATEGORY + SUCCESS, DELETE_CATEGORY + SUCCESS)]: (state) =>
      produce(state, (draft) => {
        draft.category.categoryAction.loading = false;
        draft.category.categoryAction.success = true;
        draft.category.categoryAction.error = null;
      }),
    [combineActions(ADD_CATEGORY + FAIL, DELETE_CATEGORY + FAIL)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.category.categoryAction.loading = false;
        draft.category.categoryAction.success = false;
        draft.category.categoryAction.error = payload;
      }),
    [INIT_CATEGORY_ACTION]: (state) =>
      produce(state, (draft) => {
        draft.category.categoryAction.loading = false;
        draft.category.categoryAction.success = false;
        draft.category.categoryAction.error = null;
      }),
    [UPDATE_NETWORK_SEARCH]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.searchKey = payload;
      }),
    [UPDATE_NETWORK_ARCHIVED_VISIBLE]: (state) =>
      produce(state, (draft) => {
        draft.hideArchived = !state.hideArchived;
      }),
  },
  initialState,
);
