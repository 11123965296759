import React from 'react';
import { useSelector } from 'react-redux';

import FlexCol from '@app/components/FlexCol';
import FlexRow from '@app/components/FlexRow';
import { currentNetworkSelector } from '@app/store/network/selector';

export const NetworkUsersRequestPage = () => {
  const { network_membership_requests = [] } = useSelector(currentNetworkSelector);
  return (
    <FlexCol className="px-7 flex-1">
      {!!network_membership_requests.length && (
        <FlexCol>
          <div className="font-sans text-34 text-dark">Requests</div>
          <div className="text-base text-inactive">New people who want to join.</div>
        </FlexCol>
      )}
      {!network_membership_requests.length && (
        <FlexCol className="flex-0.7 items-center text-center justify-center">
          <FlexCol className="h-14 w-14 rounded-full border border-black justify-center items-center mb-6">
            <div className="transform rotate-45 text-2xl ml-1">⎦</div>
          </FlexCol>
          <div className="font-sans text-30">
            All good here. No
            <br />
            requests to approve.
          </div>
        </FlexCol>
      )}
      {network_membership_requests.map((item, index) => (
        <FlexRow key={index}>{item?.user?.name}</FlexRow>
      ))}
    </FlexCol>
  );
};
