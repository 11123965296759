const UpdateExceptionStatus = `
  mutation UpdateExceptionStatus($uuid: uuid!, $resolved: Boolean = true) {
    update_exception_by_pk(pk_columns: {uuid: $uuid}, _set: {resolved: $resolved}) {
      __typename
      uuid
    }
  }
`;

export default UpdateExceptionStatus;
