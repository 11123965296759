import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { BaseModal } from './BaseModal';

import { networkSelector } from '@app/store/network/selector';
import { isSuperAdminSelector } from '@app/store/session/selector';
import { setActiveNetworkAction } from '@app/store/network/actions';
import { useHistory } from 'react-router-dom';
import { logoutAction } from '@app/store/session/actions';

Modal.setAppElement('#root');

export const MainMenuModal = ({ isOpen, onClose, onCreate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, selected } = useSelector(networkSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const networks = isSuperAdmin ? [{ name: 'Super Admin' }, ...items] : items;
  const activeNetworks = networks.filter(({ archived_at }) => !archived_at);

  const onSelectNetwork = (item) => {
    onClose();
    dispatch(setActiveNetworkAction(item.id ? item : {}));
    if (item.id) {
      history.push(`/networks/${item.id}/members`);
    } else {
      history.push('/networks');
    }
  };

  const onLogout = () => {
    dispatch(logoutAction());
    history.push('/login');
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className="w-52 mx-4 my-5 p-4 bg-white rounded-xl shadow-xl">
      <div className="overflow-auto max-h-1/2-full">
        {activeNetworks.map((item, index) => (
          <div key={index} onClick={() => onSelectNetwork(item)}>
            <div className="p-2 text-sm flex flex-row items-center cursor-pointer hover:text-gray-400">
              <div className="flex-1 truncate w-full">{item.name}</div>
              <span>
                {selected.id === item.id && (
                  <div className="w-4 h-4 rounded-full bg-primary flex justify-center items-center">
                    <div className="transform rotate-45 text-white text-xs ml-0.5">⎦</div>
                  </div>
                )}
                {selected.id !== item.id && <div className="w-4 h-4 rounded-full border border-gray-300"></div>}
              </span>
            </div>
            <div className="w-full border-b mb-2 border-gray-200" />
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col mt-4 items-center text-center justify-center">
        {!!isSuperAdmin && (
          <button className="text-xs shadow-xl px-6 py-2" onClick={onCreate}>
            Create a new network
          </button>
        )}
        <button className="text-xs px-4 py-2 mt-4" onClick={onLogout}>
          Log out
        </button>
      </div>
    </BaseModal>
  );
};
