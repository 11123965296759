import mapDateTime from './mapDateTime';

const mapNetworkMembershipInvitation = ({ uuid, id, user_name, user_email, user_phone, created_at, updated_at, referrer }) => ({
  uuid,
  id,
  user: {
    name: user_name,
    email: user_email,
    phone: user_phone,
    referrer: { ...referrer },
  },
  createdAt: mapDateTime(created_at),
  updatedAt: mapDateTime(updated_at),
});

export default mapNetworkMembershipInvitation;
