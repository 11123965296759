import React from 'react';
import { Link } from 'react-router-dom';

export const ActionLink = ({ href, children, onClick = () => {} }) => {
  if (href[0] === '#') {
    const handleClick = (event) => {
      event.preventDefault();
      onClick();
    };

    return (
      <>
        <a className="w-full" href={href} onClick={handleClick}>
          {children}
        </a>
      </>
    );
  }

  return (
    <Link href={href}>
      <a className="w-full">{children}</a>
    </Link>
  );
};

export default ActionLink;
