import React, { useEffect, useState } from 'react';
import { HashtagItem } from './HashtagItem';

import { Input } from './Input';

const actionClassName =
  'border-2 font-bold text-xs border-gray-400 text-gray-400 rounded-full flex justify-center items-center w-5 h-5 ml-2 cursor-pointer';

export const NetworkCategorySection = ({
  category,
  onChangeOrder = () => {},
  onDeleteCategory = () => {},
  onAdd = () => {},
  onDeleteHashtag = () => {},
}) => {
  const [newTag, setNewTag] = useState('');
  useEffect(() => {
    setNewTag('');
  }, [category]);
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <p className="text-2xl font-semibold mb-2">{category.category}</p>
        <div className="flex flex-row">
          <div className={actionClassName} onClick={() => onChangeOrder({ up: true, category })}>
            <span>↑</span>
          </div>
          <div className={actionClassName} onClick={() => onChangeOrder({ up: false, category })}>
            <span>↓</span>
          </div>
          <div className={actionClassName + ' ml-6'} onClick={() => onDeleteCategory(category)}>
            <span>✕</span>
          </div>
        </div>
      </div>
      <div className="mb-8 grid grid-flow-row gap-1 md:gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        {category.tags.map(({ hashtag }, index) => (
          <HashtagItem key={index} hashtag={hashtag} onDelete={() => onDeleteHashtag(hashtag.uuid, category.uuid)} />
        ))}
        <Input
          className="text-base font-medium text-gray-800 h-auto border-gray-300 border rounded-xl"
          type="text"
          value={newTag}
          inputFieldClassName="p-3 bg-gray-100 text-base font-medium text-gray-800"
          onChange={({ target }) => setNewTag(target.value)}
          onBlur={({ target }) => onAdd(target.value)}
          onKeyDown={({ target, key }) => key === 'Enter' && onAdd(target.value)}
        />
      </div>
    </div>
  );
};
