import produce from 'immer';
import { combineActions, handleActions } from 'redux-actions';

import { FAIL, START, SUCCESS } from '../common';
import {
  CONNECT_STRIPE,
  GET_USER,
  GOTO_STRIPE_DASHBOARD,
  LOG_OUT,
  SET_SHOW_TUTORIAL,
  SUBMIT_CODE,
  SUBMIT_EMAIL,
  SUBMIT_PHONE,
  SUBMIT_PHONE_CODE,
} from './actions';

const initialState = {
  authorizing: false,
  loggedIn: false,
  loading: false,
  emailSuccess: false,
  phoneSuccess: false,
  phone: '',
  user: {},
  stripe: {
    loading: false,
    success: false,
    error: null,
  },
  showTutorial: true,
};

export const sessionReducer = handleActions(
  {
    [LOG_OUT]: (state) => ({ ...initialState, showTutorial: state.showTutorial }),
    [GET_USER + START]: (state) =>
      produce(state, (draft) => {
        draft.loggedIn = false;
        draft.authorizing = true;
      }),
    [GET_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loggedIn = true;
        draft.authorizing = false;
        draft.user = payload;
      }),
    [GET_USER + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.loggedIn = false;
        draft.authorizing = false;
      }),
    [SUBMIT_EMAIL + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.emailSuccess = false;
      }),
    [SUBMIT_EMAIL + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.emailSuccess = true;
      }),
    [SUBMIT_EMAIL + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.emailSuccess = false;
      }),
    [SUBMIT_CODE + START]: (state) =>
      produce(state, (draft) => {
        draft.authorizing = true;
        draft.loggedIn = false;
      }),
    [SUBMIT_CODE + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.authorizing = false;
        draft.loggedIn = false;
      }),
    [SUBMIT_PHONE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.phoneSuccess = false;
        draft.phone = payload.phone || state.phone;
      }),
    [SUBMIT_PHONE + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.phoneSuccess = true;
      }),
    [SUBMIT_PHONE + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.phoneSuccess = false;
      }),
    [SUBMIT_PHONE_CODE + START]: (state) =>
      produce(state, (draft) => {
        draft.authorizing = true;
        draft.loggedIn = false;
      }),
    [SUBMIT_PHONE_CODE + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.phoneSuccess = true;
      }),
    [SUBMIT_PHONE_CODE + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.authorizing = false;
        draft.loggedIn = false;
      }),
    [SET_SHOW_TUTORIAL]: (state) =>
      produce(state, (draft) => {
        draft.showTutorial = false;
      }),
    [combineActions(CONNECT_STRIPE + START, GOTO_STRIPE_DASHBOARD + START)]: (state) =>
      produce(state, (draft) => {
        draft.stripe.loading = true;
        draft.stripe.success = false;
        draft.stripe.error = null;
      }),
    [combineActions(CONNECT_STRIPE + SUCCESS, GOTO_STRIPE_DASHBOARD + SUCCESS)]: (state) =>
      produce(state, (draft) => {
        draft.stripe.loading = false;
        draft.stripe.success = true;
        draft.stripe.error = null;
      }),
    [combineActions(CONNECT_STRIPE + FAIL, GOTO_STRIPE_DASHBOARD + FAIL)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.stripe.loading = false;
        draft.stripe.success = false;
        draft.stripe.error = payload;
      }),
  },
  initialState,
);
