import React from 'react';

import { getLinkPreview } from 'link-preview-js';

export const ContentCardLink = ({ link }) => {
  const [image, setImage] = React.useState();
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [error, setError] = React.useState();
  React.useEffect(() => {
    setTitle('');
    setImage('');
    setDescription('');
    getLinkPreview(link, {
      timeout: 10000,
      headers: {},
    })
      .then((data) => {
        setImage(data.images[0] || data.favicons[1] || data.favicons[0]);
        setTitle(data.title || data.siteName);
        setDescription(data.description || data.url);
        setError(null);
      })
      .catch((e) => {
        setError(e);
      });
  }, [link]);

  if (error) {
    return (
      <a href={link} target="blank" className="mt-4 text-xs border rounded py-1 text-center w-32">
        View Link
      </a>
    );
  }

  return (
    <a className="mt-4 w-3/5 rounded-2xl overflow-hidden border border-gray-200" href={link} target="blank">
      <img resizeMode="cover" className="w-44 h-24" src={image} />
      <div className="p-2">
        <div className="text-xs font-semibold">{title}</div>
        <div className="text-tiny text-gray-500 truncate">{description}</div>
      </div>
    </a>
  );
};
