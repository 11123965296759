import * as React from 'react';

export const StripeIcon = (props) => {
  return (
    <svg width={256} height={256} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" {...props}>
      <defs>
        <linearGradient x1="100%" y1="58.356%" x2="0%" y2="0%" id="prefix__a">
          <stop stopColor="#2697D4" offset="0%" />
          <stop stopColor="#207BCB" offset="50%" />
          <stop stopColor="#2285CE" offset="100%" />
        </linearGradient>
        <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="prefix__b">
          <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={3.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.185125113 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path fill="url(#prefix__a)" d="M0 0h256v256H0z" />
      <path
        d="M139.688 111c-12.927-4.78-20.011-8.5-20.011-14.343 0-4.96 4.073-7.792 11.334-7.792 13.281 0 26.916 5.136 36.302 9.739l5.312-32.76c-7.438-3.542-22.667-9.385-43.74-9.385-14.874 0-27.27 3.895-36.124 11.156-9.21 7.614-13.99 18.594-13.99 31.875 0 24.083 14.698 34.354 38.604 43.031 15.406 5.49 20.542 9.386 20.542 15.406 0 5.844-4.96 9.208-13.99 9.208-11.157 0-29.572-5.489-41.615-12.572L77 187.677c10.27 5.844 29.395 11.864 49.23 11.864 15.76 0 28.864-3.718 37.718-10.801 9.916-7.792 15.052-19.302 15.052-34.177 0-24.615-15.052-34.886-39.313-43.562z"
        fill="#FFF"
        filter="url(#prefix__b)"
      />
    </svg>
  );
};
