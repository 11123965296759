import React from 'react';

import { BaseModal } from './BaseModal';
import Button from '../Button';
import { userSelector } from '@app/store/session/selector';
import { useDispatch, useSelector } from 'react-redux';
// import { useTour } from '@reactour/tour';
import { setShowTutorial } from '@app/store/session/actions';

export const WelcomeModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  // const { setIsOpen } = useTour();
  return (
    <BaseModal
      isOpen={isOpen}
      parentSelector={() => document.querySelector('#dashbaord')}
      className="rounded-xl shadow-xl bg-white m-auto max-w-md"
      overlayClassName="flex bg-opacity-80">
      <div className="flex flex-col justify-center p-8">
        <div className="font-sans text-3xl">Welcome to your new</div>
        <div className="font-sans text-3xl">social club headquarters.</div>
        <div className="mt-4 text-sm">
          {`Hi ${user?.name}`}
          <br />
          <br />
          {"We are thrilled you've joined Declaration at this early stage, You're our kind of people."}
          <br />
          <br />
          {'Right now the dashboard tools are only available on desktop, but we will have a mobile version later this year.'}
          <Button
            label="View dashbaord"
            className="mr-2 mt-5 text-xs p-4"
            onClick={() => {
              // setIsOpen(true);
              onClose();
              dispatch(setShowTutorial());
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};
