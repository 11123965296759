import React, { useEffect, useState } from 'react';

import { UserCell } from './UserCell';
import { NETWORK_ADMIN_ROLE } from '@shared/constants';
import { DoubleConfirmModal, UserDetailModal } from '@app/components/modals';
import { useDispatch, useSelector } from 'react-redux';
import { userActionStatusSelector } from '@app/store/network/selector';
import { blockUserAction, makeAdminAction } from '@app/store/network/actions';
import FlexRow from './FlexRow';
import { Input } from './Input';
import FlexCol from './FlexCol';
import SearchIcon from '@app/assets/images/searchIcon.svg';
import _ from 'lodash';
import verbalizeUserRole from '@shared/utils/verbalizeUserRole';

const filterSearchUser = (items = [], searchKey = '') => {
  const groups = _.groupBy(
    items.filter(
      ({ user }) =>
        user?.phone?.includes(searchKey) ||
        user?.name?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchKey.toLowerCase()),
    ),
    (item) => (item?.is_blocked ? 'blocked' : 'active'),
    (item) => (item?.role === NETWORK_ADMIN_ROLE ? 'admin' : 'member'),
  );
  return [
    ..._.sortBy(groups.active, 'user.name'),
    ..._.sortBy(groups.admin, 'user.name'),
    ..._.sortBy(groups.member, 'user.name'),
    ..._.sortBy(groups.blocked, 'user.name'),
  ];
};

export const MemberTable = ({ items, isSuperAdmin = false }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isBlockModalActive, setIsBlockModalActive] = useState(false);
  const [isUnblockModalActive, setIsUnblockModalActive] = useState(false);
  const [isPromoteModalActive, setIsPromoteModalActive] = useState(false);
  const [isDemoteModalActive, setIsDemoteModalActive] = useState(false);
  const [isPromoteManagerModalActive, setIsPromoteManagerModalActive] = useState(false);
  const [isDemoteManagerModalActive, setIsDemoteManagerModalActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { loading, success } = useSelector(userActionStatusSelector);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setIsBlockModalActive(false);
    setIsUnblockModalActive(false);
    setIsPromoteModalActive(false);
    setIsDemoteModalActive(false);
    setIsPromoteManagerModalActive(false);
    setIsDemoteManagerModalActive(false);
  }, [loading, success]);

  useEffect(() => {
    const item = items.find(({ user_uuid }) => selectedItem?.user_uuid === user_uuid);
    setSelectedItem(item);
  }, [items, selectedItem]);

  return (
    <FlexCol className="h-full flex-1 pt-8 pr-8">
      {/* <Table columns={columns} data={items} showColumnHeader /> */}
      <FlexRow className="items-center bg-white shadow-custom1 rounded-lg pl-5 pr-2 py-2.5 text-10 text-inactive">
        <div className="w-1/3 flex-shrink-0">Name</div>
        <div className="w-1/4 flex-shrink-0">Username</div>
        <div className="w-1/6 flex-shrink-0">Status</div>
        <div className="flex-full flex justify-end">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="max-w-170 w-full rounded-full h-6 items-center shadow-none flex bg-bg1"
            inputFieldClassName="search-box w-full text-black px-2"
            rightElement={<img src={SearchIcon} className="w-3 h-3" />}
          />
        </div>
      </FlexRow>
      <FlexCol className="py-4 h-full px-8 -mx-8 overflow-y-scroll">
        {filterSearchUser(items, search).map((item, index) => (
          <FlexRow key={index} className="items-center py-2 pl-5 mt-2 rounded-xl hover:shadow-custom1 text-dark text-11 member-table-row">
            <div className="w-1/3 flex-shrink-0">
              <UserCell
                nameClass={item?.is_blocked ? 'text-red-600' : ''}
                value={{ name: item?.user?.name, image: item?.user?.user_profile?.photo }}
                theme="secondary"
              />
            </div>
            <div className={'w-1/4 flex-shrink-0 ' + (item?.is_blocked ? 'text-red-600' : '')}>@{item?.user?.user_profile?.username}</div>
            <div className={'w-1/6 flex-shrink-0 ' + (item?.is_blocked ? 'text-red-600' : '')}>
              {item?.is_blocked ? 'Blocked' : verbalizeUserRole(item?.role) || 'status'}
            </div>
            <FlexRow className="flex-1 items-center justify-between">
              <FlexRow className={`flex-1 justify-end mr-4 items-center`}>
                <div
                  className="border px-2 text-11 cursor-pointer rounded text-label font-medium detail-button"
                  onClick={() => {
                    setShowDetails(true);
                    setSelectedItem(item);
                  }}>
                  View details
                </div>
              </FlexRow>
            </FlexRow>
          </FlexRow>
        ))}
      </FlexCol>
      {isBlockModalActive && selectedItem && (
        <DoubleConfirmModal
          isOpen={isBlockModalActive}
          heading="Block member"
          description={`Are you sure you want to block\n ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, block"
          isFetching={loading}
          onSubmit={() => dispatch(blockUserAction(selectedItem))}
          onCancel={() => setIsBlockModalActive(false)}
          onClose={() => setIsBlockModalActive(false)}
        />
      )}
      {isUnblockModalActive && selectedItem && (
        <DoubleConfirmModal
          isOpen={isUnblockModalActive}
          heading="Unblock member"
          description={`Are you sure you want to unblock\n ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, unblock"
          isFetching={loading}
          onSubmit={() => dispatch(blockUserAction(selectedItem))}
          onCancel={() => setIsUnblockModalActive(false)}
          onClose={() => setIsUnblockModalActive(false)}
        />
      )}
      {(isPromoteModalActive || isPromoteManagerModalActive) && selectedItem && (
        <DoubleConfirmModal
          isOpen={isPromoteModalActive || isPromoteManagerModalActive}
          heading={isPromoteModalActive ? 'Make admin' : 'Make Content moderator'}
          description={`Are you sure you want to promote ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, promote"
          isFetching={loading}
          onSubmit={() => dispatch(makeAdminAction({ user: selectedItem, forAdmin: isPromoteModalActive }))}
          onCancel={() => {
            setIsPromoteModalActive(false);
            setIsPromoteManagerModalActive(false);
          }}
          onClose={() => {
            setIsPromoteModalActive(false);
            setIsPromoteManagerModalActive(false);
          }}
        />
      )}
      {(isDemoteModalActive || isDemoteManagerModalActive) && selectedItem && (
        <DoubleConfirmModal
          isOpen={isDemoteModalActive || isDemoteManagerModalActive}
          heading="Demote member"
          description={`Are you sure you want to demote ${selectedItem.user.name || selectedItem.user.email}?`}
          submitLabel="Yes, demote"
          isFetching={loading}
          onSubmit={() => dispatch(makeAdminAction({ user: selectedItem, forAdmin: isDemoteModalActive }))}
          onCancel={() => {
            setIsDemoteModalActive(false);
            setIsDemoteManagerModalActive(false);
          }}
          onClose={() => {
            setIsDemoteModalActive(false);
            setIsDemoteManagerModalActive(false);
          }}
        />
      )}
      {showDetails && selectedItem && (
        <UserDetailModal
          isOpen={showDetails}
          item={selectedItem}
          isSuperAdmin={isSuperAdmin}
          setIsBlockModalActive={setIsBlockModalActive}
          setIsUnblockModalActive={setIsUnblockModalActive}
          setIsPromoteModalActive={setIsPromoteModalActive}
          setIsDemoteModalActive={setIsDemoteModalActive}
          setIsPromoteManagerModalActive={setIsPromoteManagerModalActive}
          setIsDemoteManagerModalActive={setIsDemoteManagerModalActive}
          onClose={() => {
            setShowDetails(false);
            setSelectedItem(null);
          }}
        />
      )}
    </FlexCol>
  );
};
