import React, { useState } from 'react';

import { Input } from './Input';
import { formatPhone } from '@shared/utils/formatPhone';
import { RadioButton } from './RadioButton';

export const MemberRegisterForm = ({ isFetching = false, onSubmit = () => {} }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ firstName, lastName, email: email.trim(), phone: phone.trim() });
  };

  return (
    <form className="w-full max-w-xs px-2" onSubmit={handleSubmit}>
      <div className="mb-10">
        <Input
          type="text"
          label="First name"
          placeholder="Enter your first name"
          isRequired
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
      </div>
      <div className="mb-10">
        <Input
          type="text"
          label="Last name"
          placeholder="Enter your last name"
          isRequired
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
      </div>
      <div className="mb-10">
        <Input type="email" label="Email address" placeholder="Enter your email" value={email} onChange={(event) => setEmail(event.target.value)} />
      </div>
      <div className="mb-10">
        <Input
          type="text"
          label="Phone number"
          placeholder="Enter your phone number"
          value={formatPhone(phone)}
          onChange={(event) => setPhone(formatPhone(event.target.value))}
        />
      </div>
      <div className="mb-10">
        <label>Mobile device</label>
        <div className="flex flex-row items-center my-2">
          <RadioButton label="iOS" checked />
          <RadioButton label="Android" className="pl-4" />
        </div>
        <div className="text-xs text-gray-400 leading-5">
          Android is not available at the moment, but reserve your early membership now and we will send your invitation when it’s released.
        </div>
      </div>
      <Input className="pb-24" label="Send text invitation" type="submit" isFetching={isFetching} />
    </form>
  );
};
