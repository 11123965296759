import React from 'react';

export const RadioButton = ({ checked = false, label = '', className = '', onClick = () => {} }) => {
  return (
    <div className={`flex flex-row items-center justify-center cursor-pointer ${className}`} onClick={onClick}>
      <div className="flex border rounded-full w-5 h-5 p-1 flex-shrink-0">
        <div className={`w-full h-full rounded-full ${checked ? 'bg-primary' : ''}`}></div>
      </div>
      <div className="text-sm pl-2">{label}</div>
    </div>
  );
};
