const DeleteCategoryHashtag = `
  mutation DeleteCategoryHashtag($hashtag_uuid: uuid!, $category_uuid: uuid!, $network_uuid: uuid!) {
    delete_network_category_hashtag_by_pk(
      hashtag_uuid: $hashtag_uuid,
      network_uuid: $network_uuid,
      category_uuid: $category_uuid)
    {
      hashtag_uuid
    }
}
`;

export default DeleteCategoryHashtag;
