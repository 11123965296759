import mapNetwork from './mapNetwork';
import mapDateTime from './mapDateTime';
import { BLOCKED_NETWORK_USER_RELATIONSHIP_TYPE, BLOCKED_ROLE, CONNECTED_NETWORK_USER_RELATIONSHIP_TYPE, PENDING_NETWORK_USER_RELATIONSHIP_TYPE } from '../constants';
const mapUserNetworkProfile = ({ uuid, id, problem_bio, solution_bio, created_at, updated_at }) => ({
  uuid,
  id,
  problemBio: problem_bio,
  solutionBio: solution_bio,
  createdAt: created_at && mapDateTime(created_at),
  updatedAt: updated_at && mapDateTime(updated_at),
});

const mapUserProfile = ({
  uuid,
  id,
  username,
  location,
  location_latitude_longitude,
  photo,
  personal_bio,
  educational_institution,
  date_of_birth,
  work_place,
  work_title,
  work_bio,
  created_at,
  updated_at,
}) => ({
  uuid,
  id,
  username,
  location: location || '',
  latitudeLongitude: location_latitude_longitude ? location_latitude_longitude.split(',').map((l) => Number(l.replace(/[\(\)]/g, ''))) : [NaN, NaN],
  photo,
  personalBio: personal_bio || '',
  educationalInstitution: educational_institution || '',
  workPlace: work_place || '',
  workTitle: work_title || '',
  workBio: work_bio || '',
  dateOfBirth: date_of_birth && mapDateTime(date_of_birth),
  createdAt: created_at && mapDateTime(created_at),
  updatedAt: updated_at && mapDateTime(updated_at),
});

const mapReferrer = ({ uuid, name, email, phone, user_profile = {} }) => ({
  uuid,
  name,
  email,
  phone,
  profile: mapUserProfile(user_profile),
});
const mapUser = ({
  uuid,
  id,
  name = '',
  email,
  phone,
  created_at,
  updated_at,
  last_seen_at,
  super_admin = null,
  referrer,
  role,
  push_tokens = [],
  user_profile = {},
  network_user_profiles = [],
  network_users = [],
  to_network_user_relationships = [],
  from_network_user_relationships = [],
  stripe_merchants = [],
  user_hashtags = [],
  notification_setting,
}) => {
  const relationship = { to: to_network_user_relationships[0], from: from_network_user_relationships[0] };
  return {
    uuid,
    id,
    name: name || '',
    email,
    phone,
    referrer: mapReferrer(referrer || {}),
    push_tokens,
    createdAt: mapDateTime(created_at),
    updatedAt: mapDateTime(updated_at),
    last_seen_at: mapDateTime(last_seen_at),
    networkUuids: network_users.map(({ network }) => network.uuid),
    networkIds: network_users.map(({ network }) => network.id),
    networksByUuid: network_users.reduce((accumulator, { network }) => {
      accumulator[network.uuid] = mapNetwork(network);
      return accumulator;
    }, {}),
    networksById: network_users.reduce((accumulator, { network }) => {
      accumulator[network.id] = mapNetwork(network);
      return accumulator;
    }, {}),
    profile: mapUserProfile(user_profile || {}),
    profilesByNetworkUuid: network_user_profiles.reduce((accumulator, { network, ...network_user_profile }) => {
      accumulator[network.uuid] = mapUserNetworkProfile(network_user_profile || {});
      return accumulator;
    }, {}),
    profilesByNetworkId: network_user_profiles.reduce((accumulator, { network, ...network_user_profile }) => {
      accumulator[network.id] = mapUserNetworkProfile(network_user_profile || {});
      return accumulator;
    }, {}),
    role: role,
    rolesByNetworkUuid: network_users.reduce((accumulator, { network, role, is_blocked }) => {
      accumulator[network.uuid] = is_blocked ? BLOCKED_ROLE : role;
      return accumulator;
    }, {}),
    rolesByNetworkId: network_users.reduce((accumulator, { network, is_blocked, role }) => {
      accumulator[network.id] = is_blocked ? BLOCKED_ROLE : role;
      return accumulator;
    }, {}),
    isBlockedByNetworkUuid: network_users.reduce((accumulator, { network, is_blocked }) => {
      accumulator[network.uuid] = is_blocked;
      return accumulator;
    }, {}),
    isBlockedByNetworkId: network_users.reduce((accumulator, { network, is_blocked }) => {
      accumulator[network.id] = is_blocked;
      return accumulator;
    }, {}),
    isSuperAdmin: !!super_admin,
    connected: !!(
      from_network_user_relationships.find((item) => item.type === CONNECTED_NETWORK_USER_RELATIONSHIP_TYPE) ||
      to_network_user_relationships.find((item) => item.type === CONNECTED_NETWORK_USER_RELATIONSHIP_TYPE)
    ),
    isMyPending: relationship && relationship.to && relationship.to.type === PENDING_NETWORK_USER_RELATIONSHIP_TYPE,
    isIncomingPending: relationship && relationship.from && relationship.from.type === PENDING_NETWORK_USER_RELATIONSHIP_TYPE,
    isMyBlocked: relationship && relationship.from && relationship.from.type === BLOCKED_NETWORK_USER_RELATIONSHIP_TYPE,
    isFromBlocked: relationship && relationship.to && relationship.to.type === BLOCKED_NETWORK_USER_RELATIONSHIP_TYPE,
    relationShip: relationship,
    stripe_merchants,
    user_hashtags,
    notification_setting,
  };
};

export default mapUser;
