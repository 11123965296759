import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, ContentCard, LoadingView, ModerationNavContainer } from '@app/components';
import { approvePendingPostAction, fetchPendingPostsAction, fetchUsersByNetworkAction } from '@app/store/network/actions';
import { currentNetworkSelector, pendingPostsSelector } from '@app/store/network/selector';

import EMPTY_BACKGROUND from '@app/assets/images/pending-empty-background.png';
import { PostDeclineModal } from '@app/components/modals/PostDeclineModal';
import FlexRow from '@app/components/FlexRow';

export const NetworkPendingPage = () => {
  const dispatch = useDispatch();
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const { uuid: network_uuid } = useSelector(currentNetworkSelector);
  const { loading, items = [], success } = useSelector(pendingPostsSelector);
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const { id: networkId } = useParams();
  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  useEffect(() => {
    if (items.length > 0 && !selectedItem) {
      setTimeout(() => {
        setSelectedItem(items[0]);
      });
    }
  }, [items, selectedItem]);

  useEffect(() => {
    if (!loading && success) {
      setSelectedItem(null);
    }
  }, [loading, success]);

  useEffect(() => {
    if (network_uuid) {
      dispatch(fetchPendingPostsAction());
    }
  }, [dispatch, network_uuid]);

  const onApprove = () => {
    if (network_uuid && selectedItem) {
      dispatch(approvePendingPostAction(selectedItem.uuid));
    }
  };

  return (
    <>
      <FlexRow className="h-full flex-1">
        <ModerationNavContainer
          title="Moderation"
          subtitle="Filter the quality of content in your space."
          items={items}
          selectedItem={selectedItem}
          onChangeItem={(value) => setSelectedItem(value)}
        />
        <FlexRow className="flex-1 items-center overflow-hidden overflow-y-scroll p-7 bg-white rounded-xl my-8 mr-8 shadow-custom1">
          {!!selectedItem && (
            <FlexRow className="flex-1 justify-center items-center">
              <FlexRow className="justify-center flex-1">
                <Button label="Decline" className="w-32 py-2.5 bg-bg3 text-xs text-inactive" onClick={() => setShowDeclineModal(true)} />
              </FlexRow>
              <ContentCard selectedItem={selectedItem} />
              <FlexRow className="justify-center flex-1">
                <Button label="Approve" className="w-32 bg-primary-green mr-3 py-2.5 text-xs text-white" onClick={() => onApprove()} />
              </FlexRow>
            </FlexRow>
          )}
          {!selectedItem && (
            <div className="h-full flex-1 flex flex-col justify-center items-center">
              <img alt="empty-image" className="w-48 object-cover" src={EMPTY_BACKGROUND} />
            </div>
          )}
        </FlexRow>
      </FlexRow>
      {showDeclineModal && selectedItem && (
        <PostDeclineModal
          isOpen={showDeclineModal}
          content={selectedItem}
          onSuccess={() => {
            setSelectedItem(null);
            setShowDeclineModal(false);
          }}
          onClose={() => setShowDeclineModal(false)}
        />
      )}
      {loading && <LoadingView className="border absolute inset-0 bg-black bg-opacity-10" />}
    </>
  );
};
