import * as React from 'react';

function MenuIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M1.463 2.106a1.462 1.462 0 100 2.923h21.074a1.462 1.462 0 100-2.923H1.463zm0 7.892a1.462 1.462 0 100 2.923h21.074a1.462 1.462 0 100-2.923H1.463zm0 7.892a1.462 1.462 0 100 2.924h21.074a1.462 1.462 0 100-2.924H1.463z" />
    </svg>
  );
}

export default MenuIcon;
