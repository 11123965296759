import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const BaseModal = ({ isOpen, children, onClose, className = '', overlayClassName = '', parentSelector }) => {
  return (
    <Modal
      isOpen={isOpen}
      parentSelector={parentSelector ? parentSelector : () => document.querySelector('#root')}
      onRequestClose={onClose}
      className={`relative ${className}`}
      overlayClassName={`bg-black bg-opacity-40 absolute inset-0 ${overlayClassName}`}
      contentLabel="Example Modal">
      {children}
    </Modal>
  );
};
