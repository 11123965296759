import produce from 'immer';
import { handleActions } from 'redux-actions';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_LOGS, UPDATE_EXCEPTION_STATUS, UPDATE_LOG_FILTER } from './actions';
import { LOG_OUT } from '../session/actions';
import { groupBy } from '@shared/utils/groupBy';

const initialState = {
  items: [],
  builds: ['All'],
  networks: ['All'],
  resolvedStatus: [
    { label: 'Not resolved', value: false },
    { label: 'Resolved', value: true },
  ],
  filter: {
    search: '',
    resolved: false,
    isMobile: true,
  },
  loading: false,
  success: false,
  error: null,
};

export const logsReducer = handleActions(
  {
    [LOG_OUT]: () => initialState,
    [FETCH_LOGS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [FETCH_LOGS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.items = payload;
        draft.error = null;
        draft.builds = ['All', ...Object.keys(groupBy(payload, 'build_version'))];
        draft.filter.build = draft.builds[0];
        draft.platforms = ['All', ...Object.keys(groupBy(payload, 'device'))];
        draft.filter.platform = draft.platforms[0];
        draft.networks = [
          'All',
          ...Object.keys(
            groupBy(
              payload.map(({ network }) => network).filter((item) => !!item),
              'name',
            ),
          ),
        ];
        draft.filter.network = draft.networks[0];
      }),
    [FETCH_LOGS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [UPDATE_LOG_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter[payload.key] = payload.value;
      }),
    [UPDATE_EXCEPTION_STATUS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = state.items.filter(({ uuid }) => uuid !== payload);
      }),
  },
  initialState,
);
