import React, { useMemo } from 'react';

import { UserCell } from './UserCell';
// import { ActionMenu } from './ActionMenu';
import { Table } from './Table';
import { formatDate } from '@shared/utils/formatDate';
import { formatPhone } from '@shared/utils/formatPhone';
import FlexRow from './FlexRow';
import { Button } from '.';

export const NetworkMembershipRequestTable = ({ items, action, onAccept = () => {}, onDecline = () => {} }) => {
  const heading = items.length ? `${items.length} New member requests` : 'No requested members';
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: ({ user: { name, user_profile } }) => ({
          name,
          image: user_profile && user_profile.photo,
        }),
        Cell: ({ value }) => <UserCell value={value} />,
        style: {
          width: '176px',
        },
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        style: {
          width: '213px',
        },
      },
      {
        Header: 'Phone',
        accessor: 'user.phone',
        Cell: ({ value }) => formatPhone(value),
        style: {
          width: '213px',
        },
      },
      {
        Header: 'Received at',
        accessor: 'created_at',
        // Cell: ({ value }) => formatDateTime(value)
        Cell: ({ value }) => {
          return `Sent ${formatDate(value)}`;
        },
      },
      {
        Header: 'Body',
        accessor: 'body',
      },
      {
        id: 'actions',
        accessor: (item) => item,
        Cell: ({ value }) => (
          <FlexRow className="justify-end items-center text-center">
            <Button
              type="submit"
              label="Accept"
              className="w-16 bg-primary-green shadow-custom1 py-2 mr-3"
              labelClassName="text-white text-xs"
              onClick={() => onAccept({ item: value })}
            />
            <Button
              type="submit"
              label="Decline"
              className="w-16 bg-white shadow-custom1 py-2"
              labelClassName="text-label text-xs"
              onClick={() => onDecline({ item: value })}
            />
          </FlexRow>
        ),
      },
    ],
    [onAccept, onDecline],
  );

  return <Table heading={heading} columns={columns} data={items} action={action} isCollapsible />;
};
