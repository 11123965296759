import { createSelector } from 'reselect';

const getLogsState = (state) => state.logs;

export const logSelector = createSelector([getLogsState], (logs) => logs);
export const logFilterSelector = createSelector([getLogsState], ({ filter }) => filter);
export const logItemsSelector = createSelector([logSelector, logFilterSelector], ({ items }, filter) => {
  return items
    .filter(({ build_version }) => (filter.build === 'All' ? true : filter.build === build_version))
    .filter(({ network }) => (filter.network === 'All' ? true : network && filter.network === network.name))
    .filter(
      ({ message, user = {} }) =>
        message.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()) ||
        (user && user.name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase())) ||
        (user && user.email.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase())),
    );
});
