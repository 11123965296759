import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';

import { BaseModal } from './BaseModal';
import { Input } from '../Input';
import MobileDownArrow from '@app/assets/images/down-arrow.svg';
import { inviteMemberAction, initInviteMemberAction } from '@app/store/network/actions';
import { inviteMemberSelector } from '@app/store/network/selector';
import FlexRow from '../FlexRow';
import Button from '../Button';
import FlexCol from '../FlexCol';

export const InviteMemberModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(inviteMemberSelector);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isEmailInvite, setIsEmailInvite] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setName('');
      setEmail('');
      setPhone('');
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    // if (!loading && success) {
    //   onClose();
    // }
  }, [loading, onClose, success]);

  //
  // TODO... ADD PHONE BACK IN AS PART OF "TWO SMS MESSAGES SENT" TWILIO CARD
  //
  //
  // const onChangePhone = (value) => {
  //   const newValue = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //   setPhone(!newValue[2] ? newValue[1] : '(' + newValue[1] + ') ' + newValue[2] + (newValue[3] ? '-' + newValue[3] : ''));
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      inviteMemberAction({
        name,
        email,
        user_phone: phone || '',
      }),
    );
  };

  // <div className="input-row">
  //    <Input type="text" value={phone} label="Member phone" onChange={(event) => onChangePhone(event.target.value)} placeholder="Add phone" />
  // </div>

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} className="w-full h-full flex flex-col bg-white" overlayClassName="flex">
      <FlexRow className="p-7" onClick={onClose}>
        <img src={MobileDownArrow} className="rotate-90 transform cursor-pointer w-8 h-8" />
      </FlexRow>
      <form className="px-7 relative flex-1 flex flex-col" onSubmit={handleSubmit}>
        <p className="font-sans text-34">Send an invite</p>
        <FlexRow className="w-full mt-8 mb-2 shadow-custom1 bg-bg1 rounded-xl overflow-hidden">
          <Button
            label="Email"
            type="button"
            onClick={() => setIsEmailInvite(true)}
            className={`flex-full py-3 text-label text-sm ${isEmailInvite ? 'bg-white' : ''}`}
          />
          <Button
            label="Mobile"
            type="button"
            onClick={() => setIsEmailInvite(false)}
            className={`flex-full py-3 text-label text-sm ${!isEmailInvite ? 'bg-white' : ''}`}
          />
        </FlexRow>
        <FlexCol className="mt-8">
          <div className="text-sm text-label">Member fullname</div>
          <Input
            type="text"
            className="p-5 bg-white shadow-custom1 rounded-xl mt-2"
            value={name}
            isRequired
            onChange={(event) => setName(event.target.value)}
            placeholder="Fullname"
          />
        </FlexCol>
        {isEmailInvite && (
          <FlexCol className="mt-8">
            <div className="text-sm text-label">Email address</div>
            <Input
              type="email"
              className="p-5 bg-white shadow-custom1 rounded-xl mt-2"
              isRequired
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email address"
            />
          </FlexCol>
        )}
        {!isEmailInvite && (
          <FlexCol className="mt-8">
            <div className="text-sm text-label">Mobile Phone</div>
            <PhoneInput
              className="px-5 py-2 bg-white shadow-custom1 rounded-xl mt-2"
              placeholder="Phone"
              defaultCountry="US"
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </FlexCol>
        )}
        <div
          style={{
            color: 'red',
            fontSize: 16,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
          }}>
          {error && error.message}
        </div>
        <FlexRow className="justify-center mt-8">
          <Button label="Send an invite" className="bg-primary-green px-8 py-3 rounded-xl text-white" type="submit" isFetching={loading} />
        </FlexRow>
        {success && (
          <FlexCol className="absolute inset-0 justify-center bg-white p-7 items-center">
            <FlexCol className="h-14 w-14 rounded-full border border-black justify-center items-center mb-6">
              <div className="transform rotate-45 text-2xl ml-1">⎦</div>
            </FlexCol>
            <div className="font-sans text-30 text-center">
              Success! Your invite
              <br />
              has been sent.
            </div>
            <Button
              label="Send another"
              className="bg-primary-green px-8 py-3 mt-8 rounded-xl text-white"
              onClick={(e) => {
                e.preventDefault();
                dispatch(initInviteMemberAction());
              }}
            />
          </FlexCol>
        )}
      </form>
    </BaseModal>
  );
};
