import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isSuperAdminSelector } from '@app/store/session/selector';
import { fetchLogsAction, updateExceptionStatusAction } from '@app/store/logs/actions';
import { DashboardContainer, LogTable } from '@app/components';
import { logItemsSelector } from '@app/store/logs/selector';

export const Logs = () => {
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const items = useSelector(logItemsSelector);

  useEffect(() => {
    dispatch(fetchLogsAction());
  }, [dispatch, isSuperAdmin]);

  if (!isSuperAdmin) {
    return <Redirect to={`/networks`} />;
  }

  return (
    <DashboardContainer className="px-5 py-4">
      <LogTable items={items} onResolve={({ uuid }) => dispatch(updateExceptionStatusAction(uuid))} />
    </DashboardContainer>
  );
};
