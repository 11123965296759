import { createAction } from 'redux-actions';
import { START } from '../common';

export const FETCH_LOGS = 'FETCH_LOGS';
export const UPDATE_LOG_FILTER = 'UPDATE_LOG_FILTER';
export const UPDATE_EXCEPTION_STATUS = 'UPDATE_EXCEPTION_STATUS';

export const fetchLogsAction = createAction(FETCH_LOGS + START);
export const updateLogFilterAction = createAction(UPDATE_LOG_FILTER);
export const updateExceptionStatusAction = createAction(UPDATE_EXCEPTION_STATUS + START);
