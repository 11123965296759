export * from './storage';

export const Env = {
  PORT: process.env.PORT,
  HASURA_BASE_URL: process.env.REACT_APP_HASURA_BASE_URL,
  REST_BASE_URL: process.env.REACT_APP_REST_BASE_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  MOBILE_BASE_URL: process.env.REACT_APP_MOBILE_BASE_URL,
  CLOUDINARY_NAME: process.env.REACT_APP_CLOUDINARY_NAME,
  CLOUDINARY_PRESET: process.env.REACT_APP_CLOUDINARY_PRESET,
  JWT_SECRET_KEY: process.env.REACT_APP_JWT_SECRET_KEY,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export const AppConfig = {
  title: 'Declaration',
};
