import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DashboardContainer, NetworkCategorySection, Section } from '@app/components';
import {
  addHashTagAction,
  deleteCategoryAction,
  deleteHashtagAction,
  fetchCategoriesAction,
  fetchUsersByNetworkAction,
} from '@app/store/network/actions';
import { categorySelector, currentNetworkSelector } from '@app/store/network/selector';
import { useMutation } from 'urql';
import UpdateNetworkCategoryOrders from '@app/mutations/UpdateNetworkCategoryOrders';
import { getError, showErrorToast } from '@app/utils';
import { AddCategoryModal, DoubleConfirmModal } from '@app/components/modals';

const description =
  'Add categories and relevant tags to your space. These tags are used to improve member matching,\ncreating subgroups, and content will be delivered to members who have shard interests.';

export const NetworkInterestsPage = () => {
  const dispatch = useDispatch();
  const { uuid: network_uuid } = useSelector(currentNetworkSelector);
  const { items, loading, categoryAction } = useSelector(categorySelector);
  const { id: networkId } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [{ fetching: updating }, updateCategoryOrder] = useMutation(UpdateNetworkCategoryOrders);

  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);

  useEffect(() => {
    if (network_uuid) {
      dispatch(fetchCategoriesAction());
    }
  }, [dispatch, network_uuid]);

  useEffect(() => {
    if (!categoryAction.loading && categoryAction.success) {
      setSelectedCategory(null);
    }
  }, [categoryAction]);

  const onChangeOrder = ({ up, index }) => {
    if (index !== 0 && up) {
      updateCategoryOrder({
        object: items.map((item, sIndex) => ({
          category_uuid: item.category.uuid,
          network_uuid,
          order: sIndex === index + 1 ? sIndex : sIndex === index ? sIndex - 1 : sIndex === index - 1 ? sIndex + 1 : sIndex,
        })),
      }).then(({ error }) => {
        if (error) {
          showErrorToast(getError(error).message);
          return;
        }
        dispatch(fetchCategoriesAction());
      });
    } else if (index !== items.length - 1 && !up) {
      updateCategoryOrder({
        object: items.map((item, sIndex) => ({
          category_uuid: item.category.uuid,
          network_uuid,
          order: sIndex < index ? sIndex : sIndex === index ? sIndex + 1 : sIndex === index + 1 ? sIndex - 1 : sIndex,
        })),
      }).then(({ error }) => {
        if (error) {
          showErrorToast(getError(error).message);
          return;
        }
        dispatch(fetchCategoriesAction());
      });
    }
  };

  const onAdd = ({ value, category }) => {
    if (!value) {
      return;
    }
    if (value.length < 2 || value.length > 20) {
      showErrorToast('Hastag length must be between 3 and 20');
      return;
    }
    if (category.tags.map(({ hashtag }) => hashtag.text.toLowerCase()).includes(value.toLowerCase())) {
      showErrorToast('Hastag already exists.');
      return;
    }
    dispatch(addHashTagAction({ category_uuid: category.uuid, hashtag: value }));
  };

  const onDeleteHashtag = (hashtag_uuid, category_uuid) => {
    dispatch(deleteHashtagAction({ hashtag_uuid, category_uuid }));
  };

  const onDeleteCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <DashboardContainer className="px-5 py-4">
      <div className="p-6">
        <div className="row relative">
          <Section title="Categories of interest" loading={loading || updating} description={description} style={{ paddingBottom: 40 }}></Section>
        </div>
        <div className="row">
          {items.map(({ category, order }, index) => (
            <NetworkCategorySection
              category={category}
              key={index}
              onAdd={(value) => onAdd({ value, category })}
              onDeleteHashtag={onDeleteHashtag}
              onDeleteCategory={onDeleteCategory}
              onChangeOrder={(value) => onChangeOrder({ ...value, order, index })}
            />
          ))}
        </div>
        <div className="row mt-4">
          <Button
            label="+ Add section"
            className="text-black bg-white text-base shadow-custom1 py-2 rounded-xl"
            size="large"
            onClick={() => setShowAddModal(true)}
          />
        </div>
      </div>
      {showAddModal && (
        <AddCategoryModal isOpen={showAddModal} categories={items.map(({ category }) => category.category)} onClose={() => setShowAddModal(false)} />
      )}
      {!!selectedCategory && (
        <DoubleConfirmModal
          isOpen={!!selectedCategory}
          onClose={() => setSelectedCategory(null)}
          isFetching={categoryAction.loading}
          onCancel={() => setSelectedCategory(null)}
          onSubmit={() => dispatch(deleteCategoryAction({ category_uuid: selectedCategory.uuid }))}
          heading="Confirm"
          description={`Are you sure want to delete the "${selectedCategory.category}" section?`}
          submitLabel="Delete"
        />
      )}
    </DashboardContainer>
  );
};
