const InsertNetworkMembershipInvitations = `
  mutation InsertNetworkMembershipInvitations(
    $data: [network_membership_invitation_insert_input!]!
  ) {
    insert_network_membership_invitation(objects: $data) {
      __typename
    }
  }
`;

export default InsertNetworkMembershipInvitations;
