import { Env } from '@app/config';
import { Cloudinary } from '@cloudinary/base';
import { Resize } from '@cloudinary/base/actions/resize';
import { imageAxios } from './axiosInstances';

const urlReg = /(http|https|file):\/\/[^ "]+$/;

const cld = new Cloudinary({
  cloud: {
    cloudName: Env.CLOUDINARY_NAME,
  },
  url: {
    secure: true,
  },
});

export const CloudImage = (publicId, options = {}) => {
  if (!publicId) {
    return undefined;
  }
  if (publicId && urlReg.test(publicId)) {
    return publicId;
  }
  const myImage = cld
    .image(publicId)
    .addFlag('progressive')
    .resize(
      Resize.fill()
        .width(Math.ceil(options.width || 100))
        .height(Math.ceil(options.height || 100))
        .addQualifier('q_auto')
        .addQualifier('f_auto'),
    );
  return myImage.toURL();
};

export const CloudVideo = (publicId, options = {}) => {
  if (publicId && urlReg.test(publicId)) {
    return publicId;
  }
  const myVideo = cld.video(publicId);
  // myVideo.addFlag('progressive');
  myVideo.resize(
    Resize.fill()
      .width(Math.ceil(options.width || 100))
      .height(Math.ceil(options.height || 100))
      .addQualifier('q_auto')
      .addQualifier('f_auto'),
  );
  if (options.short) {
    myVideo.resize(Resize.fill().addQualifier('so_0').addQualifier('du_3'));
  }
  return myVideo.toURL();
};

export const CloudUploadMedia = async (file, options = { folder: '', onUploadProgress: () => {} }) => {
  const formData = new FormData();
  const type = 'image';

  formData.append('Content-Type', 'image');

  formData.append('file', file);
  if (options.folder) {
    formData.append('folder', options.folder);
  }
  formData.append('upload_preset', Env.CLOUDINARY_PRESET);

  try {
    const { data } = await imageAxios.put('', formData, {
      onUploadProgress: (progress) => {
        if (options.onUploadProgress) {
          options.onUploadProgress(Math.round((progress.loaded * 100) / progress.total));
        }
      },
    });
    return Promise.resolve({ url: data.public_id, type });
  } catch (error) {
    return Promise.resolve({ error: 'File upload failed. Max file size 40MB' });
  }
};
