import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageContainer } from '@app/components';

import WELCOME_IMAGE from '@shared/images/dashboard-background.png';
import CLOSE_ICON from '@app/components/CloseIcon';
import MESSAGE_ICON from '@app/components/MessageIcon';
import { useParams } from 'react-router-dom';
import { parseRegisterToken } from '@app/utils';
import { MemberRegisterForm } from '@app/components/MemberRegisterForm';
import { initInviteMemberAction, inviteMemberAction } from '@app/store/network/actions';
import { inviteMemberSelector } from '@app/store/network/selector';

export const Registration = ({ history }) => {
  const dispatch = useDispatch();
  const [refObject, setRefObject] = useState({});
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      dispatch(initInviteMemberAction());
      setRefObject(parseRegisterToken(token));
    }
  }, [token, dispatch]);

  const { loading, success } = useSelector(inviteMemberSelector);

  const onSubmit = ({ firstName, lastName, email, phone }) => {
    dispatch(
      inviteMemberAction({
        network_uuid: refObject.networkId,
        user_uuid: refObject.userId,
        name: `${firstName} ${lastName}`,
        email: email.trim(),
        user_phone: phone.trim(),
        register: true,
      }),
    );
  };

  return (
    <PageContainer className="flex-col">
      <div className="flex flex-row flex-full w-full h-full items-center">
        <div className="flex-full h-full hidden sm:block relative">
          <img src={WELCOME_IMAGE} className="object-cover flex-full h-full" />
          <div className="absolute inset-0 text-center flex items-center justify-center">
            <div className="text-white flex flex-col">
              <span className="font-serif text-base mb-1 lg:text-lg italic font-extralight">early private access to</span>
              <span className="font-sans font-medium tracking-wide text-3xl lg:text-4xl">{(refObject && refObject.networkName) || 'Network'}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-full h-full items-center justify-center">
          {!success && (
            <div className="overflow-auto flex w-full h-full justify-center flex-1 pt-24">
              <MemberRegisterForm isFetching={loading} onSubmit={onSubmit} />
            </div>
          )}
          {!success && (
            <div className="absolute top-0 right-0 p-8 cursor-pointer" onClick={() => history.push('/')}>
              <CLOSE_ICON />
            </div>
          )}
          {!loading && success && (
            <div className="relative h-full flex flex-col w-full items-center justify-center">
              <MESSAGE_ICON width={30} height={30} />
              <div className="font-sans text-2xl lg:text-3xl text-center mt-6">
                Please check your SMS Messages and
                <br />
                click on the link to confirm
                <br />
                your account.
              </div>
              <div className="absolute top-0 right-0 p-8 cursor-pointer" onClick={() => history.push('/')}>
                <CLOSE_ICON />
              </div>
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
};
