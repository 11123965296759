const GetAnalyticsData = `
  query GetAnalyticsData($network_uuid: uuid, $start_date: timestamptz) {
    network(where: {uuid: {_eq: $network_uuid}}) {
      new_user: network_users_aggregate(where: {created_at: {_gte: $start_date}}) {
        aggregate {
          count
        }
      }
      post: content_aggregate(where: {created_at: {_gte: $start_date}}) {
        aggregate {
          count
        }
      }
      roundtable: content_aggregate(where: {created_at: {_gte: $start_date}, content_partial: {content_partial_roundtable: {}}}) {
        aggregate {
          count
        }
      }
      conversation: conversations_aggregate(where: {created_at: {_gte: $start_date}}) {
        aggregate {
          count
        }
      }
    }
    message: chat_message_aggregate(where: {conversation: { network_uuid: {_eq: $network_uuid}}, created_at: {_gte: $start_date}}) {
      aggregate {
        count
      }
    }
  }
`;

export default GetAnalyticsData;
