const InsertNewHashtag = `
  mutation InsertNewHashtag(
    $uuid: uuid,
    $hashtag: String,
    $network_category_hashtag: [network_category_hashtag_insert_input!]!
  ) {
    insert_hashtag_one(object: {
      text: $hashtag
      uuid: $uuid
    }) {
      uuid
    }
    insert_network_category_hashtag(objects: $network_category_hashtag) {
      affected_rows
    }
  }
`;

export default InsertNewHashtag;
