const UpdateContentReject = `
  mutation UpdateContentReject($content_uuid: uuid!, $uuid: uuid!, $reject: content_reject_insert_input!) {
    result: insert_content_reject_one(object: $reject, on_conflict: {constraint: content_reject_pkey, update_columns: [reason]}) {
      uuid
    }
    update_content(where: {uuid: {_eq: $content_uuid}}, _set: {reject_uuid: $uuid, admin_approved: false}) {
      affected_rows
    }
  }
`;

export default UpdateContentReject;
