import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { archiveNetworkAction, fetchUsersByNetworkAction, updateNetworkSettingsAction } from '@app/store/network/actions';
import { generateRegisterLink, copyToClipboard, showInfoToast } from '@app/utils';

import { Avatar, Button, CopyIcon, LoadingView, Section, SpinnerIcon } from '@app/components';
import { currentNetworkSelector, networkSettingSelector } from '@app/store/network/selector';
import { isSuperAdminSelector, userSelector } from '@app/store/session/selector';
import FlexRow from '@app/components/FlexRow';
import PageNavContainer from '@app/components/PageNavContainer';

import OverviewIcon from '@app/assets/images/hand.svg';
import FlexCol from '@app/components/FlexCol';
import { BaseModal } from '@app/components/modals';
import MobileDownArrow from '@app/assets/images/down-arrow.svg';

const MenuItems = [
  { tab: 'name', title: 'Organization name', subtitle: 'Official business name.', icon: OverviewIcon },
  { tab: 'logo', title: 'Your logo', subtitle: 'Add or update your logo.', icon: OverviewIcon },
  { tab: 'category', title: 'Network Settings', subtitle: 'Configure network settings.', icon: OverviewIcon },
];

export const NetworkSettingsPage = () => {
  const dispatch = useDispatch();
  const [localImage, setLocalImage] = useState(null);
  const currentNetwork = useSelector(currentNetworkSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const user = useSelector(userSelector);
  const { loading, success } = useSelector(networkSettingSelector);
  const { id: networkId } = useParams();
  const { setting = {} } = currentNetwork;
  const [approval, setApproval] = useState(setting && setting.post_approval);
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    if (setting) {
      setApproval(!!setting.post_approval);
    }
  }, [setting]);

  useEffect(() => {
    dispatch(fetchUsersByNetworkAction(networkId));
  }, [dispatch, networkId]);
  const [name, setName] = useState(currentNetwork.name);

  useEffect(() => {
    setName(currentNetwork.name);
  }, [currentNetwork]);

  useEffect(() => {
    if (!loading && success) {
      setLocalImage(null);
    }
  }, [loading, success]);

  const handleUpdate = () => {
    dispatch(
      updateNetworkSettingsAction({
        name,
        avatar: localImage,
        approval: !!approval,
      }),
    );
  };

  const handleNameChange = ({ name }) => {
    setName(name);
  };

  async function handleAvatarFileChange({ file }) {
    setLocalImage(file);
  }

  const copyRegistrationLink = () => {
    const link = generateRegisterLink(user.uuid, currentNetwork.uuid, currentNetwork.name);
    copyToClipboard(link);
    showInfoToast('Copied the registration link to clipboard.');
  };

  const archiveNetwork = () => {
    if (confirm(`Are you sure you want to ${currentNetwork.archived_at ? 'activate' : 'archive'} this network?`)) {
      dispatch(archiveNetworkAction(!currentNetwork.archived_at));
    }
  };

  return (
    <FlexRow className="h-full flex-1">
      <PageNavContainer
        title="Settings"
        subtitle="Your organization details."
        items={MenuItems}
        selectedTab={selectedTab}
        onChangeTab={(value) => setSelectedTab(value)}
      />
      {!!selectedTab && (
        <BaseModal isOpen={!!selectedTab} onClose={() => setSelectedTab('')} className="w-full h-full flex flex-col bg-white" overlayClassName="flex">
          <FlexCol className="p-7">
            <FlexRow onClick={() => setSelectedTab('')}>
              <img src={MobileDownArrow} className="rotate-90 transform cursor-pointer w-8 h-8" />
            </FlexRow>
            <FlexCol className="bg-white shadow-custom1 p-7 rounded-xl mt-12">
              {(selectedTab === 'name' || selectedTab === 'social') && (
                <Section
                  title="Your organization name"
                  description="This is the full name for your business, collective, or organization(30 characters max)."
                  style={{ paddingBottom: 34 }}>
                  <NetworkNameForm
                    initialValues={{
                      name: name,
                    }}
                    onNameChange={handleNameChange}
                  />
                </Section>
              )}
              {selectedTab === 'logo' && (
                <Section title="Network logo" description="Your logo will appear throughout the Declaration ecosystem." style={{ paddingBottom: 40 }}>
                  <NetworkAvatarForm
                    initialValues={{
                      avatar: localImage ? URL.createObjectURL(localImage) : currentNetwork.avatar,
                    }}
                    isFetching={loading}
                    onFileChange={handleAvatarFileChange}
                  />
                </Section>
              )}
              {selectedTab === 'category' && (
                <>
                  <Section title="Network Settings" style={{ paddingBottom: 40 }}>
                    <FlexRow className="cursor-pointer items-center" onClick={() => setApproval(!approval)}>
                      <FlexCol className={`w-4 h-4 rounded items-center justify-center mr-2 ${approval ? 'bg-primary' : 'border-gray-600 border-2'}`}>
                        {approval && <span className="text-white font-bold text-11 mt-1">✓</span>}
                      </FlexCol>
                      <div className="text-xs">Require Moderator approval of Posts</div>
                    </FlexRow>
                  </Section>
                  <Section title="Network registration link" style={{ paddingBottom: 40 }}>
                    <FlexRow>
                      <Button
                        size="large"
                        className="bg-primary-green text-xs px-4 py-2 text-white"
                        labelClassName="ml-2"
                        leftIcon={<CopyIcon width="14" fill="white" />}
                        label="Copy Link"
                        onClick={copyRegistrationLink}
                      />
                    </FlexRow>
                  </Section>
                </>
              )}
              <Button className="bg-primary-green py-2 px-4 text-sm text-white flex-shrink-0" label="Save" onClick={handleUpdate} />
            </FlexCol>
            <FlexRow className="absolute top-8 right-7 items-center">
              {isSuperAdmin && (
                <Button
                  className={`py-1.5 px-4 text-xs text-white ${currentNetwork.archived_at ? '' : 'bg-red-700'}`}
                  onClick={archiveNetwork}
                  label={currentNetwork.archived_at ? 'Activate Network' : 'Archive Network'}
                />
              )}
            </FlexRow>
            {loading && <LoadingView className="absolute inset-0 bg-black bg-opacity-20" />}
          </FlexCol>
        </BaseModal>
      )}
    </FlexRow>
  );
};

function NetworkNameForm({ initialValues = {}, onNameChange = () => {} }) {
  const handleNameChange = (event) => {
    const updatedName = event.target.value;

    onNameChange({ name: updatedName });
  };

  return (
    <FlexRow>
      <input
        type="text"
        className="border p-3 py-4 rounded-md border-gray-400 bg-bg3 text-xs w-full"
        value={initialValues.name || ''}
        placeholder="Your network name"
        maxLength={30}
        onChange={handleNameChange}
      />
    </FlexRow>
  );
}

function NetworkAvatarForm({ initialValues = {}, isFetching, onFileChange = () => {} }) {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileChange({ file });
  };

  return (
    <FlexRow className="items-center">
      <label htmlFor="avatar-file-input">
        <Avatar imageSrc={initialValues.avatar} className="w-12 h-12 overflow-hidden cursor-pointer rounded-full border" />
        <input id="avatar-file-input" className="hidden" type="file" accept="image/*" onChange={handleFileChange} />
      </label>
      <label htmlFor="avatar-file-input" className="text-label text-xs ml-8 p-2 rounded-md border cursor-pointer">
        Upload image
      </label>

      {isFetching && <SpinnerIcon />}
    </FlexRow>
  );
}
